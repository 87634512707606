import React, { ReactNode } from "react";

import styled from "styled-components";

// Custom styles for the SidenavCollapse
import {
  CollapseItem,
  CollapseIconBox,
  CollapseIcon,
  // CollapseText,
  CollapseTextBox,
} from "./SidenavCollapse.styled";

interface ISidenavCollapse {
  // color?: string;
  icon: ReactNode;
  [x: string]: any;
  name?: string;
  children?: ReactNode;
  active: boolean;
  noCollapse?: boolean;
  open?: boolean;
  collapse?: {
    name: string;
    key: string;
    route: string;
  }[];
}

const SideNavListItem = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  padding-top: 0px;
  padding-bottom: 0px;
`;

function SidenavCollapse({
  icon,
  name, // eslint-disable-line
  children,
  active, // eslint-disable-line
  noCollapse, // eslint-disable-line
  open,
  collapse,
  ...rest // eslint-disable-line
}: ISidenavCollapse) {
  return (
    <>
      <SideNavListItem>
        <CollapseItem active>
          <CollapseIconBox active sidenavColor="transparent">
            {typeof icon === "string" ? (
              <CollapseIcon className="material-icons">{icon}</CollapseIcon>
            ) : (
              icon
            )}
          </CollapseIconBox>
          <CollapseTextBox>{name}</CollapseTextBox>

          {/* <CollapseTextBox primary={name}>
              <CollapseText active>{name}</CollapseText>
          </CollapseTextBox> */}
          {/* <ExpandMoreIcon
            sx={{
              transform: open ? "rotate(180deg)" : "rotate(0)",
              transition: transitions.create(
                ["color", "transform", "opacity"],
                {
                  easing: transitions.easing.easeInOut,
                  duration: transitions.duration.shorter,
                },
              ),
              display: noCollapse ? "none" : "block",
            }}
          /> */}
        </CollapseItem>
      </SideNavListItem>
      {children && <div>{children}</div>}
    </>
  );
}

export default SidenavCollapse;
