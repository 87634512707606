import React from "react";
import styled from "styled-components";

export const DividerEl = styled.hr`
  flex-shrink: 0;
  border-top: 0px solid rgba(0, 0, 0, 0.86);
  border-right: 0px solid rgba(0, 0, 0, 0.86);
  border-left: 0px solid rgba(0, 0, 0, 0.86);
  background-color: transparent;
  height: 0.0625rem;
  margin: 1rem 0px;
  border-bottom: none;
  opacity: 0.25;
  background-image: linear-gradient(
    to right,
    rgba(52, 71, 103, 0),
    rgba(0, 0, 0, 0.881),
    rgba(52, 71, 103, 0)
  ) !important;
  width: 100%;
`;

function Divider() {
  return <DividerEl />;
}

export default Divider;
