import React, { ReactNode } from "react";

// Custom styles for the SidenavItem
import { ItemContent } from "./styled/sidenavItem";

// Typechecking props for the SidenavItem
interface ISidenavItem {
  name: string;
  [x: string]: any;
  active?: boolean;
  nested?: boolean;
  children?: ReactNode;
  open?: boolean;
}

function SidenavItem({
  name,
  active,
  nested,
  children,
  open,
  ...rest
}: ISidenavItem) {
  return (
    <>
      {/* eslint-disable-next-line */}

      {open ? (
        <>
          <div>
            <ItemContent active>
              <p>{name}</p>
              {children && <span className="material-icons">expand_less</span>}
            </ItemContent>
          </div>
          {children && <div>{children}</div>}
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default SidenavItem;
