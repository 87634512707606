import React, { useEffect, useState } from "react";

import google from "../../assets/images/google.png";
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase/firebase";
import {
  LoginAction,
  LoginBox,
  LoginButton,
  LoginContent,
  LoginHeadline,
  LoginPage,
  LoginSubtitle,
} from "./Login.styled";
import {
  FormBox,
  InlineInputBox,
  Input,
  RadioInputBox,
} from "../../components/UI/Forms/Forms.styled";
import { writeNewUser, IUserData } from "../../utils/api/writeNewUser";
import Divider from "../../components/UI/Divider/Divider";
import {
  Modal,
  ModalBox,
  ModalLayer,
} from "../../components/UI/Modal/Modal.styled";
import { publicIpv4 } from "public-ip";
import { readUserPersonalData } from "../../utils/api/readUserData";
import { Logo } from "../../components/Hero/Hero";
import { ButtonVisagify } from "../../components/ButtonVisagify/ButtonVisagify";
import visagify from "../../assets/images/visagify-logo.png";

function Login() {
  const navigate = useNavigate();
  const [isAuth, setIsAuth] = useState(
    false || window.localStorage.getItem("auth-visagify") === "true"
  );
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [displayModal, setDisplayModal] = useState(false);

  let userData: IUserData = {
    createdAt: Date.now(),
    uid: "",
    pic: "",
    nom: "",
    prenom: "",
    gender: "",
    birthday: "",
    pays: "",
    ville: "",
    email: "",
    emailVerified: false,
    ip: "",
    subscription: "",
    customerId: "",
  };

  const provider = new GoogleAuthProvider();
  // const auth = getAuth();
  const loginWithGoogle = () => {
    signInWithPopup(auth, provider).then(async (userCred) => {
      const user = await readUserPersonalData(userCred.user.uid);
      if (user.uid === "") {
        console.log("not registered");
        const _nom = userCred.user.displayName?.split(" ")[0];
        const _prenom = userCred.user.displayName?.split(" ")[1];
        try {
          userData.createdAt = Date.now();
          userData.uid = userCred.user.uid;
          userData.pic = userCred.user.photoURL ? userCred.user.photoURL : "";
          userData.nom = typeof _nom === "undefined" ? "" : _nom;
          userData.prenom = typeof _prenom === "undefined" ? "" : _prenom;
          userData.gender = "";
          userData.email = userCred.user.email ? userCred.user.email : "";
          userData.birthday = "";
          userData.subscription = "free";
          const ipV4 = await publicIpv4();
          userData.ip = ipV4;
          console.log(userData);
          writeNewUser(userData, 0, userCred.user.uid).then();
        } catch (error) {
          console.log(error);
        }
      }
      window.localStorage.setItem("uid-visagify", userCred.user.uid);
      window.localStorage.setItem("auth-visagify", "true");
      setIsAuth(true);
    });
  };

  const createWithEmail = () => {
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCred) => {
        window.localStorage.setItem("uid-visagify", userCred.user.uid);
        window.localStorage.setItem("auth-visagify", "true");
        setIsAuth(true);
        try {
          userData.createdAt = Date.now();
          userData.uid = userCred.user.uid;
          userData.pic = `https://firebasestorage.googleapis.com/v0/b/askmorpheusdreams.appspot.com/o/askmorpheus-logo-128.png?alt=media&token=7a485330-6580-457a-a770-b3f55105802b`;
          userData.nom = nom
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
          userData.prenom = prenom
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
          userData.email = email;
          userData.subscription = "free";
          const ipV4 = await publicIpv4();
          userData.ip = ipV4;
          // params.uid && userData.amis.push(params.uid);
          writeNewUser(userData, 0, userCred.user.uid);
        } catch (error) {
          console.log(error);
        }
      })
      .catch((e: { code: string; message: string }) => {
        console.log(e.code === "auth/email-already-in-use");
      });
  };

  const signinWithEmail = (event: React.FormEvent) => {
    event.preventDefault();
    console.log(isAuth);
    console.log(email);
    console.log(password);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCred) => {
        console.log(userCred);
        window.localStorage.setItem("uid-visagify", userCred.user.uid);
        window.localStorage.setItem("auth-visagify", "true");
        setIsAuth(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    console.log(isAuth);
    if (isAuth) {
      navigate("/app/");
    }
  }, [isAuth]); // eslint-disable-line

  return (
    <LoginPage>
      <LoginBox>
        <LoginContent>
          <Logo src={visagify} alt="visagify logo" />
          <LoginHeadline>Craft Your Visual Signature.</LoginHeadline>
          <LoginSubtitle>Welcome Back.</LoginSubtitle>
        </LoginContent>
        <FormBox>
          <Input
            placeholder="E-mail"
            type="email"
            required
            onChange={(event) => setEmail(event.target.value)}
          />
          <Input
            placeholder="Password"
            type="password"
            required
            onChange={(event) => setPassword(event.target.value)}
          />
          <ButtonVisagify style={{ width: "90%" }} onClick={signinWithEmail}>
            Log In
          </ButtonVisagify>
        </FormBox>

        <LoginAction>
          <Divider />
          <LoginSubtitle>
            Create your account and start personalizing your digital
            experiences.
          </LoginSubtitle>

          <ButtonVisagify
            style={{ width: "90%" }}
            onClick={() => setDisplayModal(!displayModal)}
          >
            Sign Up
          </ButtonVisagify>
          <LoginSubtitle>OR</LoginSubtitle>
          <LoginButton onClick={loginWithGoogle}>
            <img
              alt="Google Logo"
              src={google}
              style={{ width: "1.2rem", verticalAlign: "sub" }}
            />{" "}
            Login/Sign Up with Google
          </LoginButton>
        </LoginAction>
        <ModalBox isDisplay={displayModal}>
          <ModalLayer
            onClick={() => setDisplayModal(!displayModal)}
          ></ModalLayer>
          <Modal>
            <InlineInputBox>
              <Input
                placeholder="First Name"
                type="text"
                required
                onChange={(event) => setPrenom(event.target.value)}
              />
              <Input
                placeholder="Last Name"
                type="text"
                required
                onChange={(event) => setNom(event.target.value)}
              />
            </InlineInputBox>
            <Input
              placeholder="Email"
              type="email"
              required
              onChange={(event) => setEmail(event.target.value)}
            />
            <Input
              placeholder="New Password"
              type="password"
              required
              onChange={(event) => setPassword(event.target.value)}
            />
            <RadioInputBox>
              <input type="checkbox" id="terms" />
              <label htmlFor="terms">
                I agree with Visagify's{" "}
                <a href="/terms" style={{ textDecoration: "underline" }}>
                  Terms of Use
                </a>{" "}
                and{" "}
                <a href="/privacy" style={{ textDecoration: "underline" }}>
                  Privacy Policy
                </a>
                .
              </label>
            </RadioInputBox>
            <ButtonVisagify onClick={createWithEmail}>Sign Up</ButtonVisagify>
          </Modal>
        </ModalBox>
      </LoginBox>
    </LoginPage>
  );
}

export default Login;
