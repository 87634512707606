// src/components/ImageModal.tsx
import React from "react";
import styled from "styled-components";

const ModalBackdrop = styled.div`
  position: fixed;
  flex-direction: column;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  @media screen and (max-width: 682px) {
  }

  @media screen and (min-width: 510px) {
    width: 100vw;
    height: 100vh;
  }
`;

const ModalContent = styled.div`
  background: rgba(255, 255, 255, 0.15);
  border-radius: 8px;
  max-width: 90%;
  max-height: 95%;
  height: 98vh;
  overflow: auto;
  align-items: center;
  display: flex;
`;

const StyledImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  color: white;
  cursor: pointer;
`;

const ImageLink = styled.a`
  color: rgba(255, 255, 255, 0.7);

  &:hover {
    color: rgba(255, 255, 255, 1);
    text-decoration: underline;
    font-weight: 500;
  }
`;

interface ImageModalProps {
  url: string;
  onClose: () => void;
  onNext: () => void;
  onPrevious: () => void;
}

const NavigationButton = styled.button`
  background: transparent;
  border: none;
  padding: 10px;
  cursor: pointer;
  color: white;
  font-size: 30px;
  transition: background 0.3s ease;

  &:hover {
    background: ${(props: { side: string }) =>
      props.side === "prev"
        ? "linear-gradient(90deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))"
        : "linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8))"};
  }

  // Full width of the clickable area
  height: 100%;
  position: absolute;
  top: 0;

  // Adjusting size to be a reasonable target for clicking
  width: 30%;

  // Using ::after for visual content so it does not affect the clickable area
  /* &::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    border: 2px solid white;
    border-radius: 50%;
  } */
`;

const PreviousButton = styled(NavigationButton)`
  left: 0;
  &::after {
    left: 20px;
    border-right: none;
    border-top: none;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

const NextButton = styled(NavigationButton)`
  right: 0;
  &::after {
    right: 20px;
    border-left: none;
    border-bottom: none;
    transform: translate(50%, -50%) rotate(-45deg);
  }
`;

const ImageModalProSoccer: React.FC<ImageModalProps> = ({
  url,
  onClose,
  onNext,
  onPrevious,
}) => {
  return (
    <ModalBackdrop onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <StyledImg src={url} alt="Full Size" />

        <PreviousButton side="prev" onClick={onPrevious} />
        <NextButton side="next" onClick={onNext} />
        <CloseButton onClick={onClose}>×</CloseButton>
      </ModalContent>
      <ImageLink href={url} target="blank">
        Open in the web browser
      </ImageLink>
    </ModalBackdrop>
  );
};

export default ImageModalProSoccer;
