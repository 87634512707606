import React, { useEffect, useState } from "react";
import styled from "styled-components";
import imageCompression from "browser-image-compression";
import { palette } from "../../../assets/style/colors";
import { useLocation } from "react-router-dom";
import { SelectLab } from "../../../components/UI/Forms/Forms.styled";
import uploadProSoccerImages from "../../../utils/api/admin/prosoccer/writeAddImages";

export const players = [
  {
    name: "Cristiano Ronaldo",
    id: "cristiano_ronaldo",
    country: "Portugal",
    club: "Al Nassr",
  },
  {
    name: "Lionel Messi",
    id: "lionel_messi",
    country: "Argentina",
    club: "Paris Saint-Germain",
  },
  {
    name: "Neymar Jr",
    id: "neymar_jr",
    country: "Brazil",
    club: "Paris Saint-Germain",
  },
  {
    name: "Kylian Mbappé",
    id: "kylian_mbappe",
    country: "France",
    club: "Paris Saint-Germain",
  },
  {
    name: "David Beckham",
    id: "david_beckham",
    country: "England",
    club: "Retired",
  },
  {
    name: "Karim Benzema",
    id: "karim_benzema",
    country: "France",
    club: "Real Madrid",
  },
  {
    name: "Ronaldinho",
    id: "ronaldinho",
    country: "Brazil",
    club: "Retired",
  },
  {
    name: "Zlatan Ibrahimović",
    id: "zlatan_ibrahimovic",
    country: "Sweden",
    club: "AC Milan",
  },
  {
    name: "Mohamed Salah",
    id: "mohamed_salah",
    country: "Egypt",
    club: "Liverpool",
  },
  {
    name: "Sergio Ramos",
    id: "sergio_ramos",
    country: "Spain",
    club: "Paris Saint-Germain",
  },
  {
    name: "Paulo Dybala",
    id: "paulo_dybala",
    country: "Argentina",
    club: "AS Roma",
  },
  {
    name: "Gareth Bale",
    id: "gareth_bale",
    country: "Wales",
    club: "Los Angeles FC",
  },
  {
    name: "Luis Suárez",
    id: "luis_suarez",
    country: "Uruguay",
    club: "Nacional",
  },
  {
    name: "Andrés Iniesta",
    id: "andres_iniesta",
    country: "Spain",
    club: "Vissel Kobe",
  },
  {
    name: "Toni Kroos",
    id: "toni_kroos",
    country: "Germany",
    club: "Real Madrid",
  },
  {
    name: "Antoine Griezmann",
    id: "antoine_griezmann",
    country: "France",
    club: "Atlético Madrid",
  },
  {
    name: "Zinedine Zidane",
    id: "zinedine_zidane",
    country: "France",
    club: "Retired",
  },
  {
    name: "Erling Braut Haaland",
    id: "erling_braut_haaland",
    country: "Norway",
    club: "Manchester City",
  },
  {
    name: "Robert Lewandowski",
    id: "robert_lewandowski",
    country: "Poland",
    club: "Barcelona",
  },
  {
    name: "Luka Modrić",
    id: "luka_modric",
    country: "Croatia",
    club: "Real Madrid",
  },
];

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 300px;
`;

const StyledLabel = styled.label`
  border: solid 1px #000;
  background-color: #fff;
  color: ${palette.primary.buttonText};
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 300ms ease-in-out;

  &:hover {
    background-color: #000;
    color: #fff;
    box-shadow: rgba(8, 255, 8, 0.8) 0px 4px 8px;
  }

  input {
    display: none;
  }
`;

const Button = styled.button`
  padding: 10px;
  background-color: ${palette.primary.button};
  color: ${palette.primary.buttonText};
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${palette.primary.buttonHover};
  }
`;

const PreviewContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const RemoveButton = styled.button`
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-weight: bold;
  position: absolute;
  top: 0;
  right: 0;
  &:hover {
    background-color: darkred;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const ImagePreview = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  border: 2px solid #000;
  border-radius: 10px;
`;

export const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];

function AddProSoccer() {
  const location = useLocation();

  useEffect(() => {
    // Logic to apply styles to the body based on the path
    if (location.pathname.includes("/prosoccer")) {
      document.body.classList.add("soccer-theme");
    } else {
      document.body.classList.remove("soccer-theme");
    }
  }, [location.pathname]);

  // const [images, setImages] = useState<FileList | null>(null);
  const [playerName, setPlayerName] = useState("");
  const [country, setCountry] = useState("");
  const [club, setClub] = useState("");
  const [ethnicity, setEthnicity] = useState("");
  const [ageRange, setAgeRange] = useState("");
  const [hairType, setHairType] = useState("");
  const [hairColor, setHairColor] = useState("");
  const [hairLength, setHairLength] = useState("");
  const [faceShape, setFaceShape] = useState("");
  const [eyesShape, setEyesShape] = useState("");
  const [noseShape, setNoseShape] = useState("");
  const [lipsShape, setLipsShape] = useState("");
  const [cheeksShape, setCheeksShape] = useState("");
  // Add more states as needed

  const [metadata, setMetadata] = useState({
    playerName: "",
    country: "",
    club: "",
    gender: "male",
    ethnicity: "",
    ageRange: "",
    faceShape: "",
    hairType: "",
    hairColor: "",
    hairLength: "",
    eyesShape: "",
    noseShape: "",
    lipsShape: "",
    cheeksShape: "",
  });

  // Handle change for metadata inputs
  const handleMetadataChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    setMetadata((prevMetadata) => ({
      ...prevMetadata,
      [name]: value,
    }));
    switch (name) {
      case "playerName":
        setPlayerName(value);
        break;
      case "country":
        setCountry(value);
        break;
      case "club":
        setClub(value);
        break;
      case "ethnicity":
        setEthnicity(value);
        break;
      case "ageRange":
        setAgeRange(value);
        break;
      case "faceShape":
        setFaceShape(value);
        break;
      case "hairType":
        setHairType(value);
        break;
      case "hairColor":
        setHairColor(value);
        break;
      case "hairLength":
        setHairLength(value);
        break;
      case "eyesShape":
        setEyesShape(value);
        break;
      case "noseShape":
        setNoseShape(value);
        break;
      case "lipsShape":
        setLipsShape(value);
        break;

      case "cheeksShape":
        setCheeksShape(value);
        break;
      default:
        console.log(`the field ${name} doesn't exist`);
    }

    console.log(metadata);
  };

  const [images, setImages] = useState<string[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      try {
        for (const file of files) {
          if (!validImageTypes.includes(file.type)) {
            alert("Please select a valid image file (jpeg, png, or jpg).");
            continue; // Skip invalid files
          }

          if (file.size > 10 * 1024 * 1024) {
            alert("The file size should be less than 10 MB.");
            continue; // Skip large files
          }

          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          };

          const compressedBlob = await imageCompression(file, options);
          const compressedFile = new File([compressedBlob], file.name, {
            type: file.type,
            lastModified: new Date().getTime(),
          });

          const fileReader = new FileReader();
          fileReader.onload = (e) => {
            setImages((prevImages) => [
              ...prevImages,
              e.target?.result as string,
            ]);
            setSelectedFiles((prevFiles) => [...prevFiles, compressedFile]);
          };
          fileReader.readAsDataURL(compressedFile);
        }
      } catch (error) {
        console.error(error);
        alert("An error occurred while compressing the image.");
      }
    }
  };

  const handleRemoveImage = (index: number) => {
    // Create new arrays by filtering out the element at the specified index
    const newImages = images.filter((_, idx) => idx !== index);
    const newSelectedFiles = selectedFiles.filter((_, idx) => idx !== index);

    // Update state with the new arrays
    setImages(newImages);
    setSelectedFiles(newSelectedFiles);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!selectedFiles || selectedFiles.length === 0) {
      alert("Please select at least one image.");
      return;
    }

    try {
      const response = await uploadProSoccerImages(selectedFiles, metadata);
      console.log(response.data);
      alert("Images and metadata uploaded successfully.");
    } catch (error) {
      console.error(error);
      alert("Error uploading images.");
    }
  };

  return (
    <Container>
      {images && (
        <PreviewContainer>
          {images.map((image, index) => (
            <ImageContainer key={index}>
              <ImagePreview src={image} alt={`Image Preview ${index}`} />
              <RemoveButton onClick={() => handleRemoveImage(index)}>
                X
              </RemoveButton>
            </ImageContainer>
          ))}
        </PreviewContainer>
      )}
      <Form onSubmit={handleSubmit}>
        <StyledLabel>
          Upload Images
          <input
            type="file"
            onChange={(e) => handleImageChange(e)}
            accept="image/*"
            multiple
          />
        </StyledLabel>
        <SelectLab
          name="playerName"
          value={playerName}
          onChange={(e) => handleMetadataChange(e)}
        >
          <option value="">Select Player</option>
          {players.map((player) => (
            <option key={player.id} value={player.id}>
              {player.name}
            </option>
          ))}
        </SelectLab>

        <SelectLab
          name="country"
          value={country}
          onChange={(e) => handleMetadataChange(e)}
        >
          <option value="">Select Country</option>
          {Array.from(new Set(players.map((player) => player.country)))
            .sort()
            .map((country, index) => (
              <option key={index} value={country}>
                {country}
              </option>
            ))}
        </SelectLab>

        <SelectLab
          name="club"
          value={club}
          onChange={(e) => handleMetadataChange(e)}
        >
          <option value="">Select Club</option>
          {Array.from(new Set(players.map((player) => player.club)))
            .sort()
            .map((club, index) => (
              <option key={index} value={club}>
                {club}
              </option>
            ))}
        </SelectLab>

        {/* <SelectLab
          name="gender"
          value={gender}
          onChange={(e) => handleMetadataChange(e)}
        >
          <option value="">Select Gender</option>
          <option value="female">Female</option>
          <option value="male">Male</option>
          <option value="other">Other</option>
        </SelectLab> */}

        <SelectLab
          name="ethnicity"
          value={ethnicity}
          onChange={(e) => handleMetadataChange(e)}
        >
          <option value="">Select Ethnicity</option>
          <option value="arab">Arab</option>
          <option value="asian">Asian</option>
          <option value="black">Black</option>
          <option value="caucasian">Caucasian</option>
          <option value="hispanic">Hispanic</option>
          <option value="mixed">Mixed</option>
          {/* Add more options as needed */}
        </SelectLab>

        <SelectLab
          name="ageRange"
          value={ageRange}
          onChange={(e) => handleMetadataChange(e)}
        >
          <option value="">Select Age Range</option>
          <option value="18-25">18-25</option>
          <option value="26-35">26-35</option>
          <option value="36-45">36-45</option>
          <option value="46-55">46-55</option>
          <option value="56+">56+</option>
        </SelectLab>

        <SelectLab
          name="faceShape"
          value={faceShape}
          onChange={(e) => handleMetadataChange(e)}
        >
          <option value="">Select Face Shape</option>
          <option value="oval">Oval</option>
          <option value="round">Round</option>
          <option value="square">Square</option>
          <option value="heart">Heart</option>
          <option value="diamond">Diamond</option>
          <option value="pear">Pear</option>
          <option value="oblong">Oblong</option>
          <option value="triangle">Triangle</option>
        </SelectLab>

        <SelectLab
          name="hairType"
          value={hairType}
          onChange={(e) => handleMetadataChange(e)}
        >
          <option value="">Select Hair Type</option>
          <option value="straight">Straight</option>
          <option value="wavy">Wavy</option>
          <option value="curly">Curly</option>
          <option value="coily">Coily</option>
          {/* Add more options as needed */}
        </SelectLab>

        <SelectLab
          name="hairColor"
          value={hairColor}
          onChange={(e) => handleMetadataChange(e)}
        >
          <option value="">Select Hair Color</option>
          <option value="brown">Brown</option>
          <option value="blonde">Blonde</option>
          <option value="darkBlonde">Dark Blonde</option>
          <option value="red">Red</option>
          <option value="grey">Grey</option>
          <option value="other">Other</option>
          {/* Add more options as needed */}
        </SelectLab>

        <SelectLab
          name="hairLength"
          value={hairLength}
          onChange={(e) => handleMetadataChange(e)}
        >
          <option value="">Select Hair Length</option>
          <option value="bald">Bald / Shaved</option>
          <option value="veryShort">Very Short</option>
          <option value="short">Short</option>
          <option value="medium">Medium</option>
          <option value="long">Long</option>
          <option value="veryLong">Very Long</option>
        </SelectLab>

        <SelectLab
          name="eyesShape"
          value={eyesShape}
          onChange={(e) => handleMetadataChange(e)}
        >
          <option value="">Select Eyes Shape</option>
          <option value="almond">Almond</option>
          <option value="round">Round</option>
          <option value="monolid">Monolid</option>
          <option value="hooded">Hooded</option>
          <option value="upturned">Upturned</option>
          <option value="downturned">Downturned</option>
          <option value="deepSet">Deep Set</option>
          <option value="protruding">Protruding</option>
        </SelectLab>

        <SelectLab
          name="noseShape"
          value={noseShape}
          onChange={(e) => handleMetadataChange(e)}
        >
          <option value="">Select Nose Shape</option>
          <option value="roman">Roman Nose</option>
          <option value="greek">Greek Nose</option>
          <option value="nubian">Nubian Nose</option>
          <option value="hawk">Hawk Nose</option>
          <option value="snub">Snub Nose</option>
          <option value="aquiline">Aquiline Nose</option>
          <option value="button">Button Nose</option>
          <option value="straight">Straight Nose</option>
          <option value="bulbous">Bulbous Nose</option>
        </SelectLab>

        <SelectLab
          name="lipsShape"
          value={lipsShape}
          onChange={(e) => handleMetadataChange(e)}
        >
          <option value="">Select Lips Shape</option>
          <option value="full">Full Lips</option>
          <option value="thin">Thin Lips</option>
          <option value="round">Round Lips</option>
          <option value="wide">Wide Lips</option>
          <option value="heartShaped">Heart-Shaped Lips</option>
          <option value="bowShaped">Bow-Shaped Lips</option>
          <option value="downwardTurned">Downward-Turned Lips</option>
        </SelectLab>

        <SelectLab
          name="cheeksShape"
          value={cheeksShape}
          onChange={(e) => handleMetadataChange(e)}
        >
          <option value="">Select Cheeks Shape</option>
          <option value="round">Round</option>
          <option value="square">Square</option>
          <option value="long">Long</option>
          <option value="heartShaped">Heart-Shaped</option>
          <option value="oval">Oval</option>
          <option value="diamond">Diamond</option>
        </SelectLab>

        <Button type="submit">Upload Images</Button>
      </Form>
    </Container>
  );
}

export default AddProSoccer;
