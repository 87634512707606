const readSwapsBulk = async (
  page: number,
  limit: number,
  faceId: string,
  uid: string
) => {
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  // const baseUrl = process.env.REACT_APP_API_BASEURL_LOCAL;
  const url = `${baseUrl}/v2/admin/swaps?page=${page}&limit=${limit}&faceId=${faceId}&uid=${uid}`;
  // const response = await axios.get(url);
  // return response.data;
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error("Failed to fetch swaps");
  }
  return await response.json();
};

export default readSwapsBulk;
