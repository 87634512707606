// src/pages/VisualGalleryPage.tsx
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ImageModal from "./ImageModalAdmin";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { setFaceSwapProcessing } from "../../store/applications/UIReducer";
import readSwapsBulk from "../../utils/api/bulk/readSwapsBulk";
import { FlexRow } from "../../pages/InterpretV2/InterpretV2";
import { SelectLab } from "../UI/Forms/Forms.styled";
import readFaces from "../../utils/api/user/readFaces";
import { FaceImageMetadata } from "../FaceSwapBulk69/FaceSwapBulk69V2";
import { ButtonVisagifyDark } from "../ButtonVisagify/ButtonVisagify";
import writeImagesFaceId from "../../utils/api/bulk/writeImagesFaceId";

const GalleryPageContainer = styled.div`
  // Using CSS columns for masonry layout
  column-count: 4;
  column-gap: 16px;
  width: 95vw;
  max-width: 1440px;
  margin: 0 auto;
  padding: 20px 0;

  @media (max-width: 1024px) {
    column-count: 3;
  }

  @media (max-width: 768px) {
    column-count: 2;
  }

  @media (max-width: 480px) {
    column-count: 1;
  }
`;

const HoverOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
  cursor: pointer;
`;

const StyledCard = styled.div`
  background: #fff;
  display: inline-block;
  margin: 0 0 16px;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  img {
    width: 100%;
    display: block;
    object-fit: cover;
    height: auto; // Ensure full image is displayed
  }

  position: relative;

  &:hover ${HoverOverlay} {
    opacity: 0.5;
  }
`;

const Checkbox = styled.input`
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  z-index: 2;
`;

const SubmitButtonContainer = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 20px;
  background: white;
  box-shadow: rgba(0, 0, 0, 1) 10px 2px 15px;
  z-index: 3;
`;

const FixedFlexRow = styled(FlexRow)`
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 1000;
  background: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const VisualGalleryPageAdminV2: React.FC = () => {
  const dispatch = useAppDispatch();
  const uiState = useAppSelector((state) => state.UIReducer);
  const [swaps, setSwaps] = useState<string[]>([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(
    null
  );
  const [page, setPage] = useState(1);
  const [totalIncremental, setTotalIncremental] = useState(0);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [galleryLengthInit, setGalleryLengthInit] = useState(0);
  const isFirstRender = useRef(true);
  const [faceImages, setFaceImages] = useState<FaceImageMetadata[]>([]);
  const [faceIdList, setFaceIdList] = useState<string[]>([]);
  const [faceId, setFaceId] = useState("");
  const [faceIdClassifier, setFaceIdClassifier] = useState("");
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [isFixed, setIsFixed] = useState(false);

  const handleFaceIdChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target) {
      setPage(1);
      setFaceId(event.target.value);
    }
  };

  const handleFaceIdClassifierChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (event.target) {
      setFaceIdClassifier(event.target.value);
    }
  };

  // Function to fetch and set face images
  const fetchFaceImages = async () => {
    try {
      const response = await readFaces("uid"); // Use the full URL if your backend is on a different domain
      // console.log("faces", response);
      // Extract ids, deduplicate, and sort them alphabetically
      const ids = response.map((face) => face.id);
      const uniqueIds = Array.from(new Set(ids)).sort();
      // Update state
      setFaceImages(response);
      setFaceIdList(uniqueIds);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const NUM_OF_IMG = 100;

  useEffect(() => {
    fetchFaceImages();
  }, []); // eslint-disable-line

  const fetchSwapsByFaceId = useCallback(
    async (page: number, append: boolean = false) => {
      setIsLoading(true);
      try {
        const swapData = await readSwapsBulk(page, NUM_OF_IMG, faceId, "uid"); // Fetching with page number and limit

        if (append) {
          setSwaps((prevSwaps) => [...prevSwaps, ...swapData.swaps]);
        } else {
          setSwaps(swapData.swaps);
        }
        setTotal(swapData.total);
        setGalleryLengthInit(swapData.total);
        setHasMore(swapData.total > page * NUM_OF_IMG);
      } catch (error) {
        console.error("Failed to fetch swaps:", error);
      }
      setIsLoading(false);
    },
    [faceId]
  );

  const fetchSwaps = useCallback(
    async (page: number, append: boolean = false) => {
      setIsLoading(true);
      try {
        const swapData = await readSwapsBulk(page, NUM_OF_IMG, faceId, "uid"); // Fetching with page number and limit
        if (append) {
          setSwaps((prevSwaps) => [...prevSwaps, ...swapData.swaps]);
        } else {
          setSwaps(swapData.swaps);
        }
        setTotal(swapData.total);
        setGalleryLengthInit(swapData.total);
        setHasMore(swapData.total > 0);
      } catch (error) {
        console.error("Failed to fetch swaps:", error);
      }
      setIsLoading(false);
    },
    [] // eslint-disable-line
  );

  useEffect(() => {
    fetchSwapsByFaceId(1, false);
  }, [faceId, fetchSwapsByFaceId]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      fetchSwaps(1, false);
    }
  }, [fetchSwaps]);

  useEffect(() => {
    if (page > 1) {
      if (faceId) {
        fetchSwapsByFaceId(page, true);
      } else {
        fetchSwaps(page, true);
      }
    }
  }, [page, faceId, fetchSwaps, fetchSwapsByFaceId]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
          document.documentElement.offsetHeight - 100 &&
        !isLoading &&
        hasMore
      ) {
        setPage((prevPage) => prevPage + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isLoading, hasMore]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const fetchLatestSwaps = async () => {
      try {
        const swapData = await readSwapsBulk(page, NUM_OF_IMG, faceId, "uid");

        setGalleryLengthInit(swapData.total);

        if (swapData.total > totalIncremental) {
          setSwaps(swapData.swaps);
          setTotalIncremental(swapData.total);
        } else if (swapData.total > total + uiState.faceSwapBulkLength - 1) {
          setSwaps(swapData.swaps);
          setTotal(swapData.total);
          setTotalIncremental(swapData.total);
          setGalleryLengthInit(swapData.total);
          dispatch(setFaceSwapProcessing(false));
        }
      } catch (error) {
        console.error("Failed to fetch swaps:", error);
      }
    };

    const intervalId = setInterval(() => {
      if (uiState.faceSwapProcessing) {
        fetchLatestSwaps();
        console.log("Listening...");
      }
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, [galleryLengthInit, uiState.faceSwapProcessing, dispatch]); // eslint-disable-line

  const openModal = (index: number) => {
    setSelectedImageIndex(index);
  };

  const closeModal = () => {
    setSelectedImageIndex(null);
  };

  const handlePrevious = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex !== null && prevIndex > 0 ? prevIndex - 1 : swaps.length - 1
    );
  };

  const handleNext = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex !== null && prevIndex < swaps.length - 1 ? prevIndex + 1 : 0
    );
  };

  const handleImageSelect = (imageUrl: string) => {
    setSelectedImages((prevSelectedImages) => {
      if (prevSelectedImages.includes(imageUrl)) {
        return prevSelectedImages.filter((url) => url !== imageUrl);
      } else {
        return [...prevSelectedImages, imageUrl];
      }
    });
  };

  const handleSubmit = async () => {
    try {
      await writeImagesFaceId(selectedImages, faceIdClassifier);
      alert("Metadata updated successfully!");
      setSelectedImages([]);
    } catch (error) {
      console.error("Failed to update metadata:", error);
      alert("Failed to update metadata.");
    }
  };

  return (
    <>
      {isFixed ? (
        <FixedFlexRow style={{ justifyContent: "flex-end" }}>
          <SelectLab onChange={handleFaceIdChange}>
            <option value="">Select original face</option>
            <option value="">All</option>
            {faceIdList.map((id, index) => (
              <option key={index} value={id}>
                {id}
              </option>
            ))}
          </SelectLab>
        </FixedFlexRow>
      ) : (
        <FlexRow style={{ justifyContent: "flex-end", marginTop: "1rem" }}>
          <SelectLab onChange={handleFaceIdChange}>
            <option value="">Select original face</option>
            <option value="">All</option>
            {faceImages.map((image, index) => (
              <option key={index} value={image.id}>
                {image.id}
              </option>
            ))}
          </SelectLab>
        </FlexRow>
      )}
      <GalleryPageContainer>
        {swaps.map((swap, index) => (
          <StyledCard key={index}>
            <img src={swap} alt={`Swap Media ${index}`} />
            <Checkbox
              type="checkbox"
              checked={selectedImages.includes(swap)}
              onChange={() => handleImageSelect(swap)}
            />
            <HoverOverlay onClick={() => openModal(index)}></HoverOverlay>
          </StyledCard>
        ))}
        {isLoading && <div>Loading...</div>}
      </GalleryPageContainer>
      {selectedImageIndex !== null && (
        <ImageModal
          url={swaps[selectedImageIndex]}
          onClose={closeModal}
          onNext={handleNext}
          onPrevious={handlePrevious}
        />
      )}
      {selectedImages.length > 0 && (
        <SubmitButtonContainer>
          <SelectLab onChange={handleFaceIdClassifierChange}>
            <option value="">Select original face</option>
            {faceImages.map((image, index) => {
              return (
                <option key={index} value={image.id}>
                  {image.id}
                </option>
              );
            })}
          </SelectLab>
          <ButtonVisagifyDark onClick={handleSubmit}>
            Submit Selected Images
          </ButtonVisagifyDark>
        </SubmitButtonContainer>
      )}
    </>
  );
};

export default VisualGalleryPageAdminV2;
