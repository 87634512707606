import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Palette } from "../../assets/style/colors";
import visagify from "../../assets/images/visagify-logo.png";
import { useTheme } from "../../context/ThemeContext";

// Define our primary and secondary colors
export const ContentWrapper = styled.div`
  text-align: center;
  max-width: 1200px; // Max width for the content
`;

export const HeroContainer = styled.section<{ theme: Palette }>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.headline};
  height: 90vh;
  padding: 10px;
`;

export const Title = styled.h1<{ theme: Palette }>`
  font-size: 2rem;
  color: ${(props) => props.theme.headline};
  margin-top: 0.4rem;
  margin-bottom: 0.4em;
`;

export const Subtitle = styled.p<{ theme: Palette }>`
  font-size: 1.5rem;
  margin-top: 20px;
  color: ${(props) => props.theme.paragraph};
`;

export const StyledLink = styled(Link)<{ theme: Palette }>`
  display: inline-block;
  background-color: ${(props) => props.theme.button};
  color: ${(props) => props.theme.buttonText};
  text-transform: uppercase;
  padding: 10px 20px;
  margin-top: 30px;
  border: none;
  border-radius: 8px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) =>
      props.theme.primaryColor}; // Define primaryColor in theme
    color: ${(props) =>
      props.theme.secondaryColor}; // Define secondaryColor in theme
    cursor: pointer;
  }
`;

// Logo remains unchanged
export const Logo = styled.img`
  max-width: 200px;
  width: 100%;

  @media screen and (max-width: 482px) {
    max-width: 150px;
  }
`;

// Hero section component
const Hero = () => {
  const theme = useTheme();

  return (
    <HeroContainer theme={theme}>
      <ContentWrapper>
        <Logo src={visagify} alt="visagify logo" />
        <Title theme={theme}>
          Revolutionize Your Content with Seamless Face Swapping.
        </Title>
        <Subtitle theme={theme}>
          Experience the new era of digital creativity. Simple. Fast.
          Revolutionary.
        </Subtitle>
        <StyledLink to="/login" theme={theme}>
          Join Beta.
        </StyledLink>
      </ContentWrapper>
    </HeroContainer>
  );
};

export default Hero;
