import React, { ReactNode } from "react";
import styled from "styled-components";

export const ListElement = styled.ul`
  list-style: none;
  margin: 10px 0px;
  padding: 0px;
  position: relative;
  list-style-type: none !important; ;
`;

interface IList {
  children: ReactNode;
}

function List({ children }: IList) {
  return <ListElement>{children}</ListElement>;
}

export default List;
