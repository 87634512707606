import axios from "axios";
import { FaceImageMetadata } from "../../../components/FaceSwapBulk69/FaceSwapBulk69V2";

const readFaces = async (uid: string): Promise<FaceImageMetadata[]> => {
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  // const baseUrl = process.env.REACT_APP_API_BASEURL_LOCAL;
  const url = `${baseUrl}/user/face/${uid}`;
  const response = await axios.get(url);
  return response.data;
};

export default readFaces;
