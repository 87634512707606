import React, { useEffect, useState } from "react";
import styled from "styled-components";
import imageCompression from "browser-image-compression";
import { palette } from "../../assets/style/colors";
import uploadFaceImage from "../../utils/api/user/writeAddFaceImage";
import { Input } from "../../components/UI/Forms/Forms.styled";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { loadAuth } from "../../store/applications/AuthReducer";

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 300px;
`;

const StyledLabel = styled.label`
  border: solid 1px #000;
  background-color: #fff;
  color: ${palette.primary.buttonText};
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 300ms ease-in-out;

  &:hover {
    background-color: #000;
    color: #fff;
    box-shadow: rgba(8, 255, 8, 0.8) 0px 4px 8px;
  }

  input {
    display: none;
  }
`;

export const Button = styled.button`
  padding: 10px;
  background-color: ${palette.primary.button};
  color: ${palette.primary.buttonText};
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${palette.primary.buttonHover};
  }
`;

const PreviewContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const RemoveButton = styled.button`
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-weight: bold;
  position: absolute;
  top: 0;
  right: 0;
  &:hover {
    background-color: darkred;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const ImagePreview = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  border: 2px solid #000;
  border-radius: 10px;
`;

export const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];

function ManageFaces() {
  const dispatch = useAppDispatch();
  // const [images, setImages] = useState<FileList | null>(null);
  const authState = useAppSelector((state) => state.AuthReducer);
  const [id, setId] = useState("");
  // Add more states as needed

  const [image, setImage] = useState<string>();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  useEffect(() => {
    dispatch(loadAuth());
  }, []); // eslint-disable-line

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      const file = event.target.files[0];

      if (!validImageTypes.includes(file.type)) {
        alert("Please select a valid image file (jpeg, png, or jpg).");
        return; // Skip invalid files
      }

      if (file.size > 10 * 1024 * 1024) {
        alert("The file size should be less than 10 MB.");
        return; // Skip large files
      }

      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };

        const compressedBlob = await imageCompression(file, options);
        const compressedFile = new File([compressedBlob], file.name, {
          type: file.type,
          lastModified: new Date().getTime(),
        });

        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          setImage(e.target?.result as string);
          setSelectedFile(compressedFile);
        };
        fileReader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error(error);
        alert("An error occurred while compressing the image.");
      }
    }
  };

  const handleRemoveImage = () => {
    setImage(undefined);
    setSelectedFile(null);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!selectedFile) {
      alert("Please select an image.");
      return;
    }

    try {
      const response = await uploadFaceImage(selectedFile, authState.uid, id); // Note: Adjust the upload function if needed
      console.log(response.data);
      alert("Image and metadata uploaded successfully.");
    } catch (error) {
      console.error(error);
      alert("Error uploading image.");
    }
  };

  return (
    <Container>
      {image && (
        <PreviewContainer>
          <ImageContainer>
            <ImagePreview src={image} alt={`Image Preview`} />
            <RemoveButton onClick={() => handleRemoveImage()}>X</RemoveButton>
          </ImageContainer>
        </PreviewContainer>
      )}
      <Form onSubmit={handleSubmit}>
        <StyledLabel>
          Upload Images
          <input
            type="file"
            onChange={(e) => handleImageChange(e)}
            accept="image/*"
            multiple
          />
        </StyledLabel>

        <Input
          placeholder="id"
          value={id}
          onChange={(e) => setId(e.target.value)}
        />

        <Button type="submit">Upload Image</Button>
      </Form>
    </Container>
  );
}

export default ManageFaces;
