import React from "react";
import styled from "styled-components";

export const LegalBox = styled.div`
  margin: 5rem;

  @media screen and (max-width: 682px) {
    margin: 2rem;
  }
`;

function Terms() {
  return (
    <LegalBox>
      <h1>Terms of use</h1>
      <h2>1. Introduction</h2>
      <p>
        <b>1.1 Overview of Visagify.</b> Welcome to Visagify, an innovative web
        application designed to provide users with advanced face-swapping
        capabilities. Our platform utilizes cutting-edge technology to allow
        users to seamlessly swap faces in images and videos, offering a unique
        and engaging experience. Visagify is accessible to a wide range of
        users, with various subscription levels to suit different needs.
      </p>
      <p>
        <b>1.2 Acceptance of Terms.</b> By accessing or using Visagify, whether
        through a free, standard, or premium subscription, you agree to be bound
        by these Terms of Use ("Terms"). These Terms govern your access to and
        use of Visagify's services and any images, videos, information, text,
        graphics, or other materials uploaded, downloaded, or appearing on the
        Services (collectively referred to as "Content"). If you do not agree to
        these Terms, you should not use Visagify.
      </p>
      <p>
        <b>1.3 Modifications to Terms.</b> Visagify reserves the right, at its
        sole discretion, to modify or replace these Terms at any time. If the
        alterations constitute a material change to the Terms, Visagify will
        notify you by posting an announcement on the service or sending you an
        email. What constitutes a material change will be determined at
        Visagify's sole discretion. You are advised to review these Terms
        periodically for any changes. Your continued use of the service
        following the posting of any changes to the Terms constitutes acceptance
        of those changes.
      </p>
      <p>
        <b>1.4 Legal Capacity.</b> By using Visagify, you affirm that you are of
        legal age to enter into these Terms, or, if you are not, that you have
        obtained parental or guardian consent to enter into these Terms. If you
        do not meet these requirements or do not agree to these Terms, you may
        not use the Visagify service.
      </p>
      <p>
        <b>1.5 Contact Information.</b> For any questions about these Terms of
        Use, please contact us at [insert your contact email or contact form
        link]. We are committed to addressing your inquiries and concerns
        promptly.
      </p>
      <h2>2. Account Registration and Subscriptions</h2>
      <p>
        <b>2.1 Account Registration.</b> To access and use the features of
        Visagify, you must register and create an account. By registering, you
        agree to provide accurate, current, and complete information about
        yourself as prompted by our registration form. You are responsible for
        maintaining the confidentiality of your account and password and for
        restricting access to your computer or device. You agree to accept
        responsibility for all activities that occur under your account or
        password. Visagify reserves the right to refuse service, terminate
        accounts, or remove or edit content in its sole discretion.
      </p>
      <p>
        <b>2.2 Subscription Tiers.</b> Visagify offers different subscription
        tiers with varying features and limitations: Free Subscription: This
        tier is available at no cost and allows up to 10 image face swaps per
        month. Video face swaps and NSFW content uploads are not permitted under
        this subscription. Standard Subscription: For a fee of 7€ per month,
        this tier offers unlimited image face swaps and allows up to 5 video
        face swaps per month. NSFW content uploads are not permitted under this
        subscription. Premium Subscription: Priced at 24€ per month, this tier
        offers unlimited image and video face swaps and grants access to upload
        NSFW content.
      </p>
      <p>
        <b>2.3 Subscription Payment and Renewal.</b> Payment for the Standard
        and Premium subscriptions must be made in advance. Your subscription
        will automatically renew each month, and your chosen payment method will
        be charged the applicable subscription fee unless you cancel your
        subscription before the renewal date.
      </p>
      <p>
        <b>2.4 Subscription Changes and Cancellations.</b> You may upgrade or
        downgrade your subscription tier at any time. Any changes will take
        effect at the start of the next billing cycle. If you choose to cancel
        your subscription, you may use your subscription until the end of your
        then-current subscription term; your subscription will not be renewed
        after your then-current term expires.
      </p>
      <p>
        <b>2.5 Account Security.</b> You are responsible for safeguarding the
        password that you use to access Visagify and for any activities or
        actions under your password. Visagify cannot and will not be liable for
        any loss or damage arising from your failure to comply with this
        security obligation.
      </p>
      <p>
        <b>2.6 Account Termination by Visagify.</b> Visagify reserves the right
        to terminate your account or your access to the Services immediately,
        without prior notice or liability, if you breach any of these Terms.
        Upon termination, your right to use the Services will immediately cease.
      </p>
      <h2>3. User Responsibilities and Conduct</h2>
      <p>
        <b>3.1 Acceptable Use Policy.</b> As a user of Visagify, you are
        expected to use the Services responsibly and ethically. This includes
        complying with all applicable laws and regulations in your use of
        Visagify and not using the Services in a way that is harmful to others
        or to Visagify's operation and reputation.
      </p>
      <p>
        <b>3.2 Content Upload Guidelines.</b> General Guidelines: Users are
        responsible for ensuring that the content they upload, including images
        and videos for face swapping, complies with these Terms and does not
        infringe upon the rights of others. Content that is illegal, offensive,
        or promotes harm is strictly prohibited. NSFW Content: Non-subscribing
        users are not permitted to upload NSFW (Not Safe For Work) content.
        Subscribers of the Premium tier may upload NSFW content, but even within
        this tier, content that is illegal or violates the rights of others is
        not allowed. Copyrighted Material: Users must not upload content that
        infringes on the intellectual property rights of others. Visagify
        reserves the right to remove any content that is believed to infringe
        upon copyright and to take further action, including account
        termination, against users who repeatedly infringe or violate these
        Terms.
      </p>
      <p>
        <b>3.3 Redistribution and Sharing of Content.</b> Users are permitted to
        share or redistribute face-swapped media created on Visagify. However,
        users must ensure that their sharing and redistribution practices do not
        violate the rights of others, including privacy and intellectual
        property rights.
      </p>
      <p>
        <b>3.4 Prohibited Conduct.</b> You agree not to engage in any of the
        following prohibited activities: Using the Services for any illegal
        purpose or in violation of any local, state, national, or international
        law. Violating or encouraging others to violate the rights of third
        parties, including privacy and intellectual property rights. Posting or
        transmitting content that is fraudulent, false, misleading, or
        deceptive. Using Visagify to harm the reputation or image of any person
        or entity.
      </p>
      <p>
        <b>3.5 Responsibility for Conduct and Content.</b> You are solely
        responsible for your conduct while using Visagify and for the content
        you upload or create using the Services. Visagify is not responsible for
        the content users upload and does not endorse any user-generated
        content.
      </p>
      <p>
        <b>3.6 Reporting Violations.</b> If you encounter any content on
        Visagify that you believe violates these Terms or your rights, please
        report it to us immediately at [insert contact email or reporting
        mechanism].
      </p>
      <h2>4. Intellectual Property Rights</h2>
      <p>
        <b>4.1 User Content.</b> As a user of Visagify, you retain all rights to
        the images and videos you upload to the platform (referred to as "User
        Content"). By uploading User Content, you grant Visagify a worldwide,
        non-exclusive, royalty-free, sublicensable, and transferable license to
        use, reproduce, distribute, prepare derivative works of, display, and
        perform the User Content in connection with the Service and Visagify's
        (and its successors' and affiliates') business, including without
        limitation for promoting and redistributing part or all of the Service
        (and derivative works thereof) in any media formats and through any
        media channels.
      </p>
      <p>
        <b>4.2 Face-Swapped Content.</b> The face-swapped images and videos
        created using Visagify's services are subject to shared intellectual
        property rights. Both you and Visagify retain rights over the
        face-swapped content. Visagify is granted the right to use the
        face-swapped content without restrictions for promotional or other
        purposes, in line with the granted license for User Content.
      </p>
      <p>
        <b>4.3 Copyrighted Material.</b> You may not upload, post, or transmit
        any content that infringes on the copyright or other intellectual
        property rights of a third party. Visagify assumes no responsibility for
        the conduct of users who infringe the intellectual property rights of
        others. If you believe that your copyright or intellectual property
        rights have been violated by a user of Visagify, please contact us at
        [insert contact information], and we will investigate the matter.
      </p>
      <p>
        <b>4.4 Copyright Compliance and DMCA Policy.</b> Visagify respects the
        intellectual property rights of others and expects its users to do the
        same. We will respond to notices of alleged copyright infringement that
        comply with applicable law and are properly provided to us.
      </p>
      <p>
        <b>4.5 Trademarks.</b> The trademarks, service marks, and logos of
        Visagify (the "Visagify Trademarks") used and displayed on the Service
        are registered and unregistered trademarks or service marks of Visagify.
        Other company, product, and service names located on the Services may be
        trademarks or service marks owned by third parties (the "Third-Party
        Trademarks", and, collectively with Visagify Trademarks, the
        "Trademarks"). Nothing on the Service should be construed as granting,
        by implication, estoppel, or otherwise, any license or right to use the
        Trademarks, without our prior written permission specific for each such
        use.
      </p>
      <h2>5. Data Privacy and Security</h2>
      <p>
        <b>5.1 Collection of Personal.</b> Information In the course of using
        Visagify, you may be required to provide personal information such as
        your email address, first name, and last name. This information is
        collected to enhance your experience with Visagify, provide customer
        support, communicate updates, and manage your account. Visagify is
        committed to safeguarding your personal information and will use it only
        in accordance with these Terms.
      </p>
      <p>
        <b>5.2 Use of Personal Data.</b> Your personal data will be used to
        manage your account, provide customer service, communicate about your
        subscription and updates to Visagify services, and other purposes
        directly related to your use of Visagify. We will not share, sell, rent,
        or disclose your personal information to third parties without your
        consent, except as required by law or as necessary to provide you with
        Visagify services.
      </p>
      <p>
        <b>5.3 Data Storage and Security.</b> Visagify employs robust security
        measures to protect the data under our control against unauthorized
        access, alteration, disclosure, or destruction. Your personal data is
        stored securely using Google's cloud services, which adhere to high
        standards of security and data protection. However, no method of
        transmission over the Internet or method of electronic storage is 100%
        secure; therefore, while we strive to use commercially acceptable means
        to protect your personal information, we cannot guarantee its absolute
        security.
      </p>
      <p>
        <b>5.4 User Responsibilities.</b> You are responsible for maintaining
        the confidentiality of your account information, including your
        password, and for any and all activities that occur under your account.
        You agree to immediately notify Visagify of any unauthorized use of your
        account or any other breach of security.
      </p>
      <p>
        <b>5.5 Data Retention.</b> Visagify will retain your personal
        information only for as long as necessary to fulfill the purposes for
        which it was collected and as required by applicable law. When your
        personal information is no longer required, we will ensure it is
        securely destroyed, erased, or anonymized.
      </p>
      <p>
        <b>5.6 Access to Your Data.</b> You have the right to access, correct,
        or delete your personal data held by Visagify. If you wish to exercise
        these rights, please contact us at [insert contact information]. We will
        respond to your request in accordance with applicable laws. 5.7 Changes
        to Privacy Policy Visagify reserves the right to update this privacy
        policy at any time. We will notify you of any changes by posting the new
        privacy policy on the Visagify platform and, if the changes are
        significant, we will provide a more prominent notice. You are advised to
        review this privacy policy periodically for any changes.
      </p>
      <h2>6. Liability and Disclaimers</h2>
      <p>
        <b>6.1 Disclaimer of Warranties.</b> You expressly understand and agree
        that your use of Visagify and its services is at your sole risk. The
        services are provided on an "AS IS" and "AS AVAILABLE" basis. Visagify
        and its affiliates, subsidiaries, officers, directors, employees,
        agents, partners, and licensors expressly disclaim all warranties of any
        kind, whether express or implied, including but not limited to the
        implied warranties of merchantability, fitness for a particular purpose,
        and non-infringement.
      </p>
      <p>
        <b>6.2 Service Availability.</b> Visagify makes no warranty that (i) the
        service will meet your requirements; (ii) the service will be
        uninterrupted, timely, secure, or error-free; (iii) the results that may
        be obtained from the use of the service will be accurate or reliable;
        (iv) the quality of any products, services, information, or other
        material purchased or obtained by you through the service will meet your
        expectations; and (v) any errors in the service will be corrected.
      </p>
      <p>
        <b>6.3 Limitation of Liability.</b> You expressly understand and agree
        that Visagify and its affiliates, subsidiaries, officers, directors,
        employees, agents, partners, and licensors shall not be liable to you
        for any direct, indirect, incidental, special, consequential, or
        exemplary damages, including but not limited to, damages for loss of
        profits, goodwill, use, data, or other intangible losses (even if
        Visagify has been advised of the possibility of such damages), resulting
        from: (i) the use or the inability to use the service; (ii) the cost of
        procurement of substitute goods and services; (iii) unauthorized access
        to or alteration of your transmissions or data; (iv) statements or
        conduct of any third party on the service; or (v) any other matter
        relating to the service.
      </p>
      <p>
        <b>6.4 Content Disclaimer.</b> Visagify does not control, endorse, or
        take responsibility for any third-party content available on or linked
        to by the service, including user-generated content.
      </p>
      <p>
        <b>6.5 User Content and Conduct.</b> Visagify is not responsible for the
        content uploaded by users and does not endorse any user-generated
        content. Users are solely responsible for their own content and the
        consequences of submitting and publishing their content on the service.
        Visagify is not liable for any errors or omissions in any content or for
        any loss or damage of any kind incurred as a result of the use of any
        content posted, emailed, transmitted, or otherwise made available via
        the services.
      </p>
      <p>
        <b>6.6 Acknowledgement.</b> You acknowledge that Visagify does not
        pre-screen content, but that Visagify and its designees shall have the
        right (but not the obligation) in their sole discretion to refuse or
        remove any content that is available via the service. Without limiting
        the foregoing, Visagify and its designees shall have the right to remove
        any content that violates these Terms or is deemed by Visagify, in its
        sole discretion, to be otherwise objectionable.
      </p>
      <h2>7. Modification and Termination of Services</h2>
      <p>
        <b>7.1 Modification of Services.</b> Visagify reserves the right to
        modify, suspend, or discontinue, temporarily or permanently, the Service
        (or any part thereof) with or without notice at any time. Changes to the
        Services may include the addition or removal of features, including
        subscription tiers, or changes to provided tools and functionalities. We
        are not liable to you or to any third party for any modification, price
        change, suspension, or discontinuance of the Service.
      </p>
      <p>
        <b>7.2 Notification of Changes.</b> In the event of any significant
        changes to the Service or these Terms, we will provide notice through
        the Service or by contacting you via the email address associated with
        your account. Your continued use of the Service after such notice
        constitutes your acceptance of the changes. If you do not agree to the
        changes, you may cancel your subscription and stop using the Service.
      </p>
      <p>
        <b>7.3 Termination by User.</b> You may terminate your use of Visagify
        and your account at any time. To terminate your account, please follow
        the instructions in the account settings or contact our support team at
        [insert contact information]. Upon termination, your account will be
        deactivated, and you will no longer have access to your account or any
        data associated with it.
      </p>
      <p>
        <b>7.4 Termination by Visagify.</b> Visagify may terminate or suspend
        your access to the Service immediately, without prior notice or
        liability, for any reason whatsoever, including, without limitation, if
        you breach these Terms. Upon termination, your right to use the Service
        will immediately cease. If you wish to terminate your account, you may
        simply discontinue using the Service.
      </p>
      <p>
        <b>7.5 Effect of Termination.</b> Upon termination of your account, your
        right to use the Service stops right away. You acknowledge that
        termination of your account may involve deletion of your information
        from our live databases. Visagify will not have any liability whatsoever
        to you for any suspension or termination, including for deletion of your
        data.
      </p>
      <p>
        <b>7.6 Data Preservation.</b> In the event of account termination,
        Visagify may keep your account information and content for a limited
        period in our backup copies, as required by law or for legitimate
        business purposes.
      </p>
      <h2>8. Payment and Cancellation Policy</h2>
      <p>
        <b>8.1 Payment Terms.</b> <br /> Free Subscription: No payment is
        required for users subscribing to the Free tier of Visagify services.
        <br /> Standard Subscription: The Standard subscription is priced at 7€
        per month. Payment is due in advance on a monthly basis.
        <br /> Premium Subscription: The Premium subscription is priced at 24€
        per month. Payment is due in advance on a monthly basis. Payments must
        be made using the payment methods offered through Visagify. <br />
        You represent and warrant that any payment information you provide is
        accurate and that you are authorized to use the payment method in
        question.
      </p>
      <p>
        <b>8.2 Billing Cycle.</b> The subscription fee is billed at the
        beginning of each subscription period and may take a few days after the
        billing date to appear on your account. The subscription period for the
        Standard and Premium tiers is one month, commencing on the date you
        subscribe to a paid tier and renewing automatically each month.
      </p>
      <p>
        <b>8.3 Renewal and Cancellation.</b> Your Visagify subscription will
        automatically renew at the end of each subscription period, unless you
        cancel it before the end of the current period. You can cancel your
        subscription at any time through your account settings or by contacting
        our customer service. If you cancel, you can continue to use the
        subscription until the end of the current billing cycle.
      </p>
      <p>
        <b>8.4 Refunds.</b> Payments are non-refundable and there are no refunds
        or credits for partially used periods, except as required by applicable
        law. In the event of a cancellation, your subscription will remain
        active until the end of the current billing cycle.
      </p>
      <p>
        <b>8.5 Changes in Fees.</b> Visagify reserves the right to adjust the
        pricing for its services or any components thereof in any manner and at
        any time as we may determine in our sole discretion. Any price changes
        will take effect following email notice to you.
      </p>
      <p>
        <b>8.6 Failed Payments.</b> In the case of a failed payment, access to
        the paid services will be suspended until successful payment is made.
        Visagify reserves the right to pursue any amounts you fail to pay in
        connection with the services and you will be responsible for all costs
        and expenses incurred by Visagify in connection with such collection
        activity, including collection fees, court costs, and attorneys' fees.
      </p>
      <p>
        <b>8.7 Account Deactivation for Non-Payment.</b> If a subscription fee
        payment fails and remains unpaid, Visagify reserves the right to
        deactivate your account after a reasonable period. Reactivation of your
        account may require payment of the outstanding balance and a
        reactivation fee.
      </p>
      <h2>9. Governing Law and Dispute Resolution</h2>
      <p>
        <b>9.1 Governing Law.</b> These Terms of Use and any action related
        thereto shall be governed by and construed in accordance with the laws
        of France, without regard to or application of its conflict of law
        provisions or your state or country of residence. All claims, legal
        proceedings, or litigation arising in connection with the Services will
        be brought solely in France, and you consent to the jurisdiction of and
        venue in such courts and waive any objection as to inconvenient forum.
      </p>
      <p>
        <b>9.2 Dispute Resolution.</b> In the event of a dispute arising under
        or in connection with these Terms or the Service, the following process
        shall apply: Initial Resolution Attempt: You agree to first try to
        resolve the dispute informally by contacting Visagify at [insert contact
        information]. We will attempt to resolve the dispute informally by
        contacting you via email. Binding Arbitration: If the dispute is not
        resolved through informal negotiation within a specified period (e.g.,
        30 days), either party may elect to have the dispute finally and
        exclusively resolved by binding arbitration. Any election to arbitrate,
        at any time, shall be final and binding on the other party. Arbitration
        Process: The arbitration will be conducted in Paris, France, by a
        neutral arbitrator, whose decision will be final and binding, and no
        court shall have jurisdiction over any appeal of the arbitration award.
        The arbitration shall be conducted in accordance with the rules of an
        established arbitration institution mutually agreed upon by the parties.
        Costs of Arbitration: The allocation and payment of costs and fees for
        the arbitration will be determined by the arbitrator.
      </p>
      <p>
        <b>9.3 Class Action Waiver.</b> You agree that any arbitration or
        proceeding shall be limited to the dispute between us and you
        individually. To the full extent permitted by law, (a) no arbitration or
        proceeding shall be joined with any other; (b) there is no right or
        authority for any dispute to be arbitrated or resolved on a class-action
        basis or to utilize class action procedures; and (c) there is no right
        or authority for any dispute to be brought in a purported representative
        capacity on behalf of the general public or any other persons.
      </p>
      <p>
        <b>9.4 Exception – Small Claims Court.</b> Claims Notwithstanding the
        parties' decision to resolve all disputes through arbitration, either
        party may seek relief in a small claims court for disputes or claims
        within the scope of that court’s jurisdiction.
      </p>
      <p>
        <b>9.5 Time Limitation on Claims.</b> You agree that any claim you may
        have arising out of or related to your relationship with Visagify must
        be filed within one year after such claim arose; otherwise, your claim
        is permanently barred.
      </p>
      <h2>10. Miscellaneous Provisions</h2>
      <p>
        <b>10.1 Entire Agreement.</b> These Terms of Use, including any legal
        notices and disclaimers contained on this website, constitute the entire
        agreement between Visagify and you in relation to your use of the
        Service. This agreement supersedes all prior agreements and
        understandings with respect to the same.
      </p>
      <p>
        <b>10.2 Waiver.</b> The failure of Visagify to exercise or enforce any
        right or provision of these Terms of Use shall not operate as a waiver
        of such right or provision. Any waiver of any provision of these Terms
        of Use will be effective only if in writing and signed by Visagify.
      </p>
      <p>
        <b>10.3 Severability.</b> If any provision of these Terms is found by a
        court of competent jurisdiction to be invalid, the parties nevertheless
        agree that the court should endeavor to give effect to the parties'
        intentions as reflected in the provision, and the other provisions of
        these Terms remain in full force and effect.
      </p>
      <p>
        <b>10.4 Assignment.</b> You may not assign or transfer these Terms, by
        operation of law or otherwise, without Visagify's prior written consent.
        Any attempt by you to assign or transfer these Terms, without such
        consent, will be null and of no effect. Visagify may assign or transfer
        these Terms, at its sole discretion, without restriction.
      </p>
      <p>
        <b>10.5 Force Majeure.</b> Visagify shall not be liable for any failure
        to perform its obligations hereunder where such failure results from any
        cause beyond Visagify's reasonable control, including, without
        limitation, mechanical, electronic, or communications failure or
        degradation.
      </p>
      <p>
        <b>10.6 Headings.</b> The section titles in these Terms of Use are for
        convenience only and have no legal or contractual effect.
      </p>
      <p>
        <b>10.7 Non-Disparagement.</b> You agree not to engage in any conduct or
        communication, public or private, designed to disparage Visagify, its
        Services, or any of its users.
      </p>
      <p>
        <b>10.8 Compliance with Law.</b> You agree to comply with all applicable
        laws, statutes, ordinances, and regulations regarding your use of the
        Service and your purchase of items or services through the Service’s
        affiliated sites.
      </p>
      <p>
        <b>10.9 Contact Information.</b> If you have any questions about these
        Terms, please contact us at [insert contact information].
      </p>
      <h2>11. Acceptance of the Terms</h2>
      <p>
        <b>11.1 Agreement to Terms.</b> By accessing or using Visagify's
        services, you acknowledge that you have read, understood, and agree to
        be bound by these Terms of Use. If you do not agree with any part of
        these Terms, you must not use the Service. Your continued use of
        Visagify after any modifications to these Terms implies your acceptance
        of those changes.
      </p>
      <p>
        <b>11.2 Representations and Warranties.</b> You represent and warrant
        that: You have the legal capacity to enter into these Terms and form a
        binding agreement under any applicable law. You will use Visagify's
        services in accordance with these Terms and all applicable laws, rules,
        and regulations. All information you provide to Visagify is true,
        accurate, current, and complete.
      </p>
      <p>
        <b>11.3 Updates to the Terms.</b> Visagify reserves the right, at its
        sole discretion, to modify or replace these Terms at any time. If a
        revision is material, we will provide at least 30 days' notice prior to
        any new terms taking effect. What constitutes a material change will be
        determined at our sole discretion.
      </p>
      <p>
        <b>11.4 Consent to Electronic Communications.</b> By using Visagify's
        services, you consent to receiving electronic communications from
        Visagify. These communications may include notices about your account
        and information concerning or related to our service. You agree that any
        notices, agreements, disclosures, or other communications that we send
        to you electronically will satisfy any legal communication requirements,
        including that such communications be in writing.
      </p>
      <p>
        <b>11.5 Acknowledgement.</b> Your use of Visagify's services is subject
        to your acceptance of these Terms of Use and all its revisions. Your
        acceptance of these terms binds you to the provisions herein.
      </p>
    </LegalBox>
  );
}

export default Terms;
