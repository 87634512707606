import React, { useRef, useEffect } from "react";
import styled from "styled-components";

const AutoHeightTextarea = styled.textarea`
  overflow: hidden;
  resize: none; // optional: to prevent manual resizing
  padding: 10px;
  margin: 5px 0;
  min-width: 40vw;
  border-radius: 0.5rem;
  background-color: rgb(255 255 255/20%);
  box-shadow: 2px 2px 5px 0 rgb(0 0 0 / 15%),
    -2px -2px 5px 0 rgb(255 255 255 / 50%);
  outline: none;
  color: #fff;
  transition: all 0.3s;
  font-size: 17px !important;

  &:hover {
    background-color: rgb(255 255 255/10%);
    box-shadow: inset 2px 2px 5px 0 rgb(0 0 0 / 50%),
      inset -2px -2px 5px 0 rgb(255 255 255 / 15%);
  }

  &:focus {
    background-color: rgb(255 255 255/10%);
    box-shadow: inset 2px 2px 5px 0 rgb(0 0 0 / 50%),
      inset -2px -2px 5px 0 rgb(255 255 255 / 15%);
  }

  &:not(:placeholder-shown) {
    background-color: rgb(255 255 255/10%);
    box-shadow: inset 2px 2px 5px 0 rgb(0 0 0 / 50%),
      inset -2px -2px 5px 0 rgb(255 255 255 / 15%);
  }

  @media screen and (max-width: 621px) {
    min-width: 55vw;
    height: 35px;
  }
`;

interface Props {
  value: string;
  onChange: (value: string) => void;
}

const TextAreaResizable: React.FC<Props> = ({ value, onChange }) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset to default height
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + "px";
    }
  }, [value]); // Update height whenever the value changes

  return (
    <AutoHeightTextarea
      ref={textareaRef}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

export default TextAreaResizable;
