import React from "react";
import { EmptyProgressBar, FillingProgressBar } from "./LinearProgress.styled";

function LinearProgress(props: { percent: number }) {
  return (
    <EmptyProgressBar>
      <FillingProgressBar percent={props.percent} />
    </EmptyProgressBar>
  );
}

export default LinearProgress;
