import styled from "styled-components";
import { ICollapseItem } from "../SidenavCollapse.styled";

const item = {
  width: "100%",
  padding: 0,
  cursor: "pointer",
};

const ItemContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  margin: 0 10px 0 10px;
  user-select: none;
  position: relative;

  & span {
    color: ${(props: ICollapseItem) =>
      props.active ? "#fff" : "rgba(255,255,255, 0.7)"};
    font-weight: ${(props: ICollapseItem) => (props.active ? 500 : 400)};
    font-size: 1rem;
    opacity: ${(props: ICollapseItem) => (props.miniSidenav ? 0 : 1)};
    transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
`;

export { item, ItemContent };
