import styled from "styled-components";
import { palette } from "../../assets/style/colors";

export const ButtonVisagify = styled.button`
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  outline: 0;
  border: 0;
  margin: 0.5rem 0;
  font-family: "DM Serif Text", "Roboto", "Helvetica", "Arial", sans-serif;
  letter-spacing: 0.02857em;
  min-width: 64px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 0.5rem;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  min-height: 2.5rem;
  padding: 0.75rem 1.5rem;
  background-color: ${palette.primary.button};
  color: ${palette.primary.buttonText};
  border: 1px solid #ffffff22;

  vertical-align: middle;
  text-decoration: none;
  white-space: nowrap;
  text-decoration: none;
  z-index: 1;
  flex-flow: row nowrap;

  // box-shadow: rgb(12 22 44 / 30%) 0px 9px 20px 1px;
  vertical-align: middle;
  appearance: none;
  padding: 0.6rem;

  transition: all ease-in-out 300ms;

  @media screen and (max-width: 462px) {
    min-width: 50px;
  }

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  /* background: linear-gradient(45deg, #ffda7e, #e97cbb, #3d47d9);
  will-change: background, opacity, filter;
  background-size: 300%;
  animation: _mp-title-color 16s linear infinite;

  @keyframes _mp-title-color {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  } */
`;

export const ButtonVisagifyDark = styled.button`
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  outline: 0;
  border: 0;
  margin: 0.5rem 0;
  font-family: "DM Serif Text", "Roboto", "Helvetica", "Arial", sans-serif;
  letter-spacing: 0.02857em;
  min-width: 64px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 0.5rem;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  min-height: 2.5rem;
  padding: 0.75rem 1.5rem;
  background-color: #000;
  color: #fff;
  border: 1px solid #ffffff22;

  vertical-align: middle;
  text-decoration: none;
  white-space: nowrap;
  text-decoration: none;
  flex-flow: row nowrap;

  // box-shadow: rgb(12 22 44 / 30%) 0px 9px 20px 1px;
  vertical-align: middle;
  appearance: none;
  padding: 0.6rem;

  transition: all ease-in-out 300ms;

  @media screen and (max-width: 462px) {
    min-width: 50px;
  }

  &:hover {
    box-shadow: rgba(8, 255, 8, 0.8) 0px 4px 8px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  /* background: linear-gradient(45deg, #ffda7e, #e97cbb, #3d47d9);
  will-change: background, opacity, filter;
  background-size: 300%;
  animation: _mp-title-color 16s linear infinite;

  @keyframes _mp-title-color {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  } */
`;
