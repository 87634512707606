import axios from "axios";

export interface MetadataSoccer {
  playerName: string;
  gender: string;
  ethnicity: string;
  country: string;
  club: string;
  ageRange: string;
  faceShape: string;
  hairType: string;
  hairColor: string;
  hairLength: string;
  eyesShape: string;
  noseShape: string;
  lipsShape: string;
  cheeksShape: string;
}

/**
 * Uploads the provided image files along with their metadata.
 * @param {File[]} imageFiles The array of image files to upload.
 * @param {Metadata} metadata The metadata associated with the images.
 * @returns Promise resolved with the API response or rejected with an error.
 */
const uploadProSoccerImages = async (
  imageFiles: File[],
  metadata: MetadataSoccer
) => {
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  const url = `${baseUrl}/admin/prosoccer/upload`;

  const formData = new FormData();
  imageFiles.forEach((file, index) => {
    formData.append("images", file, `${index}_${file.name}`);
  });
  formData.append("metadata", JSON.stringify(metadata));

  const response = await axios.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response;
};

export default uploadProSoccerImages;
