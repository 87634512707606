// src/pages/VisualGalleryPage.tsx
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import readSwaps from "../../utils/api/readSwaps";
import ImageModal from "./ImageModal";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { setFaceSwapProcessing } from "../../store/applications/UIReducer";
import { loadAuth } from "../../store/applications/AuthReducer";

const GalleryPageContainer = styled.div`
  // Using CSS columns for masonry layout
  column-count: 6;
  column-gap: 16px;
  width: 95vw;
  max-width: 1440px;
  margin: 0 auto;
  padding: 20px 0;

  @media (max-width: 1024px) {
    column-count: 4;
  }

  @media (max-width: 768px) {
    column-count: 3;
  }

  @media (max-width: 480px) {
    column-count: 2;
  }
`;

const HoverOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
  cursor: pointer;
`;

const StyledCard = styled.div`
  background: #fff;
  display: inline-block;
  margin: 0 0 16px;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  img {
    width: 100%;
    display: block;
    object-fit: cover;
    height: auto; // Ensure full image is displayed
  }

  position: relative;

  &:hover ${HoverOverlay} {
    opacity: 0.5;
  }
`;

type SwapData = {
  id: string;
  url: string;
};

const VisualGalleryPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const authState = useAppSelector((state) => state.AuthReducer);
  const uiState = useAppSelector((state) => state.UIReducer);
  const [swaps, setSwaps] = useState<SwapData[]>([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(
    null
  );
  // const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [galleryLength, setGalleryLength] = useState(0);
  // const [listen, setListen] = useState(false);

  useEffect(() => {
    dispatch(loadAuth());
  }, []); // eslint-disable-line

  useEffect(() => {
    const fetchSwaps = async () => {
      try {
        const swapData = await readSwaps(authState.uid);
        setGalleryLength(swapData.length);
        setSwaps(
          Object.keys(swapData).map((key) => ({
            id: key,
            url: swapData[key].image_url,
          }))
        );
      } catch (error) {
        console.error("Failed to fetch swaps:", error);
      }
    };

    authState.uid && fetchSwaps();
  }, [galleryLength, authState.uid]);

  // useEffect(() => {
  //   const baseUrl = process.env.REACT_APP_API_BASEURL_LOCAL;
  //   const url = `${baseUrl}/subscribe-updates/${authState.uid}`;

  //   if (authState.uid) {
  //     const eventSource = new EventSource(url);
  //     eventSource.onmessage = (event) => {
  //       const data = JSON.parse(event.data);
  //       // Handle the real-time data
  //       if (data.swaps.length > galleryLength) {
  //         dispatch(setFaceSwapProcessing(false));
  //         setGalleryLength(data.swaps.length);
  //       }
  //       setSwaps(
  //         Object.keys(data.swaps).map((key) => ({
  //           id: key,
  //           url: data.swaps[key].image_url,
  //         }))
  //       );
  //     };
  //     return () => {
  //       eventSource.close();
  //     };
  //   }
  // }, [galleryLength, authState.uid]); // eslint-disable-line

  useEffect(() => {
    const fetchSwaps = async () => {
      try {
        const swapData = await readSwaps(authState.uid);
        if (swapData.length > galleryLength) {
          dispatch(setFaceSwapProcessing(false));
          setGalleryLength(swapData.length);
          setSwaps(
            Object.keys(swapData).map((key) => ({
              id: key,
              url: swapData[key].image_url,
            }))
          );
        }
      } catch (error) {
        console.error("Failed to fetch swaps:", error);
      }
    };

    // Interval to perform a check every 30 seconds
    const intervalId = setInterval(() => {
      if (uiState.faceSwapProcessing) {
        authState.uid && fetchSwaps();
        console.log("Listenning ...");
      }
    }, 15000);

    // Clean up the interval when the component unmounts or uid changes
    return () => {
      clearInterval(intervalId);
    };
  }, [galleryLength, authState.uid, uiState.faceSwapProcessing]); // eslint-disable-line

  const openModal = (index: number) => {
    setSelectedImageIndex(index);
  };

  const closeModal = () => {
    setSelectedImageIndex(null);
  };

  const handlePrevious = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex !== null && prevIndex > 0 ? prevIndex - 1 : swaps.length - 1
    );
  };

  const handleNext = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex !== null && prevIndex < swaps.length - 1 ? prevIndex + 1 : 0
    );
  };

  return (
    <>
      <GalleryPageContainer>
        {swaps.map((swap, index) => (
          <StyledCard key={swap.id} onClick={() => openModal(index)}>
            <img src={swap.url} alt={`Swap Media ${swap.id}`} />
            <HoverOverlay></HoverOverlay>
          </StyledCard>
        ))}
      </GalleryPageContainer>
      {selectedImageIndex !== null && (
        <ImageModal
          url={swaps[selectedImageIndex].url}
          onClose={closeModal}
          onNext={handleNext}
          onPrevious={handlePrevious}
        />
      )}
    </>
  );
};

export default VisualGalleryPage;
