import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { useLocation } from "react-router-dom";
import { Palette, palette } from "../assets/style/colors";

const ThemeContext = createContext<Palette>(palette.primary);

export const useTheme = () => useContext(ThemeContext);

export interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const location = useLocation();
  const [theme, setTheme] = useState<Palette>(palette.primary);

  useEffect(() => {
    if (
      location.pathname.includes("/proshoot") ||
      location.pathname.includes("/prosoccer")
    ) {
      setTheme(palette.secondary);
    } else {
      setTheme(palette.primary);
    }
  }, [location]);

  return (
    <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
  );
};
