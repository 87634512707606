import axios from "axios";

export interface ImageMetadata {
  url: string;
  gender: string;
  ethnicity: string;
  ageRange: string;
  faceShape: string;
  hairType: string;
  hairColor: string;
  hairLength: string;
  eyesShape: string;
  noseShape: string;
  lipsShape: string;
  cheeksShape: string;
}

const readModelImages = async () => {
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  const url = `${baseUrl}/admin/proshoot/`;
  const response = await axios.get<ImageMetadata[]>(url);
  return response.data;
};

export default readModelImages;
