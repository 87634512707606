import { ref, set } from "firebase/database";
import { database } from "../../firebase/firebase";

export interface IUserData {
  createdAt: number;
  uid: string;
  customerId: string;
  pic: string;
  nom: string;
  prenom: string;
  gender: string;
  birthday: string;
  pays: string;
  ville: string;
  email: string;
  emailVerified: boolean;
  ip: string;
  subscription: string;
}

export const writeNewUser = async (
  newUserData: IUserData,
  usage: number,
  uid: string | null
) => {
  console.log(`users/${uid}`);
  try {
    set(ref(database, `users/${uid}/personaldata`), newUserData);
    set(ref(database, `users/${uid}/usage`), usage);
    const now = new Date();
    const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    console.log(`nextMonth: ${nextMonth}`);
    set(ref(database, `users/${uid}/resetDate`), nextMonth.toString());
  } catch (error) {
    console.log(error);
  }
};
