import React, { useEffect, useState } from "react";
import FaceSwapProShoot from "./FaceSwapProShoot";
import VisualGalleryPageProshoot from "../../components/VisualGalleryProshoot/VisualGalleryProshoot";
import { useLocation, useNavigate } from "react-router-dom";

function ProShoot() {
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [isAuth, setIsAuth] = useState(
    false || window.localStorage.getItem("auth-visagify") === "true"
  );

  useEffect(() => {
    if (!isAuth) {
      navigate("/");
    }
  }, []); // eslint-disable-line

  const location = useLocation();

  useEffect(() => {
    // Logic to apply styles to the body based on the path
    if (location.pathname.includes("/proshoot")) {
      document.body.classList.add("dark-theme");
    } else {
      document.body.classList.remove("dark-theme");
    }
  }, [location.pathname]);
  return (
    <div>
      <FaceSwapProShoot />
      <VisualGalleryPageProshoot />
    </div>
  );
}

export default ProShoot;
