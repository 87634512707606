import styled from "styled-components";
import morpheus from "../../assets/images/visagify-logo.png";

export const LoginPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

export const LoginBox = styled.div`
  max-width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 682px) {
    max-width: 90vw;
  }
`;

export const LoginContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const LoginHeadline = styled.h1`
  font-size: 24px;
  font-weight: 700;
`;

export const LoginSubtitle = styled.p``;

export const LoginPart1Box = styled.div`
  background: linear-gradient(
        135deg,
        rgba(26, 31, 96, 0.15) 0%,
        rgba(106, 56, 85, 0.15) 50%,
        rgba(141, 130, 104, 0.15) 100%
      )
      50% center / cover,
    url(${morpheus}) transparent;
  background-size: cover;
  background-position: 50% center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 20vh;
`;

export const LoginLogo = styled.img`
  width: 250px;
`;

export const LoginPart1 = styled.div`
  display: flex;
  backdrop-filter: blur(0.2rem);
  box-shadow: 2px 2px 20px 0 rgb(0 0 0 / 20%),
    -2px -2px 20px 0 rgb(255 255 255 / 20%);
  min-width: 20rem;
  padding: 2rem;
  margin: 1rem;
  border-radius: 1rem;
  z-index: 99;
  font-size: 3rem;
  text-align: center;
`;

export const LoginRoadmapBox = styled.div`
  display: flex;
  box-shadow: 2px 2px 20px 0 rgb(0 0 0 / 20%),
    -2px -2px 20px 0 rgb(255 255 255 / 20%);
  min-width: 20rem;
  padding: 2rem;
  margin: 1rem;
  z-index: 99;
  text-align: left;
`;

export const LoginActionBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;
  height: 100%;
`;

export const LoginCitation = styled.p`
  color: #fff;
  background-color: rgba(0 0 0 / 80%);
  padding: 1rem;
  border-radius: 1rem;

  @media screen and (max-width: 682px) {
    display: none;
  }
`;

export const LoginAction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
`;

export const LoginButton = styled.button`
  background-image: linear-gradient(310deg, #fff, #ececfb);
  box-shadow: 0px 0px 18px rgb(0 0 0 / 20%);
  padding: 0.7rem;
  min-width: calc(50vw - 4rem);
  border-radius: 0.4rem;
  font-weight: 600;
  border: none;
  transition: all 300ms ease-in-out;
  cursor: pointer;

  &:hover {
    font-weight: 700;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 20%);
  }
`;
