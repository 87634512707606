import styled from "styled-components";
import { pxToRem } from "../../utils";
import { palette } from "../../assets/style/colors";

export interface ICollapseItem {
  active: boolean;
  transparentSidenav?: boolean;
  sidenavColor?: string;
  miniSidenav?: boolean;
  nested?: boolean;
  name?: string[];
}

export const CollapseItem = styled.div`
  background: ${(props: ICollapseItem) =>
    props.active && props.transparentSidenav ? "#FFF" : "transparent"};
  color: ${(props: ICollapseItem) =>
    props.active ? palette.primary.headline : palette.primary.paragraph};
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${pxToRem(10.8)} ${pxToRem(12.8)} ${pxToRem(10.8)} ${pxToRem(16)};
  margin: 0 ${pxToRem(16)};
  border-radius: 1rem;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  box-shadow: ${(props: ICollapseItem) =>
    props.active && props.transparentSidenav ? "none" : "none"};

  @media screen and (min-width: 600px) {
  }
`;

export const CollapseIconBox = styled.div`
  background: ${(props: ICollapseItem) =>
    // eslint-disable-next-line
    props.active
      ? props.sidenavColor === "default"
        ? "#FFF"
        : "#FFF" // colors[props.sidenavColor as keyof typeof colors].main
      : "#FFF"};
  color: "#000";
  min-width: ${pxToRem(32)};
  min-height: ${pxToRem(32)};
  border-radius: 1rem;
  display: grid;
  place-items: center;
  box-shadow: "none";
  transition: margin 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  & .MuiSvgIcon-root {
    font-size: inherit !important;
  }
  /* .svg .svg .g: {
      fill: active ? white.main : gradients.dark.state,
    } */

  @media screen and (min-width: 600px) {
    background {
      ${(props: ICollapseItem) =>
        // eslint-disable-next-line
        !props.active
          ? props.transparentSidenav
            ? "#FFF"
            : "#FFF"
          : // eslint-disable-next-line
          props.sidenavColor === "default"
          ? "#FFF"
          : props.sidenavColor === "warning"
          ? "#FFF"
          : "#FFF"}
    }
  }
`;

export const CollapseIcon = styled.span`
  color: rgb(58, 65, 111);
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentcolor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: inherit !important;
`;

// const collapseIcon = ({ palette: { white, gradients } }, { active }) => ({
//   color: active ? white.main : gradients.dark.state,
// });

export const CollapseText = styled.div`
  margin-left: ${pxToRem(12.8)};

  @media screen and (min-width: 600px) {
    opacity: ${(props: ICollapseItem) => (props.transparentSidenav ? 0 : 1)};
    max-width: ${(props: ICollapseItem) =>
      props.transparentSidenav ? 0 : "100%"};
    margin-left: ${(props: ICollapseItem) =>
      props.transparentSidenav ? 0 : pxToRem(12.8)};
    transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      margin 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  & span {
    font-weight: ${(props: ICollapseItem) => (props.active ? 600 : 400)};
    font-size: 12px;
    line-height: 0;
    color: red !important;
  }
`;

export const CollapseTextBox = styled.p`
  flex: 1 1 auto;
  min-width: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0.8rem;

  @media screen and (min-width: 600px) {
    opacity: 1;
    max-width: 100%;
    margin-left: 0.8rem;
    transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      margin 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
`;

// function collapseArrow(theme, ownerState) {
//   const { palette, typography, transitions, breakpoints, functions } = theme;
//   const { noCollapse, transparentSidenav, miniSidenav, open } = ownerState;

//   const { dark, gradients } = palette;
//   const { size } = typography;
//   const { pxToRem, rgba } = functions;

//   return {
//     fontSize: `${size.md} !important`,
//     fontWeight: 700,
//     marginBottom: pxToRem(-1),
//     transform: open ? "rotate(0)" : "rotate(-180deg)",
//     color: open ? dark.main : rgba(gradients.dark.state, 0.4),
//     transition: transitions.create(["color", "transform", "opacity"], {
//       easing: transitions.easing.easeInOut,
//       duration: transitions.duration.shorter,
//     }),

//     [breakpoints.up("lg")]: {
//       display:
//         noCollapse || (transparentSidenav && miniSidenav) || miniSidenav
//           ? "none !important"
//           : "block !important",
//     },
//   };
// }

// export {
//   collapseItem,
//   collapseIconBox,
//   collapseIcon,
//   collapseText,
//   collapseArrow,
// };
