// src/components/FaceSwap.tsx
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import imageCompression from "browser-image-compression";
import {
  ButtonVisagify,
  ButtonVisagifyDark,
} from "../ButtonVisagify/ButtonVisagify";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { palette } from "../../assets/style/colors";
import {
  setFaceSwapBulkLen,
  setFaceSwapProcessing,
} from "../../store/applications/UIReducer";
import { MutatingDots } from "react-loader-spinner";
import bg from "../../assets/images/facewap-box-bg.png";
import { Subtitle, Title } from "../Hero/Hero";
import LinearProgress from "../LinearProgress/LinearProgress";
import {
  CloseButton,
  GallerySection,
  ImageTile,
  Modal,
} from "../../pages/ProShoot/FaceSwapProShoot";
import { FlexCol } from "../../pages/InterpretV2/InterpretV2";
import readFaces from "../../utils/api/user/readFaces";
import { loadAuth } from "../../store/applications/AuthReducer";
import { Input } from "../UI/Forms/Forms.styled";
import uploadFaceImage from "../../utils/api/user/writeAddFaceImage";
import { Form } from "../../pages/ManageFaces/ManageFaces";
import writeFileUploadBulk69V2 from "../../utils/api/bulk/writeFileUploadBulk69V2";
// import writeUid from "../../utils/api/writeUid";

const FaceSwapContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
  margin-top: 50px;
  min-height: 50vh;

  @media (max-width: 720px) {
    flex-direction: column-reverse;
  }
`;

const FaceSwapBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  padding: 1rem;
  border-radius: 1rem;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
  background-image: url(${bg});
  background-size: inherit;
  min-height: 50vh;
  max-width: 300px;
`;

const StyledLabel = styled.label`
  background-color: #fff;
  color: ${palette.primary.buttonText};
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 300ms ease-in-out;

  &:hover {
    background-color: #000;
    color: #fff;
    box-shadow: rgba(8, 255, 8, 0.8) 0px 4px 8px;
  }

  input {
    display: none;
  }
`;

const PreviewContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  max-width: 40vw;
  flex-wrap: wrap;
`;

const ImagePreview = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  border: 2px solid #000;
  border-radius: 10px;
`;

const RemoveButton = styled.button`
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-weight: bold;
  position: absolute;
  top: 0;
  right: 0;
  &:hover {
    background-color: darkred;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export interface FaceImageMetadata {
  url: string;
  createdAt: string;
  id: string;
}

const FaceSwapBulk69V2: React.FC = () => {
  const dispatch = useAppDispatch();
  const uiState = useAppSelector((state) => state.UIReducer);
  const [isUpload, setIsUpload] = useState(false);
  const [progress, setProgress] = useState(0);
  const [bulkLen, setBulkLen] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [faceId, setFaceId] = useState("");
  const [images, setImages] = useState<{
    targetImages: string[];
    sourceImage: string;
  }>({
    targetImages: [],
    sourceImage: "",
  });
  const [selectedFiles, setSelectedFiles] = useState<{
    targets: File[];
    source: File | null;
  }>({
    targets: [],
    source: null,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  // const [filteredImages, setFilteredImages] = useState<FaceImageMetadata[]>([]);

  const [faceImages, setFaceImages] = useState<FaceImageMetadata[]>([]);
  // New state to handle the selected model URL
  const [selectedFaceURL, setSelectedFaceURL] = useState<string | null>(null);

  const handleFaceSelect = (imageUrl: string, faceId: string) => {
    setSelectedFaceURL(imageUrl); // Set the selected model URL
    setFaceId(faceId);
    setIsModalOpen(false); // Close the modal
  };

  const confirmFaceSelection = () => {
    setIsModalOpen(false);
    // convertURLtoFile(selectedModelURL);

    // Logic to use the selected model in the face swap
  };

  // Function to fetch and set face images
  const fetchFaceImages = async () => {
    try {
      const response = await readFaces("uid"); // Use the full URL if your backend is on a different domain
      setFaceImages(response);
      // setFilteredImages(response); // Initially, no filter is applied
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  // const applyFilters = () => {
  //   const filtered = faceImages;
  //   setFilteredImages(filtered);
  // };

  // Call applyFilters whenever any filter value changes
  // useEffect(() => {
  //   applyFilters();
  //   // eslint-disable-next-line
  // }, [
  //   faceImages, // Include modelImages if it's dynamically loaded or can change
  // ]);

  useEffect(() => {
    dispatch(loadAuth());
  }, []); // eslint-disable-line

  useEffect(() => {
    fetchFaceImages();
  }, []); // eslint-disable-line

  useEffect(() => {
    const interval = setInterval(() => {
      if (uiState.faceSwapProcessing) {
        const now = new Date();
        const timeLeft = endTime - now.getTime();

        if (progress <= 100) {
          const progressRaw = (1 - timeLeft / 75000) * 100;
          setProgress(progressRaw);
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [progress, endTime]); // eslint-disable-line

  const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    imageKey: "target" | "source"
  ) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      if (imageKey === "target") {
        setBulkLen(files.length + selectedFiles.targets.length);
        console.log(files.length + selectedFiles.targets.length);
      }

      // If imageKey is 'target', allow multiple images up to 15
      if (
        imageKey === "target" &&
        files.length + selectedFiles.targets.length > 15
      ) {
        alert("You can only upload up to 15 target images.");
        return;
      }

      try {
        for (const file of files) {
          if (!validImageTypes.includes(file.type)) {
            alert("Please select a valid image file (jpeg, png, or jpg).");
            continue; // Skip invalid files
          }

          if (file.size > 10 * 1024 * 1024) {
            alert(
              "(EN) The file size should be less than 10 MB. (FR) La taille du fichier doit être inférieure à 10 Mo."
            );
            continue; // Skip large files
          }

          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          };

          const compressedBlob = await imageCompression(file, options);
          const originalFileName = file.name.replace(/\.[^/.]+$/, "");
          const compressedFileName = `${originalFileName}-compressed.jpeg`;
          const compressedFile = new File(
            [compressedBlob],
            compressedFileName,
            {
              type: "image/jpeg",
              lastModified: new Date().getTime(),
            }
          );

          const fileReader = new FileReader();
          fileReader.onload = (e) => {
            if (imageKey === "target") {
              setImages((prevImages) => ({
                ...prevImages,
                targetImages: [
                  ...prevImages.targetImages,
                  e.target?.result as string,
                ],
              }));
              setSelectedFiles((prevFiles) => ({
                ...prevFiles,
                targets: [...prevFiles.targets, compressedFile],
              }));
            } else {
              setImages((prevImages) => ({
                ...prevImages,
                sourceImage: e.target?.result as string,
              }));
              setSelectedFiles((prevFiles) => ({
                ...prevFiles,
                source: compressedFile,
              }));
            }
          };
          fileReader.readAsDataURL(compressedFile);
        }
      } catch (error) {
        console.error(error);
        alert("An error occurred while compressing the image.");
      }
    }
  };

  const handleRemoveImage = (index: number) => {
    const newTargetImages = [...images.targetImages];
    const newSelectedFiles = [...selectedFiles.targets];
    newTargetImages.splice(index, 1);
    newSelectedFiles.splice(index, 1);
    setImages({ ...images, targetImages: newTargetImages });
    setSelectedFiles({ ...selectedFiles, targets: newSelectedFiles });

    setBulkLen(selectedFiles.targets.length);
    console.log(selectedFiles.targets.length);
  };

  const handleSubmit = async () => {
    dispatch(setFaceSwapProcessing(true));
    dispatch(setFaceSwapBulkLen(bulkLen));
    setIsUpload(true);
    const time = new Date();
    const end = time.getTime() + 75000;
    setProgress(0);
    setEndTime(end);
    if (selectedFiles.targets && selectedFaceURL) {
      try {
        const response = await writeFileUploadBulk69V2(
          selectedFiles.targets,
          selectedFaceURL,
          faceId
          // selectedFiles.source
        );
        // const response = await writeUid(authState.uid);
        // Handle response
        console.log(response.data);
        alert("Faces swapped successfully!");
      } catch (error) {
        // Handle error
        console.error(error);
        alert("Failed to swap faces.");
        setIsUpload(false);
      }
    } else {
      alert("Please select both target and source images.");
    }
  };

  // Handle Add Face Image

  const [id, setId] = useState("");
  // Add more states as needed

  const [image, setImage] = useState<string>();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleImageChangeFace = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      const file = event.target.files[0];

      if (!validImageTypes.includes(file.type)) {
        alert("Please select a valid image file (jpeg, png, or jpg).");
        return; // Skip invalid files
      }

      if (file.size > 10 * 1024 * 1024) {
        alert("The file size should be less than 10 MB.");
        return; // Skip large files
      }

      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };

        const compressedBlob = await imageCompression(file, options);
        const compressedFile = new File([compressedBlob], file.name, {
          type: file.type,
          lastModified: new Date().getTime(),
        });

        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          setImage(e.target?.result as string);
          setSelectedFile(compressedFile);
        };
        fileReader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error(error);
        alert("An error occurred while compressing the image.");
      }
    }
  };

  const handleRemoveImageFace = () => {
    setImage(undefined);
    setSelectedFile(null);
  };

  const handleSubmitFace = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!selectedFile) {
      alert("Please select an image.");
      return;
    }

    try {
      const response = await uploadFaceImage(selectedFile, "uid", id); // Note: Adjust the upload function if needed
      console.log(response.data);
      await fetchFaceImages();
      setImage("");
      alert("Image and metadata uploaded successfully.");
    } catch (error) {
      console.error(error);
      alert("Error uploading image.");
    }
  };

  return (
    <FaceSwapContainer>
      {(selectedFaceURL || images.targetImages) && (
        <PreviewContainer>
          {images.targetImages.map((image, index) => (
            <ImageContainer key={index}>
              <ImagePreview src={image} alt={`Target Image Preview ${index}`} />
              <RemoveButton onClick={() => handleRemoveImage(index)}>
                X
              </RemoveButton>
            </ImageContainer>
          ))}
          {selectedFaceURL && (
            <ImagePreview src={selectedFaceURL || ""} alt="Selected Face" />
          )}
        </PreviewContainer>
      )}
      <FaceSwapBox>
        <Title>Let's Swap. (69 B)</Title>
        <Subtitle>
          Choose a target photo and a face photo, then witness the
          transformation.
        </Subtitle>
        <StyledLabel>
          Upload Target Images (up to 20)
          <input
            type="file"
            onChange={(e) => handleImageChange(e, "target")}
            accept="image/*"
            multiple
          />
        </StyledLabel>
        <StyledLabel onClick={() => setIsModalOpen(true)}>
          Select a Face
        </StyledLabel>
        {uiState.faceSwapProcessing && isUpload ? (
          <>
            <MutatingDots
              height="100"
              width="100"
              color="#000"
              secondaryColor="#000"
              radius="12.5"
              ariaLabel="mutating-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
            <LinearProgress percent={progress} />
          </>
        ) : (
          <ButtonVisagifyDark
            onClick={handleSubmit}
            disabled={!images.targetImages || !selectedFaceURL}
          >
            Swap Faces
          </ButtonVisagifyDark>
        )}
      </FaceSwapBox>
      <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)}>
        {/* <ButtonVisagifyDark onClick={toggleFilters}>
          {showFilters ? "Hide Filters" : "Show Filters"}
        </ButtonVisagifyDark> */}
        <CloseButton onClick={() => setIsModalOpen(false)}>x</CloseButton>

        <GallerySection>
          <FlexCol>
            {image && (
              <PreviewContainer>
                <ImageContainer>
                  <ImagePreview src={image} alt={`Image Preview`} />
                  <RemoveButton onClick={() => handleRemoveImageFace()}>
                    X
                  </RemoveButton>
                </ImageContainer>
              </PreviewContainer>
            )}
            <Form style={{ width: "auto" }} onSubmit={handleSubmitFace}>
              <StyledLabel>
                Upload Images
                <input
                  type="file"
                  onChange={(e) => handleImageChangeFace(e)}
                  accept="image/*"
                  multiple
                />
              </StyledLabel>

              <Input
                placeholder="id"
                value={id}
                onChange={(e) => setId(e.target.value)}
              />

              <ButtonVisagify type="submit">Upload Image</ButtonVisagify>
            </Form>
          </FlexCol>

          {faceImages.map((image, index) => (
            <ImageTile
              key={index}
              src={image.url}
              isSelected={selectedFaceURL === image.url}
              onClick={() => handleFaceSelect(image.url, image.id)}
            />
          ))}
        </GallerySection>
        <ButtonVisagify onClick={confirmFaceSelection}>Confirm</ButtonVisagify>
      </Modal>
    </FaceSwapContainer>
  );
};

export default FaceSwapBulk69V2;
