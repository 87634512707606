import { createSlice } from "@reduxjs/toolkit";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase/firebase";
import { readUserPersonalData } from "../../utils/api/readUserData";

const authInitialState = {
  createdAt: 0,
  ip: "",
  uid: "",
  customerId: "",
  token: "",
  email: "",
  emailVerified: false,
  isAnonymous: false,
  pic: "",
  nom: "",
  prenom: "",
  gender: "",
  birthday: "",
  dreams: [],
  subsription: "",
  fetch: false,
  error: "",
};

export const AuthReducer = createSlice({
  name: "Auth",
  initialState: authInitialState,
  reducers: {
    loadAuthFetch: (state) => {
      state.fetch = true;
      state.error = "";
    },
    loadAuthData: (state, action) => {
      state.fetch = false;
      state.error = "";
      state.email = action.payload.email;
      state.nom = action.payload.nom;
      state.prenom = action.payload.prenom;
      state.pic = action.payload.pic;
      state.gender = action.payload.gender;
      state.birthday = action.payload.birthday;
      state.ip = action.payload.ip;
      state.createdAt = action.payload.createdAt;
      state.emailVerified = action.payload.emailVerified;
      state.dreams = action.payload.dreams ? action.payload.dreams : [];
      state.subsription = action.payload.subscription;
      state.customerId = action.payload.customerId;
    },
    loadAuthToken: (state, action) => {
      state.fetch = false;
      state.error = "";
      state.token = action.payload;
    },
    loadAuthUid: (state, action) => {
      state.fetch = false;
      state.error = "";
      state.uid = action.payload;
    },
    loadAuthError: (state, action) => {
      state.fetch = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadAuthFetch,
  loadAuthData,
  loadAuthToken,
  loadAuthUid,
  loadAuthError,
} = AuthReducer.actions;

export const loadAuth = () => {
  return async (dispatch: any) => {
    dispatch(loadAuthFetch());
    try {
      onAuthStateChanged(auth, (userCred) => {
        userCred?.getIdToken().then(async (tok) => {
          window.localStorage.setItem("auth-askmorpheus", "true");
          window.localStorage.setItem("uid-askmorpheus", userCred.uid);
          dispatch(loadAuthToken(tok));
          dispatch(loadAuthUid(userCred.uid));
          await dispatch(loadAuthUserData(userCred.uid));
          // dispatch(loadAuthData(userCred));
        });
      });
    } catch (error) {
      dispatch(loadAuthError(error));
    }
  };
};

export const loadAuthUserData = (uid: string | null) => {
  return async (dispatch: any) => {
    dispatch(loadAuthFetch());
    if (uid) {
      try {
        await readUserPersonalData(uid).then((res) => {
          dispatch(loadAuthData(res));
        });
      } catch (error) {
        console.log(error);
      }
    }
  };
};

export const loadAuthEmpty = () => {
  return async (dispatch: any) => {
    dispatch(loadAuthData(authInitialState));
    dispatch(loadAuthUid(""));
    dispatch(loadAuthToken(""));
  };
};
