import axios from "axios";

/**
 * Uploads the provided files to the specified endpoint.
 * @param {string[]} selectedImages The array of selected image url.
 * @param {string} faceId The face id.
 * @returns Promise resolved with the API response or rejected with an error.
 */
const writeImagesFaceId = async (selectedImages: string[], faceId: string) => {
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  // const baseUrl = process.env.REACT_APP_API_BASEURL_LOCAL;
  const url = `${baseUrl}/v2/69/setFaceId`;

  const response = await axios.post(url, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    selectedImages: selectedImages,
    faceId: faceId,
  });

  return response;
};

export default writeImagesFaceId;
