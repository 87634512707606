import React, { useState } from "react";
import VisualGalleryPageAdmin from "../../components/VisualGalleryAdmin/VisualGalleryAdmin";
import FaceSwapAdmin from "../../components/FaceSwapAdmin/FaceSwapAdmin";
import { Input } from "../../components/UI/Forms/Forms.styled";

function AdminSwaps() {
  const [password, setPassword] = useState("");

  if (password === "bonbonbon") {
    return (
      <div>
        <FaceSwapAdmin />
        <VisualGalleryPageAdmin />
      </div>
    );
  } else {
    return (
      <div>
        <Input
          placeholder="First Name"
          type="text"
          required
          onChange={(event) => setPassword(event.target.value)}
        />
      </div>
    );
  }
}

export default AdminSwaps;
