import styled from "styled-components";
import { palette } from "../../assets/style/colors";

export const PageTitleBox = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const PageTitle = styled.h1`
  color: ${palette.primary.headline};
  font-size: 1.5rem;
  margin: 0.5rem 0;
  font-family: "DM Serif Text", "Roboto";
  /* background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(
    45deg,
    ${palette.primary.paragraphLight},
    ${palette.primary.paragraph}
  );
  will-change: background, opacity, filter; */
  // background-size: 100%;
  // animation: _mp-title-color 16s linear infinite;

  @keyframes _mp-title-color {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
`;

interface ITextProps {
  textAlign?: string;
}

export const PageDescription = styled.p`
  color: ${palette.primary.paragraph};
  text-align: ${(props: ITextProps) => props.textAlign || "inherit"};
`;

export const PageSubTitle = styled.h2`
  margin: 0.5rem 0;
  color: rgb(255 255 255 / 60%);
  font-size: 1.7rem;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(135deg, rgb(184, 84, 84), rgb(101, 65, 65));
`;

export const PageSubTitleH3 = styled.h3`
  margin: 0.5rem 0;
  color: rgb(255 255 255 / 60%);
  font-size: 1.4rem;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(45deg, #c59769, rgb(206, 159, 111));
`;
