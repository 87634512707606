import axios from "axios";

const readSwaps = async (uid: string) => {
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  const url = `${baseUrl}/swaps/${uid}`;
  const response = await axios.get(url);
  return response.data;
};

export default readSwaps;
