import styled from "styled-components";
import { palette } from "../../../assets/style/colors";

export const FormBox = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Form = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;

  @media screen and (max-width: 680px) {
    width: 100%;
  }
`;

export const Input = styled.input`
  width: 90%;
  min-width: 0px;
  margin-bottom: 1rem;
  padding-inline-start: 1rem;
  outline: 2px solid transparent;
  outline-offset: 2px;
  position: relative;
  appearance: none;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-duration: 200ms;
  font-size: 1rem;
  border: 1px solid;
  border-color: #bbb;
  height: 40px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
`;

export const RadioInputBox = styled.div`
  display: flex;
`;

export const InlineInputBox = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, minmax(0px, 1fr));
`;

export const InputNeumorphic = styled.input`
  font-size: 1.2rem;
  margin: 10px 0px;
  padding: 10px;
  border-radius: 0.5rem;
  background-color: rgb(255 255 255/30%);
  border: none;
  color: #fff;
  box-shadow: 3px 3px 8px 0 rgb(0 0 0), -3px -3px 8px 0 rgb(255 255 255 / 15%);
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease-in;

  &::placeholder {
    color: rgb(255 255 255 / 65%);
    font-size: 1rem;
  }

  &::before {
    content: "\f111";
    color: rgb(255 255 255/10%);
    font-size: inherit;
  }

  &:hover {
    // background-color: rgb(255 255 255/10%);
    box-shadow: inset 2px 2px 5px 0 rgb(0 0 0 / 15%),
      inset -2px -2px 5px 0 rgb(255 255 255 / 15%);
  }

  &:focus {
    // background-color: rgb(255 255 255/10%);
    box-shadow: inset 2px 2px 5px 0 rgb(0 0 0 / 15%),
      inset -2px -2px 5px 0 rgb(255 255 255 / 15%);
  }

  &:not(:placeholder-shown) {
    // background-color: rgb(255 255 255/10%);
    box-shadow: inset 2px 2px 5px 0 rgb(0 0 0 / 15%),
      inset -2px -2px 5px 0 rgb(255 255 255 / 15%);
  }
`;

export const Label = styled.label`
  display: flex;
  font-family: "lato", sans-serif;
  font-weight: 600;
`;

export const CheckboxNeumorphic = styled.input`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  width: 1.5em;
  height: 1.5em;
  appearance: none;
  cursor: pointer;
  background-color: rgb(255 255 255/30%);
  box-shadow: 2px 2px 5px 0 rgb(0 0 0 / 50%),
    -2px -2px 5px 0 rgb(255 255 255 / 15%);
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s;

  &::after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 1rem;
    content: "\f00c";
    color: white;
    display: none;
  }

  /* add styles to the checkmark */
  &::before {
  }

  &:hover {
    background-color: rgb(255 255 255/20%);
    box-shadow: inset 2px 2px 5px 0 rgb(0 0 0 / 50%),
      inset -2px -2px 5px 0 rgb(255 255 255 / 15%);
    &::before {
    }
  }

  &:checked {
    background-color: rgb(255 255 255/20%);
    /* background: linear-gradient(
      -220deg,
      rgba(249, 231, 187, 0.9),
      rgba(233, 124, 187, 0.9),
      rgba(61, 71, 217, 0.9)
    ); */
    box-shadow: inset 2px 2px 5px 0 rgb(0 0 0 / 50%),
      inset -2px -2px 5px 0 rgb(255 255 255 / 15%);
    &::before {
    }
    &::after {
      display: block;
    }
  }

  &:focus {
    outline: none;
    &::after {
    }
  }

  &:active {
    &::before {
    }
  }
`;

export const SelectBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

export const SelectLab = styled.select`
  color: ${palette.primary.headline};
  /* Reset */
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
  /* Personalize */
  margin-left: 1rem;
  // width: 20em;
  height: 2em;
  padding: 0 4em 0 1em;
  background: url("https://upload.wikimedia.org/wikipedia/commons/9/9d/Caret_down_font_awesome_whitevariation.svg")
      no-repeat right 0.8em center / 1.4rem,
    linear-gradient(to left, rgb(0 0 0/20%) 3em, rgb(255 255 255/90%) 3em);
  border-radius: 0.25em;
  box-shadow: 3px 3px 8px 0 rgb(0 0 0 / 15%),
    -3px -3px 8px 0 rgb(255 255 255 / 15%);

  cursor: pointer;
  /* <option> colors */
  option {
    color: inherit;
  }

  &::after {
    font-size: 1rem;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f0d7";
    position: absolute;
    color: ${palette.primary.headline};
    padding: 0 15px;
    background-color: #000;
    cursor: pointer;
    transition: all 0.3s;
  }
  /* Remove focus outline */
  &:focus {
    outline: none;
  }
  /* Remove IE arrow */
  &::-ms-expand {
    display: none;
  }
  /* appearance: none;
  width: 80%;
  outline: 0;
  border: 0;
  padding: 0 15px;
  cursor: pointer;

  &::-ms-expand {
    display: none;
  } */
`;

// create a styled component for the option elements
export const StyledOption = styled.option`
  /* add styles for the option elements */
  background-color: #f4f4f4;
  padding: 0.5em;
  transition: background-color 0.3s;

  &:hover {
    background-color: #eee;
  }

  &:active {
    background-color: #eaeaea;
  }
`;

export const InputKeywordsBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: 621px) {
    flex-direction: column;
  }
`;

export const Textarea = styled.textarea`
  padding: 10px;
  margin: 5px 0;
  height: 20vh;
  min-width: 40vw;
  border-radius: 0.5rem;
  background-color: rgb(255 255 255/20%);
  border: none;
  box-shadow: 2px 2px 5px 0 rgb(0 0 0 / 15%),
    -2px -2px 5px 0 rgb(255 255 255 / 50%);
  outline: none;
  color: ${palette.primary.headline};
  transition: all 0.3s;
  font-size: 17px !important;

  &:hover {
    background-color: rgb(255 255 255/10%);
    box-shadow: inset 2px 2px 5px 0 rgb(0 0 0 / 50%),
      inset -2px -2px 5px 0 rgb(255 255 255 / 15%);
  }

  &:focus {
    background-color: rgb(255 255 255/10%);
    box-shadow: inset 2px 2px 5px 0 rgb(0 0 0 / 50%),
      inset -2px -2px 5px 0 rgb(255 255 255 / 15%);
  }

  &:not(:placeholder-shown) {
    background-color: rgb(255 255 255/10%);
    box-shadow: inset 2px 2px 5px 0 rgb(0 0 0 / 50%),
      inset -2px -2px 5px 0 rgb(255 255 255 / 15%);
  }

  @media screen and (max-width: 621px) {
    min-width: 70vw;
  }
`;
