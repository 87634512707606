import React, { useState } from "react";
import VisualGalleryPageAdmin from "../../components/VisualGalleryAdmin/VisualGalleryAdmin";
import FaceSwapBulk69 from "../../components/FaceSwapBulk69/FaceSwapBulk69";
import { Input } from "../../components/UI/Forms/Forms.styled";

function AppBulk() {
  const [password, setPassword] = useState("");

  if (password === "bonbonbon") {
    return (
      <div>
        <FaceSwapBulk69 />
        <VisualGalleryPageAdmin />
      </div>
    );
  } else {
    return (
      <div>
        <Input
          placeholder="First Name"
          type="text"
          required
          onChange={(event) => setPassword(event.target.value)}
        />
      </div>
    );
  }
}

export default AppBulk;
