import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import BaseLayout from "./components/BaseLayout/BaseLayout";
import { useAppDispatch } from "./hooks/reduxHooks";
import { setLanguage } from "./store/applications/UIReducer";
import Landing from "./pages/Landing/Landing";
import Login from "./pages/Login/Login";
import Application from "./pages/App/App";
import Terms from "./pages/Terms/Terms";
import Privacy from "./pages/Privacy/Privacy";
import AdminSwaps from "./pages/AdminSwaps/AdminSwaps";
import ProShoot from "./pages/ProShoot/ProShoot";
import AppBulk from "./pages/AppBulk/AppBulk";
import AddProshoot from "./pages/Admin/AddProshoot/AddProshoot";
import { ThemeProvider } from "./context/ThemeContext";
import ProSoccer from "./pages/ProSoccer/ProSoccer";
import AddProSoccer from "./pages/Admin/AddSoccer/AddProSoccer";
import AppBulkV2 from "./pages/AppBulk/AppBulk2";
import ManageFaces from "./pages/ManageFaces/ManageFaces";

function App() {
  const dispatch = useAppDispatch();
  // const UI = useAppSelector((state) => state.UIReducer);
  useEffect(() => {
    const userLang = navigator.language.split("-")[0];
    dispatch(setLanguage(userLang));
  }, [dispatch]);

  return (
    <BrowserRouter>
      <ThemeProvider>
        <BaseLayout>
          <Routes>
            {/* <Route path="/pr/test" element={<TestPage />} />
          <Route path="/v2" element={<InterpretV2 />} />
          <Route path="/v2/audio" element={<AudioRecorder />} />
          <Route path="/v2/text" element={<TextUploader />} />
          <Route path="/v2/admin" element={<InterpreterV2admin />} />
          <Route path="/v2/c/:chatIdParam" element={<InterpretV2 />} /> */}
            {/* <Route path="/" element={<SelectLanguage />} /> */}
            {/* <Route
            path="/"
            element={UI.language === "fr" ? <HomepageFR /> : <Homepage />}
          /> */}
            <Route element={<Landing />} path="/" />
            <Route path="/login" element={<Login />} />
            <Route path="/app" element={<Application />} />
            <Route path="/app/bulk" element={<AppBulk />} />
            <Route path="/app/bulk2" element={<AppBulkV2 />} />
            <Route path="/app/manage-faces" element={<ManageFaces />} />
            <Route path="/app/proshoot" element={<ProShoot />} />
            <Route path="/app/prosoccer" element={<ProSoccer />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/admin/swaps" element={<AdminSwaps />} />
            <Route path="/admin/proshoot" element={<AddProshoot />} />
            <Route path="/admin/prosoccer" element={<AddProSoccer />} />
            {/* <Route path="/en" element={<Homepage />} />
          <Route path="/fr" element={<HomepageFR />} />
          <Route path="/login" element={<Login />} />
          <Route path="/fr/login" element={<LoginFR />} />
          <Route path="/dreams" element={<DreamsList />} />
          <Route path="/admin/dashboard" element={<DashboardAdmin />} /> */}
          </Routes>
        </BaseLayout>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
