import { createSlice } from "@reduxjs/toolkit";

const initialStateUi = {
  chainId: "",
};

export const web3Reducer = createSlice({
  name: "UIReducer",
  initialState: initialStateUi,
  reducers: {
    setChainIdWeb3: (state, action) => {
      state.chainId = action.payload;
    },
  },
});

export const { setChainIdWeb3 } = web3Reducer.actions;
