import axios from "axios";
// import { generateRandomString } from "..";

// const token = process.env.REACT_APP_API_SECRET_KEY;

/**
 * Uploads the provided files to the specified endpoint.
 * @param {File} targetFile The target image file.
 * @param {File} sourceFile The source image file.
 * @returns Promise resolved with the API response or rejected with an error.
 */
const writeFileUploadAdmin = async (targetFile: File, sourceFile: File) => {
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  const url = `${baseUrl}/admin/upload`;
  // const token = generateRandomString("test", 64);

  const formData = new FormData();
  formData.append("target", targetFile);
  formData.append("source", sourceFile);

  const response = await axios.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response;
};

export default writeFileUploadAdmin;
