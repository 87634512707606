import styled from "styled-components";

export interface IHeaderBackground {
  img: string;
}

export const HeaderBox = styled.div`
  position: relative;
  opacity: 1;
  color: white;
  box-shadow: rgb(0 0 0 / 11%) 0rem 0.5rem 0.4375rem -0.0625rem,
    rgb(0 0 0 / 7%) 0rem 0.25rem 0.25rem -0.0625rem;

  display: flex;
  align-items: center;
  opacity: 1;
  backdrop-filter: saturate(200%) blur(1.875rem);
  /* background: ${(props: IHeaderBackground) =>
    `linear-gradient(135deg, #231c07 0%,  #392a16 100%) 50% center / cover, url(${props.img}) transparent;`}; */
  border-radius: 1rem;
  background-position: 50% center;
  overflow: hidden;
  min-height: 5rem;

  @media screen and (min-width: 879px) {
    min-height: 5rem;
  }
`;

export const HeaderEl = styled.header`
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
  position: absolute;
  z-index: 1100;
  left: auto;
  right: 0px;
  box-shadow: none;
  backdrop-filter: none;
  color: rgb(255, 255, 255);
  top: 0px;
  min-height: 4.6875rem;
  display: grid;
  align-items: center;
  border-radius: 1rem;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  background-color: transparent !important;
`;

export const HeaderToolBar = styled.div`
  position: relative;
  display: flex;
  padding: 4px 16px 0px 0px;
  min-height: 56px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 879px) {
    flex-direction: row;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  @media screen and (min-width: 576px) {
    min-height: auto;
    padding: 0.25rem 1rem;
  }

  @media screen and (min-width: 0px) {
    min-height: 48px;
  }

  & * {
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
`;

export const HeaderNavigationBox = styled.div`
  opacity: 1;
  background: transparent;
  color: inherit;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 100%;

  @media screen and (min-width: 879px) {
    justify-content: stretch;
    width: max-content;
    margin-bottom: 0px;
    //display: none;
  }

  @media screen and (min-width: 0px) {
    margin-bottom: 8px;
    justify-content: space-between;
    width: 100%;
  }
`;

export const HeaderAccountBox = styled.div`
  opacity: 1;
  background: transparent;
  color: rgb(255, 255, 255);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 100%;

  @media screen and (min-width: 768px) {
    justify-content: stretch;
    width: max-content;
  }

  @media screen and (max-width: 568px) {
    flex-direction: column;
  }
`;

export const HeaderDrawerToggler = styled.div`
  padding-right: 40px;
  opacity: 1;
  background: transparent;
  color: white;
  display: block;
  cursor: pointer;

  @media screen and (min-width: 992px) {
    display: block !important;
  }

  @media screen and (max-width: 992px) {
    display: none;
  }
`;

export const HeaderDrawerTogglerTablet = styled.div`
  opacity: 1;
  background: transparent;
  color: white;
  display: block;
  cursor: pointer;

  @media screen and (max-width: 992px) {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
  }

  @media screen and (min-width: 992px) {
    display: none;
  }

  @media screen and (max-width: 879px) {
    display: none !important;
  }
`;

export const HeaderDrawerTogglerMobile = styled.div`
  padding-left: 40px;
  opacity: 1;
  background: transparent;
  color: white;
  display: block;
  cursor: pointer;

  @media screen and (min-width: 879px) {
    //display: none !important;
  }

  @media screen and (min-width: 0px) {
    display: block;
  }
`;

export const MenuBox = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  @media screen and (max-width: 879px) {
    display: none;
  }
`;

export const MenuLink = styled.a`
  display: flex;
  margin: 0px 10px;
`;
