import React from "react";
import curved from "../../assets/images/curved-images/curved0.jpg";
import {
  HeaderBox,
  HeaderDrawerTogglerMobile,
  HeaderEl,
  HeaderNavigationBox,
  HeaderToolBar,
} from "./Header.styled";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import {
  openChatNavMobile,
  openDrawerMobile,
} from "../../store/applications/UIReducer";
import styled from "styled-components";
import logo from "../../assets/images/visagify-logo.png";
import { PageTitleBox } from "../Paragraph/Paragraph.styled";
import { FlexRow } from "../../pages/InterpretV2/InterpretV2";
import { useLocation } from "react-router-dom";
import PageTitle from "../Paragraph/PageTitle";
import { useTheme } from "../../context/ThemeContext";

export const LevelBox = styled.div`
  display: flex;
  align-items: center;
`;

export const XP = styled.div`
  display: flex;
  padding: 5px 10px;
  margin: 5px;
  background-color: rgba(243, 130, 28, 0.5);
  border-radius: 10px;
  box-shadow: 0px -2px 1px rgb(243 130 28 / 80%);
  cursor: pointer;

  &:hover {
    transform: scale(1.03);
  }
`;

export const LevelText = styled.p`
  margin: 2px 0;
  display: flex;
  align-items: center;
`;

export const LogoImg = styled.img`
  width: 70px;
  border-radius: 1rem;
  background-color: white;

  @media screen and (max-width: 462px) {
    width: 60px;
  }
`;

export interface IPlayerLevel {
  lvl: number;
  xp: number;
}

function Header() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const drawerState = useAppSelector((state) => state.UIReducer);
  const uiState = useAppSelector((state) => state.UIReducer);
  const theme = useTheme();
  return (
    <HeaderBox img={curved}>
      <HeaderEl>
        <HeaderToolBar>
          <HeaderNavigationBox>
            <FlexRow style={{ alignItems: "center", gap: "0.5rem" }}>
              {location.pathname.includes("v2") && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    dispatch(openChatNavMobile(!uiState.chatNavMobile))
                  }
                >
                  <span
                    style={{ color: theme.paragraph }}
                    className="material-icons-outlined"
                  >
                    {uiState.chatNavMobile ? "view_sidebar" : "view_sidebar"}
                  </span>
                </div>
              )}
              <a style={{ textDecoration: "none" }} href="/">
                <LogoImg src={logo} alt="Visagify logo" />
              </a>
            </FlexRow>
            {/* <HeaderDrawerToggler
              onClick={() =>
                dispatch(openDrawerDesktop(!drawerState.drawerDesktop))
              }
            >
              <span
                style={{ color: "white" }}
                className="material-icons-outlined"
              >
                {drawerState.drawerDesktop ? "menu_open" : "menu"}
              </span>
            </HeaderDrawerToggler>
            <HeaderDrawerTogglerTablet
              onClick={() =>
                dispatch(openDrawerMobile(!drawerState.drawerMobile))
              }
            >
              <span
                style={{ color: "white" }}
                className="material-icons-outlined"
              >
                {drawerState.drawerMobile ? "menu_open" : "menu"}
              </span>
            </HeaderDrawerTogglerTablet> */}
            {/* <Breadcrumb /> */}
            <PageTitleBox>
              <PageTitle>Visagify</PageTitle>
            </PageTitleBox>
            <HeaderDrawerTogglerMobile
              onClick={() =>
                dispatch(openDrawerMobile(!drawerState.drawerMobile))
              }
            >
              <span
                style={{ color: theme.paragraph }}
                className="material-icons-outlined"
              >
                {drawerState.drawerMobile ? "menu_open" : "menu"}
              </span>
            </HeaderDrawerTogglerMobile>
          </HeaderNavigationBox>
          {/* <MenuBox>
            <LogoImg src={logo} alt="ask morpheus logo" />
            {/* <MenuLink href="/">
              <ButtonPR>
                <span style={{ color: "white" }} className="material-icons">
                  videogame_asset
                </span>
              </ButtonPR>
            </MenuLink> */}
          {/* </MenuBox> */}
        </HeaderToolBar>
      </HeaderEl>
    </HeaderBox>
  );
}

export default Header;
