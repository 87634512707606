import styled from "styled-components";

export const InterpretationBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
  margin: 5px 0;
`;

export const Interpretation = styled.div`
  padding: 1rem;
  border-radius: 1rem;
  width: 40vw;
  background-color: rgb(255 255 255/10%);
  border: none;
  box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 5px 0px,
    rgba(255, 255, 255, 0.5) -2px -2px 5px 0px;

  @media screen and (max-width: 621px) {
    min-width: 70vw;
  }
`;
