import React, { useState } from "react";
import { Input } from "../../components/UI/Forms/Forms.styled";
import FaceSwapBulk69V2 from "../../components/FaceSwapBulk69/FaceSwapBulk69V2";
import VisualGalleryPageAdminV2 from "../../components/VisualGalleryAdmin/VisualGalleryAdminV2";

function AppBulkV2() {
  const [password, setPassword] = useState("");

  if (password === "bonbonbon") {
    return (
      <div>
        <FaceSwapBulk69V2 />
        <VisualGalleryPageAdminV2 />
      </div>
    );
  } else {
    return (
      <div>
        <Input
          placeholder="First Name"
          type="text"
          required
          onChange={(event) => setPassword(event.target.value)}
        />
      </div>
    );
  }
}

export default AppBulkV2;
