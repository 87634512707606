import styled from "styled-components";

export const EmptyProgressBar = styled.div`
  background-color: rgb(255 255 255/20%);
  border-radius: 100px;
  height: 13px;
  overflow: hidden;
  position: relative;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.5) 2px 2px 5px 0px inset,
    rgba(255, 255, 255, 0.15) -2px -2px 5px 0px inset;
`;

export const FillingProgressBar = styled.div`
  // background-image: linear-gradient(to right, #666fcc, #9c77d3);
  background-image: linear-gradient(to right, #000, #222, #333, #222, #000);

  background-size: 200%;
  animation: _mp-title-color 4s linear infinite;

  @keyframes _mp-title-color {
    0% {
      background-position: 0% 10%;
    }
    50% {
      background-position: 100% 200%;
    }
    100% {
      background-position: 200% 200%;
    }
  }
  border-radius: 100px;
  height: 100%;
  width: 100%;
  position: absolute;
  left: ${(props: { percent: number }) => `${props.percent - 100}%`};
  transition: all 3s ease-in;
`;

export const FillingProgressBarWhite = styled.div`
  // background-image: linear-gradient(to right, #666fcc, #9c77d3);
  background-image: linear-gradient(to right, #fff, #ddd, #ccc, #ddd, #fff);

  background-size: 200%;
  animation: _mp-title-color 4s linear infinite;

  @keyframes _mp-title-color {
    0% {
      background-position: 0% 10%;
    }
    50% {
      background-position: 100% 200%;
    }
    100% {
      background-position: 200% 200%;
    }
  }
  border-radius: 100px;
  height: 100%;
  width: 100%;
  position: absolute;
  left: ${(props: { percent: number }) => `${props.percent - 100}%`};
  transition: all 3s ease-in;
`;
