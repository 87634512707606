import React from "react";
import Hero, { Subtitle, Title } from "../../components/Hero/Hero";
import styled from "styled-components";
import gifOriginal from "../../assets/images/think-meme.gif";
import source from "../../assets/images/ai-man-1.jpg";
import gifSwapped from "../../assets/images/swapped-meme-ai.gif";

export const SwapDemoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
export const SwapDemo = styled.div`
  /* width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem; */

  display: flex;
  flex-direction: row;
  justify-content: center; // Adjust as needed (space-between, space-around, etc.)
  align-items: center;
  width: 100%; // Take the full width of the screen
  overflow-x: hidden; // Enable scrolling on small screens
  gap: 0.2rem;

  @media screen and (max-width: 687px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const SwapImage = styled.img`
  /* max-height: 335px;

   */
  flex: 1; // Each image will take an equal amount of space in the container
  max-width: 33.33%; // Each image can take up to a third of the flex container's width
  height: auto; // Maintain aspect ratio
  object-fit: cover; // This will cover the area without stretching the image

  @media screen and (max-width: 687px) {
    max-width: 100%;
  }
`;

function Landing() {
  return (
    <div>
      <Hero />
      <SwapDemoBox>
        <Title>Swap, Smile, Share.</Title>
        <Subtitle>
          See how Visagify turns the familiar into something fresh and fun.
        </Subtitle>
        <SwapDemo>
          <SwapImage src={gifOriginal} />
          <Title>+</Title>
          <SwapImage src={source} />
          <Title>=</Title>
          <SwapImage src={gifSwapped} />
        </SwapDemo>
      </SwapDemoBox>
    </div>
  );
}

export default Landing;
