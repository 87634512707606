import React, { useState } from "react";
import styled from "styled-components";
import Divider from "../UI/Divider/Divider";

export const VersionBox = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 0.7rem;
  border: 1px solid rgba(255, 255, 255, 0.4);
  padding: 0.2rem;
  margin: 0.5rem 1rem;
  width: 50vw;

  @media screen and (max-width: 682px) {
    width: 85vw;
  }
`;

export const HoverInfoDiv = styled.div`
  display: ${(props: { isVisible: boolean }) =>
    props.isVisible ? "block" : "none"};
  position: fixed;
  top: 20%; // Adjust as needed
  left: 62%;
  transform: translateX(-50%);
  background-color: #1d1f2d;
  color: #fff;
  padding: 1rem;
  border-radius: 8px;
  width: 20vw; // Adjust as needed
  z-index: 1000; // To make sure it's above other elements
  transition: all ease-in-out 150ms;

  // Splitting div into head, body, and footer:
  & > .head,
  & > .body,
  & > .footer {
    padding: 0.5rem;
  }
  & > .footer {
    border-bottom: none;
  }

  @media screen and (max-width: 682px) {
    width: 40vw;
    top: 25%;
  }
`;

export const VersionNotSelectedBox = styled.a`
  text-decoration: none;
  border: none;
  padding: 0.5rem;
  width: 50%;
  text-align: center;
  cursor: pointer;
  transition: all ease-in-out 150ms;

  &:hover {
    border-radius: 0.5rem;
    background-color: rgba(255, 255, 255, 0.1);
  }

  &:hover + ${HoverInfoDiv} {
    display: block; // Display the div on hover
  }
`;

export const VersionSelectedBox = styled.a`
  text-decoration: none;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 0.5rem;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0.5rem;
  width: 50%;
  text-align: center;
  cursor: pointer;
`;

function Versions(props: { whichVersion: number }) {
  const [isHovered, setIsHovered] = useState(false);
  if (props.whichVersion === 1) {
    return (
      <>
        <VersionBox>
          <VersionSelectedBox href="/">AM-1</VersionSelectedBox>

          <VersionNotSelectedBox
            href="/v2"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            AM-2
          </VersionNotSelectedBox>
        </VersionBox>
        <HoverInfoDiv isVisible={isHovered}>
          <div className="head">
            La V2 offre une approche similaire à un entretien analytique,
            produisant des interprétations époustouflantes de vos rêves.
          </div>
          <Divider />
        </HoverInfoDiv>
      </>
    );
  } else {
    return (
      <>
        <VersionBox>
          <VersionNotSelectedBox href="/">AM-1</VersionNotSelectedBox>

          <VersionSelectedBox
            href="/v2"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            AM-2
          </VersionSelectedBox>
        </VersionBox>
        <HoverInfoDiv isVisible={isHovered}>
          <div className="head">
            La V2 offre une approche similaire à un entretien analytique,
            produisant des interprétations époustouflantes de vos rêves.
          </div>
          <Divider />
        </HoverInfoDiv>
      </>
    );
  }
}

export default Versions;
