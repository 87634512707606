import { ReactNode } from "react";
import styled from "styled-components";
import { ICollapses } from "./DrawerInterface";
import { palette } from "../../assets/style/colors";

export interface IDrawer {
  drawerDesktop: boolean;
  drawerMobile: boolean;
  routes: {
    type: string;
    key: string;
    name?: string;
    href?: string;
    icon?: ReactNode;
    noCollapse?: boolean;
    title?: string;
    route?: string;
    collapse?: ICollapses[];
  }[];
}

export const DrawerArea = styled.div`
  flex: 0 0 auto;
  width: 15.625rem;
  white-space: nowrap;
  border: none;
`;

export const DrawerBox = styled.div`
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  z-index: 1200;
  position: fixed;
  top: 0px;
  outline: 0px;
  left: 0px;
  width: 15.625rem;

  // background-color: #03081c;
  border: solid 1px ${palette.primary.background};
  background-color: ${palette.primary.background};
  box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 5px 8px,
    rgba(255, 255, 255, 0.5) -2px -2px 8px 0px;

  // backdrop-filter: saturate(200%) blur(1.875rem);
  /* Firefox */
  @-moz-document url-prefix() {
    backdrop-filter: none;
  }

  /* Internet Explorer + Edge */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    backdrop-filter: none;
  }
  height: calc(100vh - 2rem);
  margin: 1rem;
  border-radius: 1rem;
  // border: none;

  // box-shadow: rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem 0rem;
  transform: ${(props: IDrawer) =>
    props.drawerMobile ? "translateX(0px)" : "translateX(-20rem)"};
  transition: transform 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

  @media screen and (min-width: 992px) {
    // background-color: transparent;
    margin-bottom: 0px;
    left: 0px;
    width: 250px;
    transform: ${(props: { drawerDesktop: boolean }) =>
      props.drawerDesktop ? "translateX(0px)" : "translateX(-20rem)"};
    transition: transform 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  }
`;

export const DrawerLogoBox = styled.div`
  padding: 24px 32px 8px;
  text-align: center;
  opacity: 1;
  background: transparent;
  color: white;
`;

export const DrawerHighlightBox = styled.div`
  padding-top: 16px;
  margin: 16px;
  opacity: 1;
  background: transparent;
  color: white;
`;

export const DrawerLogoLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  background: transparent;
  color: white;
`;

export const DrawerLogoImg = styled.img`
  width: 100px;
`;

export const DrawerListTitle = styled.span`
  margin: 16px 0px 8px 8px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 0.75rem;
  line-height: 1.25;
  letter-spacing: 0.03333em;
  display: block;
  padding-left: 24px;
  opacity: 0.6;
  text-transform: uppercase;
  text-decoration: none;
  color: ${palette.primary.headline};
  font-weight: 700;
`;
