import axios from "axios";

/**
 * Uploads the provided image files along with their metadata.
 * @param {File} imageFile The array of image files to upload.
 * @param {string} id The metadata associated with the images.
 * @returns Promise resolved with the API response or rejected with an error.
 */
const uploadFaceImage = async (imageFile: File, uid: string, id: string) => {
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  // const baseUrl = process.env.REACT_APP_API_BASEURL_LOCAL;
  const url = `${baseUrl}/user/face/upload`;

  const formData = new FormData();
  formData.append("image", imageFile, `${imageFile.name}`);
  formData.append("uid", uid);
  formData.append("id", id);

  const response = await axios.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response;
};

export default uploadFaceImage;
