import React from "react";
import { LegalBox } from "../Terms/Terms";

function Privacy() {
  return (
    <LegalBox>
      <h1>Privacy Policy</h1>
      <h2>1. Introduction</h2>
      <p>
        <b>1.1 Overview.</b> Welcome to Visagify. This Privacy Policy is
        designed to inform you about our practices regarding the collection,
        use, and disclosure of personal information that you may provide through
        using our services. At Visagify, we are committed to protecting your
        privacy and handling your data in an open and transparent manner.
      </p>
      <p>
        <b>1.2 Purpose.</b> The purpose of this Privacy Policy is to explain how
        Visagify collects, uses, and safeguards the personal information you
        provide to us. This policy also outlines your rights concerning your
        personal data and how you can exercise them.
      </p>
      <p>
        <b>1.3 Scope.</b> This Privacy Policy applies to all personal
        information collected by Visagify, whether digital, written, or oral. It
        covers both information collected through our website, mobile app, and
        any other platforms where you can use our services.
      </p>
      <p>
        <b>1.4 Compliance with Laws.</b> Visagify complies with applicable
        privacy laws, including but not limited to the General Data Protection
        Regulation (GDPR) in the European Union and the Children’s Online
        Privacy Protection Act (COPPA) in the United States. We strive to adhere
        to the highest standards of data privacy and protect all information
        that is entrusted to us by our users.
      </p>
      <p>
        <b>1.5 Commitment to Privacy.</b> Your privacy is important to us, and
        we are dedicated to protecting the confidentiality and security of the
        personal information you share. We assure you that we use this data
        responsibly, and we are committed to using the personal information we
        collect in compliance with applicable data protection laws.
      </p>
      <p>
        <b>1.6 Contact Information.</b> For any questions or concerns about this
        Privacy Policy or our data handling practices, please contact us at
        [Insert Contact Information]. We are dedicated to addressing any
        concerns you might have regarding your privacy and our data protection
        practices.
      </p>
      <h2>2. Information Collection</h2>
      <p>
        <b>2.1 Types of Information Collected.</b> Visagify collects several
        types of information from and about users of our services, including:
      </p>
      <ul>
        <li>
          <p>
            <b>Personal Identification Information:</b> This includes your name,
            email address, and other contact information that you provide when
            creating an account, subscribing to our services, or requesting
            further services.
          </p>
        </li>
        <li>
          <p>
            <b>Technical and Usage Data:</b> When you access and use Visagify,
            we may automatically collect certain information about your device
            and usage of our services, including IP addresses, browser type,
            operating system, device type, and data regarding your interaction
            with our services.
          </p>
        </li>
      </ul>
      <p>
        <b>2.2 Methods of Collection.</b> We collect information from you in
        several ways:
      </p>
      <ul>
        <li>
          <p>
            <b>Direct Collection:</b> When you register on our site, subscribe
            to a newsletter, respond to a survey, fill out a form, or provide
            information on our service.
          </p>
        </li>
        <li>
          <p>
            <b>Indirect Collection:</b> Through technology like cookies, log
            files, and web beacons, we collect information about how you
            interact with our site and services. This includes pages you visit,
            the time spent on those pages, and other similar information.
          </p>
        </li>
      </ul>
      <p>
        <b>2.3 Information from Children.</b> In compliance with the Children’s
        Online Privacy Protection Act (COPPA), we do not knowingly collect any
        personal information from children under the age of 13. If we learn that
        we have collected the personal information of a child under 13, we will
        take steps to delete the information as soon as possible.
      </p>
      <p>
        <b>2.4 Non-Personally Identifiable Information.</b> We may collect
        non-personally identifiable information about you whenever you interact
        with our site. This information can include the browser name, the type
        of computer, and technical information about your means of connection to
        our site, such as the operating system and the Internet service
        providers utilized.
      </p>
      <p>
        <b>2.5 Consent for Collection.</b> By using Visagify, you consent to the
        collection of your personal and non-personal information as described in
        this Privacy Policy. If you do not agree with our methods of information
        collection, you may choose not to use our services.
      </p>

      <h2>3. Use of Information</h2>
      <p>
        <b>3.1 Purpose of Information Use.</b> The information we collect is
        used for various purposes:
      </p>
      <ul>
        <li>
          <p>
            <b>To Provide and Improve Our Services:</b> Your information helps
            us to more effectively respond to your customer service requests and
            support needs. The information you provide about how you use our
            services helps us to improve our product and service offerings.
          </p>
        </li>
        <li>
          <p>
            <b>Account Management:</b> We use your information to manage your
            account, provide you with customer support, and ensure you are
            receiving the quality of service you expect from us.
          </p>
        </li>
        <li>
          <p>
            <b>Communication:</b> We may use your information to communicate
            with you about our services, including updates, enhancements, and
            other relevant messages. If you choose to opt into our mailing list,
            you will receive emails that may include company news, updates,
            related product or service information, etc.
          </p>
        </li>
        <li>
          <p>
            <b>Research and Analysis:</b> We use the information for internal
            research and analysis to improve our understanding of our users'
            needs and service quality.
          </p>
        </li>
      </ul>
      <p>
        <b>3.2 Legal Basis for Processing.</b>
      </p>
      <ul>
        <li>
          <p>
            <b>Consent:</b> We process your personal data based on the consent
            you expressly grant to us at the point of collection.
          </p>
        </li>
        <li>
          <p>
            <b>Contractual Necessity:</b> We process personal data as necessary
            to fulfill our contractual obligations to you, such as providing
            services you have requested.
          </p>
        </li>
        <li>
          <p>
            <b>Legal Obligations and Rights:</b> We may use your personal data
            to comply with legal obligations or protect our legal rights.
          </p>
        </li>
      </ul>
      <p>
        <b>3.3 Automated Decision Making.</b> Visagify does not use automated
        decision-making processes, including profiling, in a way that produces
        legal effects concerning you or similarly significantly affects you.
      </p>
      <p>
        <b>3.4 Data Minimization.</b> We adhere to the principle of data
        minimization, ensuring that we only collect and process the necessary
        amount of information required for the intended purposes.
      </p>
      <p>
        <b>3.5 User Consent and Control.</b> You have control over the
        information you provide to us. We provide mechanisms for updating,
        correcting, or deleting your personal information and will respect your
        choices regarding the use of your data.
      </p>
      <h2>4. User Consent</h2>
      <p>
        <b>4.1 Obtaining Consent.</b> At Visagify, we prioritize obtaining clear
        and informed consent from our users before collecting, using, or
        processing their personal data. Consent is obtained through explicit
        user actions, such as ticking a checkbox, choosing settings or options,
        or through other affirmative actions that indicate your consent to our
        data practices.
      </p>
      <p>
        <b>4.2 Scope and Limitation of Consent.</b>
      </p>
      <ul>
        <li>
          <p>
            <b>Consent for Data Collection:</b> Your consent to data collection
            is limited to the purposes explicitly stated at the point of
            collection. We will not use your data for any purpose beyond what we
            have disclosed without first obtaining additional explicit consent
            from you.
          </p>
        </li>
        <li>
          <p>
            <b>Consent for Communication:</b> By providing your email address or
            other contact information, you agree to receive communications from
            us. This may include newsletters, marketing or promotional
            materials, and other information. However, you may opt-out of
            receiving any, or all, of these communications from us by following
            the unsubscribe link or instructions provided in any email we send.
          </p>
        </li>
      </ul>
      <p>
        <b>4.3 Withdrawal of Consent.</b> You have the right to withdraw your
        consent at any time. To withdraw your consent:
      </p>
      <ul>
        <li>
          <p>
            <b>For Data Processing:</b> Please contact us directly at
            contact@visagify.com. Upon receiving your request, we will cease
            processing your data for the purposes you withdraw consent for
            unless we have another legal basis to continue processing it.
          </p>
        </li>
        <li>
          <p>
            <b>For Communication:</b> You can opt out of receiving promotional
            communications by using the unsubscribe options in the communication
            you receive, or by adjusting your communication preferences in your
            account settings.
          </p>
        </li>
      </ul>
      <p>
        <b>4.4 Impact of Withdrawal.</b> Withdrawing your consent may mean that
        we are unable to provide certain services to you. We will advise you if
        this is the case at the time you withdraw your consent.
      </p>
      <p>
        <b>4.5 Consent for Cookies and Similar Technologies.</b> When you visit
        our website, we use cookies and similar technologies to improve your
        experience on our site. You have control over your consent for the use
        of these technologies, and you can withdraw your consent at any time by
        changing your browser settings.
      </p>
      <p>
        <b>4.6 Age of Consent.</b> By using Visagify, you represent that you are
        at least the age of majority in your state or province of residence, or
        that you are the age of majority in your state or province of residence
        and you have given us your consent to allow any of your minor dependents
        to use this site.
      </p>
      <h2>5. Information Sharing and Disclosure</h2>
      <p>
        <b>5.1 Sharing of Information</b> Visagify values your privacy, and we
        limit the sharing of your personal information. The circumstances under
        which your information may be shared are as follows:
      </p>
      <ul>
        <li>
          <p>
            <b>Service Providers:</b> We may share your information with
            third-party service providers to perform functions and provide
            services to us, such as cloud hosting (through Google Cloud
            services), customer support, and email communication. These service
            providers have access to personal information needed to perform
            their functions but are not permitted to share or use the
            information for any other purpose.
          </p>
        </li>
        <li>
          <p>
            <b>Legal Requirements and Law Enforcement:</b> We may disclose your
            information if required by law or if we believe in good faith that
            such action is necessary to comply with the law, prevent fraud,
            protect against misuse, protect the safety of any person, or protect
            our legal rights, property, or safety.
          </p>
        </li>
        <li>
          <p>
            <b>Business Transfers:</b> In the event that Visagify is involved in
            a merger, acquisition, bankruptcy, reorganization, or sale of all or
            a portion of its assets, your information may be among the assets
            transferred.
          </p>
        </li>
      </ul>
      <p>
        <b>5.2 Non-Disclosure of Information</b> Visagify will not rent or sell
        your personally identifiable information to others. We do not share your
        personal information with third parties for their direct marketing
        purposes without your explicit consent.
      </p>
      <p>
        <b>5.3 Aggregate and Non-Identifying Information</b> We may share
        aggregated, non-personally identifiable information publicly and with
        our partners. For example, we may share information publicly to show
        trends about the general use of our services.
      </p>
      <p>
        <b>5.4 User Consent for Sharing</b> Except as outlined above, you will
        receive notice when your personal information might be shared with third
        parties, and you will have an opportunity to choose not to share the
        information.
      </p>
      <p>
        <b>5.5 International Transfers of Data</b> If you are located in the
        European Union or other regions with laws governing data collection and
        use, please note that we may transfer information, including personal
        information, to a country and jurisdiction that does not have the same
        data protection laws as your jurisdiction. We will take all reasonable
        steps to ensure that your data is treated securely and in accordance
        with this Privacy Policy.
      </p>

      <h2>6. International Data Transfers</h2>
      <p>
        <b>6.1 Transfer of Data Outside the European Economic Area (EEA)</b>{" "}
        Visagify operates globally, which means your information may be
        processed and stored in countries outside of the European Economic Area
        (EEA). These countries may have data protection laws that are different
        from the laws of your country. However, we take appropriate safeguards
        to require that your personal information will remain protected in
        accordance with this Privacy Policy.
      </p>
      <p>
        <b>6.2 Compliance with Privacy Shield Framework</b> While Visagify uses
        Google Cloud services for data storage and processing, which complies
        with the Privacy Shield Framework, we also take additional measures to
        ensure that any transfer of data from the EEA to countries outside the
        EEA is done with adequate protections in place.
      </p>
      <p>
        <b>6.3 Safeguards for Data Transfer</b> We implement a variety of
        safeguards to ensure the security and privacy of your data during
        international transfers, including:
      </p>
      <ul>
        <li>
          <p>
            <b>Standard Contractual Clauses:</b> We use standard contractual
            clauses approved by the European Commission or other suitable
            safeguard to permit data transfers from the EEA to other countries.
          </p>
        </li>
        <li>
          <p>
            <b>Consent:</b> In certain circumstances, we may transfer your data
            outside the EEA based on your explicit consent. We will provide you
            with full information about the potential risks of such transfers
            for which there may not be adequate safeguards and obtain your
            consent before transferring your data.
          </p>
        </li>
      </ul>
      <p>
        <b>6.4 Continued Protection of Your Information</b> Our commitment to
        protecting your information is paramount, and we ensure that your data
        is handled with the utmost care and security, regardless of where it is
        processed or stored. We will take all steps reasonably necessary to
        ensure that your data is treated securely and in accordance with this
        Privacy Policy.
      </p>
      <p>
        <b>6.5 Your Rights and Controls</b> You have certain rights regarding
        the international transfer of your data, including the right to be
        informed of the safeguards we have in place. If you have any questions
        or concerns about the international transfer of your data, please
        contact us using the information provided in this Privacy Policy.
      </p>

      <h2>7. User Rights</h2>
      <p>
        <b>7.1 Overview of User Rights</b> At Visagify, we recognize and respect
        your rights regarding your personal data. Under data protection laws,
        particularly the General Data Protection Regulation (GDPR), you have
        several rights, including:
      </p>
      <ul>
        <li>
          <p>
            <b>Right to Access:</b> You have the right to request access to the
            personal information we hold about you.
          </p>
        </li>
        <li>
          <p>
            <b>Right to Rectification:</b> You have the right to request the
            correction of inaccurate personal information held about you.
          </p>
        </li>
        <li>
          <p>
            <b>Right to Erasure ("Right to be Forgotten"):</b> You can request
            the deletion or removal of your personal data where there is no
            compelling reason for its continued processing.
          </p>
        </li>
        <li>
          <p>
            <b>Right to Restrict Processing:</b> You have the right to request
            that we suspend the processing of your personal data.
          </p>
        </li>
        <li>
          <p>
            <b>Right to Data Portability:</b> You have the right to obtain and
            reuse your personal data for your own purposes across different
            services.
          </p>
        </li>
        <li>
          <p>
            <b>Right to Object:</b> You have the right to object to the
            processing of your personal data in certain circumstances, including
            for marketing purposes.
          </p>
        </li>
        <li>
          <p>
            <b>Right to Withdraw Consent:</b> If we are processing your personal
            data based on your consent, you have the right to withdraw that
            consent at any time.
          </p>
        </li>
      </ul>
      <p>
        <b>7.2 Exercising Your Rights</b> To exercise any of these rights,
        please contact us at [Insert Contact Information]. We will respond to
        your request in accordance with applicable data protection laws and
        within the time frames stipulated by those laws.
      </p>
      <p>
        <b>7.3 Verification of Identity</b> For your protection, we may need to
        verify your identity before responding to your request, particularly if
        the request involves personal data of a sensitive nature. We may ask you
        to provide additional information to confirm your identity.
      </p>
      <p>
        <b>7.4 Limits to Rights</b> In certain circumstances, your rights may be
        limited, such as where fulfilling your request would reveal personal
        data about another person, or where we have a legal requirement or a
        compelling legitimate interest to continue processing your data.
      </p>
      <p>
        <b>7.5 Right to Lodge a Complaint</b> You have the right to lodge a
        complaint with a supervisory authority, particularly in the European
        Union (if you are an EU resident) or in your country, if you feel that
        our processing of your personal data infringes data protection laws.
      </p>
      <p>
        <b></b>
      </p>
      <h2>8. Data Storage and Security</h2>
      <p>
        <b>8.1 Data Storage and Management</b> At Visagify, we take the security
        of your data seriously. Personal information collected by our service is
        stored and processed using Google Cloud services, which adhere to high
        standards of security and data protection. We ensure that your data is
        managed in a way that respects your privacy and is in compliance with
        applicable laws and regulations.
      </p>
      <p>
        <b>8.2 Security Measures</b> To prevent unauthorized access, maintain
        data accuracy, and ensure the correct use of information, we have
        implemented appropriate physical, electronic, and managerial procedures
        to safeguard and secure the information we collect online. These
        measures include:
      </p>
      <ul>
        <li>
          <p>
            <b>Encryption:</b> We use encryption technology to protect data
            transmitted over the internet and stored on our systems.
          </p>
        </li>
        <li>
          <p>
            <b>Access Control:</b> Access to personal data is restricted to
            authorized personnel who need the information to perform their job
            functions.
          </p>
        </li>
        <li>
          <p>
            <b>Regular Audits:</b> We conduct regular reviews of our data
            collection, storage, and processing practices to ensure compliance
            with our privacy policies and security measures.
          </p>
        </li>
      </ul>
      <p>
        <b>8.3 Data Retention</b> We retain personal information for as long as
        necessary to provide the services you have requested, or for other
        essential purposes such as complying with our legal obligations,
        resolving disputes, and enforcing our policies. The criteria used to
        determine the retention periods include:
      </p>
      <ul>
        <li>
          <p>
            How long the personal data is needed to provide our services and
            operate our business.
          </p>
        </li>
        <li>
          <p>Whether the user has an account with us which is still active.</p>
        </li>
        <li>
          <p>
            Any legal, contractual, or similar obligations to retain the data.
          </p>
        </li>
      </ul>
      <p>
        <b>8.4 Data Breach Procedures</b> In the unlikely event of a data
        breach, we have implemented procedures to act quickly and protect your
        data. This includes notifying you and any applicable regulator where we
        are legally required to do so.
      </p>
      <p>
        <b>8.5 User Responsibility</b> While we take every reasonable step to
        ensure the security of your data, it is also important for you to
        protect against unauthorized access to your account and information. Be
        sure to sign off when finished using a shared computer and choose a
        strong password that is unique to your Visagify account.
      </p>
      <p>
        <b>8.6 Continuous Improvement</b> Our commitment to data security is
        ongoing. We continuously assess and update our security practices to
        improve the protection and integrity of the information under our care.
      </p>
      <h2>9. Cookies and Tracking Technologies</h2>
      <p>
        <b>9.1 Use of Cookies</b> Visagify uses cookies and similar tracking
        technologies to track activity on our service and hold certain
        information. Cookies are small data files that may include an anonymous
        unique identifier, sent to your browser from a website and stored on
        your device.
      </p>
      <p>
        <b>9.2 Types of Cookies Used</b>
      </p>
      <ul>
        <li>
          <p>
            <b>Essential Cookies:</b> Necessary for the website to function
            properly, such as enabling basic functions like page navigation and
            access to secure areas of the website.
          </p>
        </li>
        <li>
          <p>
            <b>Performance and Analytics Cookies:</b> These cookies collect
            information about how you interact with our website, such as which
            pages you visit most often and if you receive error messages from
            certain pages.
          </p>
        </li>
        <li>
          <p>
            <b>Functionality Cookies:</b> These are used to recognize you when
            you return to our website, enabling us to personalize our content
            for you, greet you by name, and remember your preferences.
          </p>
        </li>
        <li>
          <p>
            <b>Advertising Cookies:</b> These cookies record your online
            activities, including your visits to our website, the pages you have
            visited, and the links you have followed. We may use this
            information to make our website and the advertising displayed on it
            more relevant to your interests.
          </p>
        </li>
      </ul>
      <p>
        <b>9.3 Consent and Control</b> When you first visit Visagify, we provide
        information about our use of cookies and ask for your consent to place
        cookies on your device, except for those strictly necessary for the
        operation of our site. You have the option to accept or decline cookies.
        Most web browsers automatically accept cookies, but you can usually
        modify your browser setting to decline cookies if you prefer.
      </p>
      <p>
        <b>9.4 Third-Party Cookies</b> We may also use various third-parties
        cookies to report usage statistics of the Service and deliver
        advertisements on and through the Service.
      </p>
      <p>
        <b>9.5 Tracking Technologies</b> We may use tracking technologies such
        as beacons, tags, and scripts to collect and track information and to
        improve and analyze our Service.
      </p>
      <p>
        <b>9.6 Managing Cookies</b> You can manage your cookie preferences at
        any time by adjusting your browser settings. Please note that disabling
        cookies may affect the availability and functionality of our services.
      </p>
      <h2>10. Third-Party Links and Services</h2>
      <p>
        <b>10.1 Interaction with Third-Party Services.</b> Visagify's website
        and services may include links to third-party websites, plug-ins,
        services, social networks, or applications. Clicking on those links or
        enabling those connections may allow third parties to collect or share
        data about you. We do not control these third-party websites and are not
        responsible for their privacy statements or practices.
      </p>
      <p>
        <b>10.2 Caution When Using Third-Party Services.</b> When you leave our
        website or are redirected to a third-party website or application, you
        are no longer governed by this Privacy Policy or our website’s Terms of
        Service. We encourage you to read the privacy policy of every site you
        visit or service you use, especially if you share any personal
        information.
      </p>
      <p>
        <b>10.3 Sharing Information with Third Parties.</b> Visagify may share
        your information with third parties in ways that are described in the
        sections on 'Information Sharing and Disclosure' of this Privacy Policy.
        Beyond this, we do not share your personal information with third-party
        websites, services, or applications without your explicit consent.
      </p>
      <p>
        <b>10.4 Third-Party Service Providers.</b> We may use various
        third-party service providers to enhance our service. These third
        parties have access to your Personal Data only to perform specific tasks
        on our behalf and are obligated not to disclose or use it for any other
        purpose.
      </p>
      <h2>11. Data Breach Notification Procedures</h2>
      <p>
        <b>11.1 Commitment to Data Security.</b> At Visagify, maintaining the
        security of your data is a top priority. Despite our rigorous security
        measures, in the unlikely event of a data breach, we have established
        procedures to respond swiftly and effectively to protect your data.
      </p>
      <p>
        <b>11.2 Detection and Investigation.</b> Upon detecting any suspected
        data security breach, we will immediately initiate an investigation. Our
        team will assess the nature and scope of the breach, identify the data
        involved, and take appropriate measures to contain and mitigate any
        risks to data security.
      </p>
      <p>
        <b>11.3 Notification Procedures.</b>{" "}
      </p>
      <ul>
        <li>
          <p>
            <b>User Notification:</b> In compliance with applicable data
            protection laws, if the breach poses a high risk to your rights and
            freedoms, we will notify you without undue delay. This notification
            will include all relevant information about the breach, its
            potential impact, and the steps we are taking in response.
          </p>
        </li>
        <li>
          <p>
            <b>Regulatory Notification:</b> If the breach is likely to result in
            a risk to the rights and freedoms of individuals, we will report it
            to the appropriate data protection authorities within the timeframe
            mandated by law, typically within 72 hours of becoming aware of the
            breach.
          </p>
        </li>
      </ul>
      <p>
        <b>11.4 Post-Breach Actions.</b>
      </p>
      <ul>
        <li>
          <p>
            <b>Review and Analysis:</b> After addressing the immediate effects
            of the breach, we will conduct a thorough review of the incident to
            understand how and why it happened.
          </p>
        </li>
        <li>
          <p>
            <b>Improvements to Security Measures:</b> Based on the review, we
            will implement any necessary changes to our security practices and
            systems to prevent similar breaches in the future.
          </p>
        </li>
        <li>
          <p>
            <b>Communication with Users:</b> We will maintain open and
            transparent communication with affected users throughout the
            process, providing updates and guidance as necessary.
          </p>
        </li>
      </ul>
      <p>
        <b>11.5 Support for Affected Users.</b> We are committed to supporting
        users affected by a data breach. This includes providing information on
        how to protect yourself from potential harm resulting from the breach,
        such as identity theft or fraud, and answering any questions you may
        have.
      </p>
      <p>
        <b>11.6 Continuous Improvement.</b> Data security is an ongoing
        commitment. We continuously review and update our security policies and
        procedures to ensure the highest level of data protection for our users.
      </p>
      <h2>12. Changes to the Privacy Policy</h2>
      <p>
        <b>12.1 Commitment to Updates.</b> At Visagify, we are committed to
        continually reviewing and updating our Privacy Policy to reflect changes
        in our services, feedback from our users, and developments in privacy
        laws and regulations.
      </p>
      <p>
        <b>12.2 Notification of Changes.</b>
      </p>
      <ul>
        <li>
          <p>
            <b>Regular Review:</b> This Privacy Policy will be reviewed and
            possibly updated on a regular basis.
          </p>
        </li>
        <li>
          <p>
            <b>Notification Method:</b> Should any significant changes be made
            to this policy, we will notify you by posting a notice on our
            website, or by sending a direct communication to you, such as via
            email, especially if the changes affect how we handle personal data.
          </p>
        </li>
        <li>
          <p>
            <b>Effective Date: </b> The date of the most recent revisions will
            appear on this page, so you are always aware of the information we
            collect, how we use it, and under what circumstances we disclose it.
          </p>
        </li>
      </ul>
      <p>
        <b>12.3 User's Responsibility.</b>{" "}
      </p>
      <ul>
        <li>
          <p>
            <b>Reviewing Changes:</b> We encourage you to periodically review
            this page for the latest information on our privacy practices. Your
            continued use of our website and services following the posting of
            changes to these terms will mean you accept those changes.
          </p>
        </li>
        <li>
          <p>
            <b>Feedback and Concerns:</b> If you disagree with any changes to
            this Privacy Policy, you will have the opportunity to discontinue
            your use of our services. We welcome your feedback on any aspects of
            our updates.
          </p>
        </li>
      </ul>
      <p>
        <b>12.4 Adjustments to Practices.</b> In response to changes in privacy
        legislation or industry standards, we may need to make changes to our
        information practices or alter the functionality of our service. We will
        update this Privacy Policy accordingly and ensure that our users remain
        fully informed of our practices.
      </p>
      <h2>13. Contact Information</h2>
      <p>
        For any general questions, concerns, privacy-specific concerns,
        feedback, suggestions, reporting a data breach or legal correspondance
        regarding Visagify, our services, or practices, please reach out to us
        through the following contact channels:
      </p>
      <p>
        <b>Email:</b> contact@visagify.com
      </p>
      <p>
        <b>Twitter:</b> @visagify
      </p>
      <p>
        <b>Instagram:</b> @visagify_ai
      </p>
      <p>
        <b></b>
      </p>
      <p>
        <b></b>
      </p>
      <h2>14. Effective Date</h2>
      <p>
        <b>14.1 Date of Effectiveness.</b> This Privacy Policy becomes effective
        as of Wednesday, November 15th 2023 and will remain in effect except
        with respect to any changes in its provisions in the future, which will
        be in effect immediately after being posted on this page.
      </p>
      <p>
        <b>14.2 Acknowledgment of Changes.</b> We reserve the right to update or
        change our Privacy Policy at any time. Your continued use of the
        Visagify service after we post any modifications to the Privacy Policy
        on this page will constitute your acknowledgment of the modifications
        and your consent to abide and be bound by the modified Privacy Policy.
      </p>
      <p>
        <b>14.3 Access to Current Policy.</b> To ensure that you are aware of
        the most current terms of our Privacy Policy, we recommend visiting this
        page periodically. We will also endeavor to notify you of significant
        changes through our website or through direct communication where
        feasible.
      </p>
    </LegalBox>
  );
}

export default Privacy;
