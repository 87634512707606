// import jsSHA from "jssha";
// chroma-js is a library for all kinds of color conversions and color scales.
import chroma from "chroma-js";
/**
  The pxToRem() function helps you to convert a px unit into a rem unit, 
 */

export function pxToRem(number: number, baseNumber = 16) {
  return `${number / baseNumber}rem`;
}

/**
    The hexToRgb() function helps you to change the hex color code to rgb
    using chroma-js library.
   */

export function hexToRgb(color: string) {
  return chroma(color).rgb().join(", ");
}

/**
    The rgba() function helps you to create a rgba color code, it uses the hexToRgb() function
    to convert the hex code into rgb for using it inside the rgba color format.
   */

export function rgba(color: string, opacity: number) {
  return `rgba(${hexToRgb(color)}, ${opacity})`;
}

/**
    The boxShadow() function helps you to create a box shadow for an element
   */

export function boxShadow(
  offset: number[] = [], // eslint-disable-line
  radius: number[] = [], // eslint-disable-line
  color: string,
  opacity: number,
  inset: string | number = ""
) {
  const [x, y] = offset;
  const [blur, spread] = radius;

  return `${inset} ${pxToRem(x)} ${pxToRem(y)} ${pxToRem(blur)} ${pxToRem(
    spread
  )} ${rgba(color, opacity)}`;
}

export function removeDuplicates(arr: any[]) {
  return arr?.filter((item, index) => arr?.indexOf(item) === index);
}

export const timestampToDate = (timestamp: number) => {
  const date = new Date(timestamp);
  const dateString =
    date.getDate().toString().padStart(2, "0") +
    "/" +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "/" +
    date.getFullYear() +
    "-" +
    date.getHours().toString().padStart(2, "0") +
    ":" +
    date.getMinutes().toString().padStart(2, "0");
  return dateString;
};

export function truncate(str: string) {
  const lastChar = str.length;
  if (lastChar < 90) {
    return `${str.substring(0, 90)}`;
  } else {
    return `${str.substring(0, 90)}...`;
  }
}

export const transformText = (text: string) => {
  // Convertir le texte en minuscules
  const lowercaseText = text.toLowerCase();

  // Remplacer les espaces par des tirets
  const transformedText = lowercaseText.replace(/ /g, "-");

  return transformedText;
};

// export function generateRandomString(seed: string, length: number) {
//   const timestamp = new Date().getTime();
//   const nearestMinute = Math.floor(timestamp / 15000) * 15000;
//   const paddedTimestamp = nearestMinute - 15000; // Subtract exactly 1 minute (60,000 milliseconds)
//   const paddedTimestampStr = paddedTimestamp.toString();

//   let sha256 = new jsSHA("SHA-256", "TEXT");
//   sha256.update("monique-" + seed + paddedTimestampStr);
//   let hash = sha256.getHash("HEX");
//   hash = hash.toUpperCase();

//   let result = "";
//   let i = 0;
//   while (result.length < length) {
//     const value = parseInt(hash.substring(i, i + 2), 16);
//     if (
//       (value >= 48 && value <= 57) ||
//       (value >= 65 && value <= 90) ||
//       (value >= 97 && value <= 122)
//     ) {
//       result += String.fromCharCode(value);
//     }
//     i += 2;
//     if (i >= hash.length) {
//       sha256 = new jsSHA("SHA-256", "TEXT");
//       sha256.update(hash);
//       hash = sha256.getHash("HEX");
//       hash = hash.toUpperCase();
//       i = 0;
//     }
//   }
//   return result.substring(0, length);
// }
