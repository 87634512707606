// src/components/FaceSwap.tsx
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactModal from "react-modal";
import imageCompression from "browser-image-compression";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { loadAuth } from "../../store/applications/AuthReducer";
import { palette } from "../../assets/style/colors";
import { setFaceSwapProcessing } from "../../store/applications/UIReducer";
import { MutatingDots } from "react-loader-spinner";
import bg from "../../assets/images/curved-images/dark-glossy-bg.jpg";
import { Subtitle, Title } from "../../components/Hero/Hero";
import {
  ButtonVisagify,
  ButtonVisagifyDark,
} from "../../components/ButtonVisagify/ButtonVisagify";
import readModelImages, {
  ImageMetadata,
} from "../../utils/api/Proshoot/readModelImages";
import writeProshootUpload from "../../utils/api/Proshoot/writeProshootUpload";
import { SelectLab } from "../../components/UI/Forms/Forms.styled";
import { useTheme } from "../../context/ThemeContext";
import { FlexRow } from "../InterpretV2/InterpretV2";
import LinearProgressWhite from "../../components/LinearProgress/LinearProgressWhite";
// import writeUid from "../../utils/api/writeUid";

ReactModal.setAppElement("#root");

const FaceSwapContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
  margin-top: 50px;
  min-height: 50vh;

  @media (max-width: 720px) {
    flex-direction: column-reverse;
  }
`;

const FaceSwapBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  // padding: 1rem;
  border-radius: 1rem;
  background-color: #fff;
  box-shadow: rgba(255, 255, 255, 0.25) 0px 4px 8px;
  background-image: url(${bg});
  background-size: cover;
  min-height: 50vh;
  max-width: 300px;
  padding: 1rem;
`;

const FaceSwapContentBox = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  &:after {
    content: "";
    background-image: ${(props: { color1?: string; color2?: string }) =>
      props.color1 && props.color2
        ? `linear-gradient(310deg, ${props.color1}, ${props.color2})`
        : "linear-gradient(310deg, rgb(0, 0, 0, 80%), rgb(0, 0, 0, 70%))"};
    display: block;
    height: 100%;
    width: 100%;
    border-radius: 1rem;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0.9;
    z-index: -1;
  }
`;

export const StyledLabel = styled.label`
  background-color: #fff;
  color: ${palette.primary.buttonText};
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #000;
    color: #fff;
    box-shadow: rgba(8, 255, 8, 0.8) 0px 4px 8px;
  }

  input {
    display: none;
  }
`;

const PreviewContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const ImagePreview = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  border: 2px solid #fff;
  border-radius: 10px;
`;

export const Modal = styled(ReactModal)`
  background: #000;
  border: solid 1px #444;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  outline: none;
  max-height: 80vh; // Set a maximum height
  overflow-y: auto; // Allow vertical scrolling

  @media (max-width: 700px) {
    inset: unset;
    transform: none;
    width: 90%; // Adjust width for smaller screens
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px; // Adjust font size as needed
  color: white;
`;

const FilterSection = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); // 4 elements per row
  grid-gap: 10px; // Adjust the gap between grid items as needed
  margin-bottom: 20px;

  @media (max-width: 820px) {
    grid-template-columns: repeat(
      2,
      1fr
    ); // 2 elements per row for smaller screens
  }

  @media (max-width: 570px) {
    grid-template-columns: repeat(
      1,
      1fr
    ); // 2 elements per row for smaller screens
  }
`;

export const GallerySection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 10px;
`;

export const ImageTile = styled.img<{ isSelected: boolean }>`
  width: 100%;
  height: auto;
  object-fit: cover;
  border: ${(props) =>
    props.isSelected ? "2px solid rgba(8, 255, 8, 0.8)" : "1px solid #ddd"};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

// const Select = styled.select`
//   padding: 5px;
// `;

const FaceSwapProShoot: React.FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const authState = useAppSelector((state) => state.AuthReducer);
  const uiState = useAppSelector((state) => state.UIReducer);
  const [isUpload, setIsUpload] = useState(false);
  const [progress, setProgress] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [images, setImages] = useState<{
    targetImage: string;
    sourceImage: string;
  }>({
    targetImage: "",
    sourceImage: "",
  });
  const [selectedFiles, setSelectedFiles] = useState<{
    target: File | null;
    source: File | null;
  }>({
    target: null,
    source: null,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modelImages, setModelImages] = useState<ImageMetadata[]>([]);
  // New state to handle the selected model URL
  const [selectedModelURL, setSelectedModelURL] = useState<string | null>(null);

  // Define state for each filter
  const [showFilters, setShowFilters] = useState<boolean>(true);
  const [filteredImages, setFilteredImages] = useState<ImageMetadata[]>([]);
  const [genderFilter, setGenderFilter] = useState("");
  const [ethnicityFilter, setEthnicityFilter] = useState("");
  const [ageFilter, setAgeFilter] = useState("");
  const [faceShapeFilter, setFaceShapeFilter] = useState("");
  const [hairTypeFilter, setHairTypeFilter] = useState("");
  const [hairLengthFilter, setHairLengthFilter] = useState("");
  const [hairColorFilter, setHairColorFilter] = useState("");
  const [eyesShapeFilter, setEyesShapeFilter] = useState("");
  const [noseShapeFilter, setNoseShapeFilter] = useState("");
  const [lipsShapeFilter, setLipsShapeFilter] = useState("");
  const [cheeksShapeFilter, setCheeksShapeFilter] = useState("");

  const toggleFilters = () => {
    setShowFilters((prevShow) => !prevShow);
  };

  const applyFilters = () => {
    const filtered = modelImages.filter((image) => {
      return (
        (genderFilter === "" || image.gender === genderFilter) &&
        (ethnicityFilter === "" || image.ethnicity === ethnicityFilter) &&
        (ageFilter === "" || image.ageRange === ageFilter) &&
        (faceShapeFilter === "" || image.faceShape === faceShapeFilter) &&
        (hairTypeFilter === "" || image.hairType === hairTypeFilter) &&
        (hairLengthFilter === "" || image.hairLength === hairLengthFilter) &&
        (hairColorFilter === "" || image.hairColor === hairColorFilter) &&
        (eyesShapeFilter === "" || image.eyesShape === eyesShapeFilter) &&
        (noseShapeFilter === "" || image.noseShape === noseShapeFilter) &&
        (lipsShapeFilter === "" || image.lipsShape === lipsShapeFilter) &&
        (cheeksShapeFilter === "" || image.cheeksShape === cheeksShapeFilter)
      );
    });
    setFilteredImages(filtered);
  };

  // Call applyFilters whenever any filter value changes
  useEffect(() => {
    applyFilters();
    // eslint-disable-next-line
  }, [
    genderFilter,
    ethnicityFilter,
    ageFilter,
    faceShapeFilter,
    hairTypeFilter,
    hairLengthFilter,
    hairColorFilter,
    eyesShapeFilter,
    noseShapeFilter,
    lipsShapeFilter,
    cheeksShapeFilter,
    modelImages, // Include modelImages if it's dynamically loaded or can change
  ]);

  // Define functions to handle filter changes
  const handleGenderChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedGender = event.target.value;
    setGenderFilter(selectedGender);

    // Apply filter to gallery
    if (selectedGender === "") {
      // If no Gender is selected, show all images
      setFilteredImages(modelImages);
    } else {
      // Filter images based on selected Gender
      const filtered = modelImages.filter(
        (image) => image.gender === selectedGender
      );
      setFilteredImages(filtered);
    }
  };

  const handleEthnicityChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedEthnicity = event.target.value;
    setEthnicityFilter(selectedEthnicity);

    // Apply filter to gallery
    if (selectedEthnicity === "") {
      // If no ethnicity is selected, show all images
      setFilteredImages(modelImages);
    } else {
      // Filter images based on selected ethnicity
      const filtered = modelImages.filter(
        (image) => image.ethnicity === selectedEthnicity
      );
      setFilteredImages(filtered);
    }
  };

  const handleAgeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedAge = event.target.value;
    setAgeFilter(selectedAge);

    // Apply filter to gallery
    if (selectedAge === "") {
      // If no Age is selected, show all images
      setFilteredImages(modelImages);
    } else {
      // Filter images based on selected Age
      const filtered = modelImages.filter(
        (image) => image.ageRange === selectedAge
      );
      setFilteredImages(filtered);
    }
  };

  const handleFaceShapeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedFaceShape = event.target.value;
    setFaceShapeFilter(selectedFaceShape);

    // Apply filter to gallery
    if (selectedFaceShape === "") {
      // If no FaceShape is selected, show all images
      setFilteredImages(modelImages);
    } else {
      // Filter images based on selected Age
      const filtered = modelImages.filter(
        (image) => image.faceShape === selectedFaceShape
      );
      setFilteredImages(filtered);
    }
  };

  const handleHairTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedHairType = event.target.value;
    setHairTypeFilter(selectedHairType);

    // Apply filter to gallery
    if (selectedHairType === "") {
      // If no HairType is selected, show all images
      setFilteredImages(modelImages);
    } else {
      // Filter images based on selected HairType
      const filtered = modelImages.filter(
        (image) => image.hairType === selectedHairType
      );
      setFilteredImages(filtered);
    }
  };

  const handleHairLengthChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedHairLength = event.target.value;
    setHairLengthFilter(selectedHairLength);

    // Apply filter to gallery
    if (selectedHairLength === "") {
      // If no HairLength is selected, show all images
      setFilteredImages(modelImages);
    } else {
      // Filter images based on selected HairLength
      const filtered = modelImages.filter(
        (image) => image.hairLength === selectedHairLength
      );
      setFilteredImages(filtered);
    }
  };

  const handleHairColorChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedHairColor = event.target.value;
    setHairColorFilter(selectedHairColor);

    // Apply filter to gallery
    if (selectedHairColor === "") {
      // If no HairColor is selected, show all images
      setFilteredImages(modelImages);
    } else {
      // Filter images based on selected HairColor
      const filtered = modelImages.filter(
        (image) => image.hairColor === selectedHairColor
      );
      setFilteredImages(filtered);
    }
  };

  const handleEyesShapeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedEyesShape = event.target.value;
    setEyesShapeFilter(selectedEyesShape);

    // Apply filter to gallery
    if (selectedEyesShape === "") {
      // If no EyesShape is selected, show all images
      setFilteredImages(modelImages);
    } else {
      // Filter images based on selected EyesShape
      const filtered = modelImages.filter(
        (image) => image.eyesShape === selectedEyesShape
      );
      setFilteredImages(filtered);
    }
  };

  const handleNoseShapeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedNoseShape = event.target.value;
    setNoseShapeFilter(selectedNoseShape);

    // Apply filter to gallery
    if (selectedNoseShape === "") {
      // If no NoseShape is selected, show all images
      setFilteredImages(modelImages);
    } else {
      // Filter images based on selected NoseShape
      const filtered = modelImages.filter(
        (image) => image.noseShape === selectedNoseShape
      );
      setFilteredImages(filtered);
    }
  };

  const handleLipsShapeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedLipsShape = event.target.value;
    setLipsShapeFilter(selectedLipsShape);

    // Apply filter to gallery
    if (selectedLipsShape === "") {
      // If no LipsShape is selected, show all images
      setFilteredImages(modelImages);
    } else {
      // Filter images based on selected LipsShape
      const filtered = modelImages.filter(
        (image) => image.lipsShape === selectedLipsShape
      );
      setFilteredImages(filtered);
    }
  };

  const handleCheeksShapeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedCheeksShape = event.target.value;
    setCheeksShapeFilter(selectedCheeksShape);

    // Apply filter to gallery
    if (selectedCheeksShape === "") {
      // If no CheeksShape is selected, show all images
      setFilteredImages(modelImages);
    } else {
      // Filter images based on selected CheeksShape
      const filtered = modelImages.filter(
        (image) => image.cheeksShape === selectedCheeksShape
      );
      setFilteredImages(filtered);
    }
  };

  // const handleCustomFileUpload = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   if (event.target.files && event.target.files[0]) {
  //     const file = event.target.files[0];
  //     const fileUrl = URL.createObjectURL(file);
  //     setSelectedModelURL(fileUrl); // Set the URL created from the file
  //     setIsModalOpen(false); // Close the modal
  //   }
  // };

  const handleModelSelect = (imageUrl: string) => {
    setSelectedModelURL(imageUrl); // Set the selected model URL
    setIsModalOpen(false); // Close the modal
  };

  const confirmModelSelection = () => {
    setIsModalOpen(false);
    // convertURLtoFile(selectedModelURL);

    // Logic to use the selected model in the face swap
  };

  // Function to convert image URL to a File object
  // const convertURLtoFile = async (imageUrl: string | null) => {
  //   console.log(`Conversion started for ${imageUrl} ...`);
  //   if (imageUrl) {
  //     console.log("Image url fetch ...");
  //     const response = await fetch(imageUrl);
  //     console.log("Blob creation ...");
  //     const blob = await response.blob();
  //     console.log("File creation ...");
  //     const file = new File([blob], "model.jpg", { type: "image/jpeg" });
  //     console.log("Target File set ...");
  //     setSelectedFiles({ ...selectedFiles, target: file }); // Update the selectedFiles state
  //   }
  // };

  // Function to fetch and set professional images
  const fetchProfessionalImages = async () => {
    try {
      const response = await readModelImages(); // Use the full URL if your backend is on a different domain
      setModelImages(response);
      setFilteredImages(response); // Initially, no filter is applied
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    dispatch(loadAuth());

    fetchProfessionalImages();
  }, []); // eslint-disable-line

  useEffect(() => {
    const interval = setInterval(() => {
      if (uiState.faceSwapProcessing) {
        const now = new Date();
        const timeLeft = endTime - now.getTime();

        if (progress <= 100) {
          const progressRaw = (1 - timeLeft / 75000) * 100;
          setProgress(progressRaw);
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [progress, endTime]); // eslint-disable-line

  const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    imageKey: "target" | "source"
  ) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      if (!validImageTypes.includes(file.type)) {
        alert("Please select a valid image file (jpeg, png, or jpg).");
        return;
      }

      // Check if the file size is less than 10 MB
      if (file.size > 10 * 1024 * 1024) {
        // 10 MB in bytes
        alert(
          "(EN) The file size should be less than 10 MB.(FR) La taille du fichier doit être inférieur à 10 MB."
        );
        return;
      }

      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };

        const compressedBlob = await imageCompression(file, options);

        // Extract original file name without extension
        const originalFileName = file.name.replace(/\.[^/.]+$/, "");

        // Create a new filename with the original name and '-compressed' suffix
        const compressedFileName = `${originalFileName}-compressed.jpeg`;

        // Convert Blob to File Object with the new filename
        const compressedFile = new File([compressedBlob], compressedFileName, {
          type: "image/jpeg",
          lastModified: new Date().getTime(),
        });

        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          if (imageKey === "target") {
            setImages((prevImages) => ({
              ...prevImages,
              targetImage: e.target?.result as string,
            }));
            setSelectedFiles((prevFiles) => ({
              ...prevFiles,
              target: compressedFile,
            }));
            // const fileUrl = URL.createObjectURL(file);
            // setSelectedModelURL(fileUrl); // Set the URL created from the file
            setIsModalOpen(false); // Close the modal
          } else {
            setImages((prevImages) => ({
              ...prevImages,
              sourceImage: e.target?.result as string,
            }));
            setSelectedFiles((prevFiles) => ({
              ...prevFiles,
              source: compressedFile,
            }));
          }
          setImages((prevImages) => ({
            ...prevImages,
            [`${imageKey}Image`]: e.target?.result as string,
          }));
        };
        fileReader.readAsDataURL(compressedFile);
        // setSelectedFiles((prevFiles) => ({
        //   ...prevFiles,
        //   [imageKey]: compressedFile,
        // }));
      } catch (error) {
        console.error(error);
        alert("An error occurred while compressing the image.");
      }
    }
  };

  const handleSubmit = async () => {
    dispatch(setFaceSwapProcessing(true));
    setIsUpload(true);
    const time = new Date();
    const end = time.getTime() + 75000;
    setProgress(0);
    setEndTime(end);
    if (selectedModelURL && selectedFiles.source) {
      try {
        const response = await writeProshootUpload(
          authState.uid,
          selectedFiles.source,
          selectedModelURL
        );
        console.log(authState.uid);
        // const response = await writeUid(authState.uid);
        // Handle response
        console.log(response.data);
        alert("Faces swapped successfully!");
      } catch (error) {
        // Handle error
        console.error(error);
        alert("Failed to swap faces.");
        setIsUpload(false);
      }
    } else if (selectedFiles.target && selectedFiles.source) {
      try {
        const response = await writeProshootUpload(
          authState.uid,
          selectedFiles.source,
          "",
          selectedFiles.target
        );
        console.log(authState.uid);
        // const response = await writeUid(authState.uid);
        // Handle response
        console.log(response.data);
        alert("Faces swapped successfully!");
      } catch (error) {
        // Handle error
        console.error(error);
        alert("Failed to swap faces.");
        setIsUpload(false);
      }
    } else {
      alert("Please select both target and source images.");
    }
  };

  return (
    <FaceSwapContainer>
      {(selectedModelURL || images.sourceImage || images.targetImage) && (
        <PreviewContainer>
          {selectedModelURL && (
            <ImagePreview src={selectedModelURL || ""} alt="Selected Model" />
          )}

          {images.targetImage && (
            <ImagePreview src={images.targetImage} alt="Target Image" />
          )}
          {images.sourceImage && (
            <ImagePreview src={images.sourceImage} alt="Face Image Preview" />
          )}
        </PreviewContainer>
      )}
      <FaceSwapBox>
        <FaceSwapContentBox>
          <Title theme={theme}>Get Your Pro Portrait.</Title>
          <Subtitle>
            Choose a model photo and a face photo, then witness the
            transformation.
          </Subtitle>
          <StyledLabel onClick={() => setIsModalOpen(true)}>
            Select Professional Model
          </StyledLabel>
          <StyledLabel>
            Upload Face Image
            <input
              type="file"
              onChange={(e) => handleImageChange(e, "source")}
              accept="image/*"
            />
          </StyledLabel>
          {uiState.faceSwapProcessing && isUpload ? (
            <>
              <MutatingDots
                height="100"
                width="100"
                color="#fff"
                secondaryColor="#fff"
                radius="12.5"
                ariaLabel="mutating-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
              <LinearProgressWhite percent={progress} />
            </>
          ) : (
            <ButtonVisagify
              onClick={handleSubmit}
              disabled={
                (!selectedModelURL && !images.sourceImage) ||
                (!images.targetImage && !images.sourceImage)
              }
            >
              Swap Faces
            </ButtonVisagify>
          )}
        </FaceSwapContentBox>
      </FaceSwapBox>

      <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)}>
        <ButtonVisagifyDark onClick={toggleFilters}>
          {showFilters ? "Hide Filters" : "Show Filters"}
        </ButtonVisagifyDark>
        <CloseButton onClick={() => setIsModalOpen(false)}>x</CloseButton>

        {showFilters && (
          <FilterSection>
            <SelectLab value={genderFilter} onChange={handleGenderChange}>
              <option value="">Gender</option>
              <option value="female">Female</option>
              <option value="male">Male</option>
              <option value="other">Other</option>
            </SelectLab>

            <SelectLab value={ethnicityFilter} onChange={handleEthnicityChange}>
              <option value="">Ethnicity</option>
              <option value="asian">Asian</option>
              <option value="black">Black</option>
              <option value="caucasian">Caucasian</option>
              <option value="hispanic">Hispanic</option>
              <option value="mixed">Mixed</option>
              <option value="other">Other</option>
            </SelectLab>

            <SelectLab value={ageFilter} onChange={handleAgeChange}>
              <option value="">Age</option>
              <option value="18-25">18-25</option>
              <option value="26-35">26-35</option>
              <option value="36-45">36-45</option>
              <option value="46-55">46-55</option>
              <option value="56+">56+</option>
            </SelectLab>

            <SelectLab
              name="faceShape"
              value={faceShapeFilter}
              onChange={handleFaceShapeChange}
            >
              <option value="">Select Face Shape</option>
              <option value="oval">Oval</option>
              <option value="round">Round</option>
              <option value="square">Square</option>
              <option value="heart">Heart</option>
              <option value="diamond">Diamond</option>
              <option value="pear">Pear</option>
              <option value="oblong">Oblong</option>
              <option value="triangle">Triangle</option>
            </SelectLab>

            <SelectLab
              name="hairType"
              value={hairTypeFilter}
              onChange={handleHairTypeChange}
            >
              <option value="">Select Hair Type</option>
              <option value="straight">Straight</option>
              <option value="wavy">Wavy</option>
              <option value="curly">Curly</option>
              <option value="coily">Coily</option>
              {/* Add more options as needed */}
            </SelectLab>

            <SelectLab
              name="hairLength"
              value={hairLengthFilter}
              onChange={handleHairLengthChange}
            >
              <option value="">Select Hair Length</option>
              <option value="bald">Bald / Shaved</option>
              <option value="veryShort">Very Short</option>
              <option value="short">Short</option>
              <option value="medium">Medium</option>
              <option value="long">Long</option>
              <option value="veryLong">Very Long</option>
            </SelectLab>

            <SelectLab value={hairColorFilter} onChange={handleHairColorChange}>
              <option value="">Hair Color</option>
              <option value="brown">Brown</option>
              <option value="blonde">Blonde</option>
              <option value="red">Red</option>
              <option value="grey">Grey</option>
              <option value="other">Other</option>
            </SelectLab>

            <SelectLab
              name="eyesShape"
              value={eyesShapeFilter}
              onChange={handleEyesShapeChange}
            >
              <option value="">Select Eyes Shape</option>
              <option value="almond">Almond</option>
              <option value="round">Round</option>
              <option value="monolid">Monolid</option>
              <option value="hooded">Hooded</option>
              <option value="upturned">Upturned</option>
              <option value="downturned">Downturned</option>
              <option value="deepSet">Deep Set</option>
              <option value="protruding">Protruding</option>
            </SelectLab>

            <SelectLab
              name="noseShape"
              value={noseShapeFilter}
              onChange={handleNoseShapeChange}
            >
              <option value="">Select Nose Shape</option>
              <option value="roman">Roman Nose</option>
              <option value="greek">Greek Nose</option>
              <option value="nubian">Nubian Nose</option>
              <option value="hawk">Hawk Nose</option>
              <option value="snub">Snub Nose</option>
              <option value="aquiline">Aquiline Nose</option>
              <option value="button">Button Nose</option>
              <option value="straight">Straight Nose</option>
              <option value="bulbous">Bulbous Nose</option>
            </SelectLab>

            <SelectLab
              name="lipsShape"
              value={lipsShapeFilter}
              onChange={handleLipsShapeChange}
            >
              <option value="">Select Lips Shape</option>
              <option value="full">Full Lips</option>
              <option value="thin">Thin Lips</option>
              <option value="round">Round Lips</option>
              <option value="wide">Wide Lips</option>
              <option value="heartShaped">Heart-Shaped Lips</option>
              <option value="bowShaped">Bow-Shaped Lips</option>
              <option value="downwardTurned">Downward-Turned Lips</option>
            </SelectLab>

            <SelectLab
              name="cheeksShape"
              value={cheeksShapeFilter}
              onChange={handleCheeksShapeChange}
            >
              <option value="">Select Cheeks Shape</option>
              <option value="round">Round</option>
              <option value="square">Square</option>
              <option value="long">Long</option>
              <option value="heartShaped">Heart-Shaped</option>
              <option value="oval">Oval</option>
              <option value="diamond">Diamond</option>
            </SelectLab>
          </FilterSection>
        )}

        <GallerySection>
          <StyledLabel>
            <FlexRow>
              <span className="material-icons-outlined">image</span>
              Upload Custom Image
              <input
                type="file"
                onChange={(e) => handleImageChange(e, "target")}
                accept="image/*"
              />
            </FlexRow>
          </StyledLabel>
          {filteredImages.map((image, index) => (
            <ImageTile
              key={index}
              src={image.url}
              isSelected={selectedModelURL === image.url}
              onClick={() => handleModelSelect(image.url)}
            />
          ))}
        </GallerySection>
        <ButtonVisagify onClick={confirmModelSelection}>Confirm</ButtonVisagify>
      </Modal>
    </FaceSwapContainer>
  );
};

export default FaceSwapProShoot;
