import React from "react";
import {
  EmptyProgressBar,
  FillingProgressBarWhite,
} from "./LinearProgress.styled";

function LinearProgressWhite(props: { percent: number }) {
  return (
    <EmptyProgressBar>
      <FillingProgressBarWhite percent={props.percent} />
    </EmptyProgressBar>
  );
}

export default LinearProgressWhite;
