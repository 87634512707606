import React, { ReactNode } from "react";
import styled from "styled-components";

interface ISideNavList {
  children: ReactNode;
}

const SideNavListBox = styled.div`
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  padding-top: 0px;
  padding-bottom: 0px;
`;

function SidenavList({ children }: ISideNavList) {
  return <SideNavListBox>{children}</SideNavListBox>;
}

export default SidenavList;
