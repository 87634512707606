// src/components/FaceSwap.tsx
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import imageCompression from "browser-image-compression";
import { ButtonVisagifyDark } from "../ButtonVisagify/ButtonVisagify";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { palette } from "../../assets/style/colors";
import {
  setFaceSwapBulkLen,
  setFaceSwapProcessing,
} from "../../store/applications/UIReducer";
import { MutatingDots } from "react-loader-spinner";
import bg from "../../assets/images/facewap-box-bg.png";
import { Subtitle, Title } from "../Hero/Hero";
import LinearProgress from "../LinearProgress/LinearProgress";
import writeFileUploadBulk69 from "../../utils/api/writeFileUploadBulk69";
// import writeUid from "../../utils/api/writeUid";

const FaceSwapContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
  margin-top: 50px;
  min-height: 50vh;

  @media (max-width: 720px) {
    flex-direction: column-reverse;
  }
`;

const FaceSwapBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  padding: 1rem;
  border-radius: 1rem;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
  background-image: url(${bg});
  background-size: inherit;
  min-height: 50vh;
  max-width: 300px;
`;

const StyledLabel = styled.label`
  background-color: #fff;
  color: ${palette.primary.buttonText};
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 300ms ease-in-out;

  &:hover {
    background-color: #000;
    color: #fff;
    box-shadow: rgba(8, 255, 8, 0.8) 0px 4px 8px;
  }

  input {
    display: none;
  }
`;

const PreviewContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  max-width: 40vw;
  flex-wrap: wrap;
`;

const ImagePreview = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  border: 2px solid #000;
  border-radius: 10px;
`;

const RemoveButton = styled.button`
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-weight: bold;
  position: absolute;
  top: 0;
  right: 0;
  &:hover {
    background-color: darkred;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const FaceSwapBulk69: React.FC = () => {
  const dispatch = useAppDispatch();
  const uiState = useAppSelector((state) => state.UIReducer);
  const [isUpload, setIsUpload] = useState(false);
  const [progress, setProgress] = useState(0);
  const [bulkLen, setBulkLen] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [images, setImages] = useState<{
    targetImages: string[];
    sourceImage: string;
  }>({
    targetImages: [],
    sourceImage: "",
  });
  const [selectedFiles, setSelectedFiles] = useState<{
    targets: File[];
    source: File | null;
  }>({
    targets: [],
    source: null,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (uiState.faceSwapProcessing) {
        const now = new Date();
        const timeLeft = endTime - now.getTime();

        if (progress <= 100) {
          const progressRaw = (1 - timeLeft / 75000) * 100;
          setProgress(progressRaw);
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [progress, endTime]); // eslint-disable-line

  const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    imageKey: "target" | "source"
  ) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      if (imageKey === "target") {
        setBulkLen(files.length + selectedFiles.targets.length);
        console.log(files.length + selectedFiles.targets.length);
      }

      // If imageKey is 'target', allow multiple images up to 15
      if (
        imageKey === "target" &&
        files.length + selectedFiles.targets.length > 15
      ) {
        alert("You can only upload up to 15 target images.");
        return;
      }

      try {
        for (const file of files) {
          if (!validImageTypes.includes(file.type)) {
            alert("Please select a valid image file (jpeg, png, or jpg).");
            continue; // Skip invalid files
          }

          if (file.size > 10 * 1024 * 1024) {
            alert(
              "(EN) The file size should be less than 10 MB. (FR) La taille du fichier doit être inférieure à 10 Mo."
            );
            continue; // Skip large files
          }

          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          };

          const compressedBlob = await imageCompression(file, options);
          const originalFileName = file.name.replace(/\.[^/.]+$/, "");
          const compressedFileName = `${originalFileName}-compressed.jpeg`;
          const compressedFile = new File(
            [compressedBlob],
            compressedFileName,
            {
              type: "image/jpeg",
              lastModified: new Date().getTime(),
            }
          );

          const fileReader = new FileReader();
          fileReader.onload = (e) => {
            if (imageKey === "target") {
              setImages((prevImages) => ({
                ...prevImages,
                targetImages: [
                  ...prevImages.targetImages,
                  e.target?.result as string,
                ],
              }));
              setSelectedFiles((prevFiles) => ({
                ...prevFiles,
                targets: [...prevFiles.targets, compressedFile],
              }));
            } else {
              setImages((prevImages) => ({
                ...prevImages,
                sourceImage: e.target?.result as string,
              }));
              setSelectedFiles((prevFiles) => ({
                ...prevFiles,
                source: compressedFile,
              }));
            }
          };
          fileReader.readAsDataURL(compressedFile);
        }
      } catch (error) {
        console.error(error);
        alert("An error occurred while compressing the image.");
      }
    }
  };

  const handleRemoveImage = (index: number) => {
    const newTargetImages = [...images.targetImages];
    const newSelectedFiles = [...selectedFiles.targets];
    newTargetImages.splice(index, 1);
    newSelectedFiles.splice(index, 1);
    setImages({ ...images, targetImages: newTargetImages });
    setSelectedFiles({ ...selectedFiles, targets: newSelectedFiles });

    setBulkLen(selectedFiles.targets.length);
    console.log(selectedFiles.targets.length);
  };

  const handleSubmit = async () => {
    dispatch(setFaceSwapProcessing(true));
    dispatch(setFaceSwapBulkLen(bulkLen));
    setIsUpload(true);
    const time = new Date();
    const end = time.getTime() + 75000;
    setProgress(0);
    setEndTime(end);
    if (selectedFiles.targets && selectedFiles.source) {
      try {
        const response = await writeFileUploadBulk69(
          selectedFiles.targets,
          selectedFiles.source
        );
        // const response = await writeUid(authState.uid);
        // Handle response
        console.log(response.data);
        alert("Faces swapped successfully!");
      } catch (error) {
        // Handle error
        console.error(error);
        alert("Failed to swap faces.");
        setIsUpload(false);
      }
    } else {
      alert("Please select both target and source images.");
    }
  };

  return (
    <FaceSwapContainer>
      {images && (
        <PreviewContainer>
          {images.targetImages.map((image, index) => (
            <ImageContainer key={index}>
              <ImagePreview src={image} alt={`Target Image Preview ${index}`} />
              <RemoveButton onClick={() => handleRemoveImage(index)}>
                X
              </RemoveButton>
            </ImageContainer>
          ))}
          {images.sourceImage && (
            <ImagePreview src={images.sourceImage} alt="Source Image Preview" />
          )}
        </PreviewContainer>
      )}
      <FaceSwapBox>
        <Title>Let's Swap. (69 B)</Title>
        <Subtitle>
          Choose a target photo and a face photo, then witness the
          transformation.
        </Subtitle>
        <StyledLabel>
          Upload Target Images (up to 20)
          <input
            type="file"
            onChange={(e) => handleImageChange(e, "target")}
            accept="image/*"
            multiple
          />
        </StyledLabel>
        <StyledLabel>
          Upload Face Image
          <input
            type="file"
            onChange={(e) => handleImageChange(e, "source")}
            accept="image/*"
          />
        </StyledLabel>
        {uiState.faceSwapProcessing && isUpload ? (
          <>
            <MutatingDots
              height="100"
              width="100"
              color="#000"
              secondaryColor="#000"
              radius="12.5"
              ariaLabel="mutating-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
            <LinearProgress percent={progress} />
          </>
        ) : (
          <ButtonVisagifyDark
            onClick={handleSubmit}
            disabled={!images.targetImages || !images.sourceImage}
          >
            Swap Faces
          </ButtonVisagifyDark>
        )}
      </FaceSwapBox>
    </FaceSwapContainer>
  );
};

export default FaceSwapBulk69;
