import { createSlice } from "@reduxjs/toolkit";

const initialStateUi = {
  drawerDesktop: true,
  drawerMobile: false,
  chatNavMobile: false,
  language: "en",
  faceSwapProcessing: false,
  faceSwapBulkLength: 0,
};

export const UIReducer = createSlice({
  name: "UIReducer",
  initialState: initialStateUi,
  reducers: {
    openDrawerDesktop: (state, action) => {
      state.drawerDesktop = action.payload;
    },
    closeDrawerDesktop: (state, action) => {
      state.drawerDesktop = action.payload;
    },
    openDrawerMobile: (state, action) => {
      state.drawerMobile = action.payload;
    },
    closeDrawerMobile: (state, action) => {
      state.drawerMobile = action.payload;
    },
    openChatNavMobile: (state, action) => {
      state.chatNavMobile = action.payload;
    },
    closeChatNavMobile: (state, action) => {
      state.chatNavMobile = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setFaceSwapProcessing: (state, action) => {
      state.faceSwapProcessing = action.payload;
    },
    setFaceSwapBulkLen: (state, action) => {
      state.faceSwapBulkLength = action.payload;
    },
  },
});

export const {
  openDrawerDesktop,
  closeDrawerDesktop,
  openDrawerMobile,
  closeDrawerMobile,
  openChatNavMobile,
  closeChatNavMobile,
  setLanguage,
  setFaceSwapProcessing,
  setFaceSwapBulkLen,
} = UIReducer.actions;
