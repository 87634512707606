import axios from "axios";

/**
 * Uploads the provided files to the specified endpoint.
 * @param {File[]} targetFiles The array of target image files.
 * @param {string} sourceUrl The source image file.
 * @returns Promise resolved with the API response or rejected with an error.
 */
const writeFileUploadBulk69V2 = async (
  targetFiles: File[],
  sourceUrl: string,
  faceId: string
) => {
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  // const baseUrl = process.env.REACT_APP_API_BASEURL_LOCAL;
  const url = `${baseUrl}/v2/69/bulk-upload`;

  const formData = new FormData();
  targetFiles.forEach((file, index) => {
    formData.append("targets", file, `${index}_target_${file.name}`);
  });
  formData.append("faceImageUrl", sourceUrl);
  formData.append("faceId", faceId);

  const response = await axios.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response;
};

export default writeFileUploadBulk69V2;
