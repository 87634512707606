import React, { ReactNode, useState } from "react";
import styled from "styled-components";
import { ButtonMorpheus } from "../ButtonMorpheus/ButtonMorpheus.styled";

const PanelBox = styled.div`
  display: ${(props: { closed: boolean }) => (props.closed ? "none" : "flex")};
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 1rem;
  padding: 0.6rem;
  margin: 0.5rem 1rem;
  width: 50vw;
  box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 5px 0px,
    rgba(255, 255, 255, 0.5) -2px -2px 5px 0px;

  @media screen and (max-width: 682px) {
    width: 85vw;
  }
`;

const PanelOptionsBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const PanelOptionsClose = styled.span`
  font-size: inherit;
  cursor: pointer;
`;

const PanelActionsBox = styled.div`
  display: flex;
  justify-content: center;
`;

function Panel({
  children,
  language,
}: {
  children: ReactNode;
  language: "fr" | "en";
}) {
  const [closed, setClosed] = useState(false);
  const handleClose = () => {
    setClosed(!closed);
  };
  return (
    <PanelBox closed={closed}>
      <PanelOptionsBox>
        <PanelOptionsClose className="material-icons" onClick={handleClose}>
          close
        </PanelOptionsClose>
      </PanelOptionsBox>
      {children}
      <PanelActionsBox>
        <ButtonMorpheus onClick={handleClose}>
          {language === "fr" ? "J'ai compris" : "Ok!"}
        </ButtonMorpheus>
      </PanelActionsBox>
    </PanelBox>
  );
}

export default Panel;
