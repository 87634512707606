import React, { useEffect, useState } from "react";
import VisualGalleryPage from "../../components/VisualGallery/VisualGallery";
import FaceSwap from "../../components/FaceSwap/FaceSwap";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardBox,
  CardContent,
  CardContentBox,
  CardDescription,
  CardTitle,
} from "../../components/Cards/Cards.styled";
import { Link } from "react-router-dom";
import {
  ButtonVisagify,
  ButtonVisagifyDark,
} from "../../components/ButtonVisagify/ButtonVisagify";

function Application() {
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [isAuth, setIsAuth] = useState(
    false || window.localStorage.getItem("auth-visagify") === "true"
  );

  useEffect(() => {
    if (!isAuth) {
      navigate("/");
    }
  }, []); // eslint-disable-line
  return (
    <div>
      <CardBox>
        <Card>
          <CardContentBox>
            <CardContent>
              <div>
                <CardTitle color="#fff">Proshoot</CardTitle>
                <CardDescription>Get your Linkedin or CV photo</CardDescription>
              </div>
              <Link to="/app/proshoot">
                <ButtonVisagify>Open</ButtonVisagify>
              </Link>
            </CardContent>
          </CardContentBox>
        </Card>
        <Card>
          <CardContentBox color1="#00BD36" color2="#016014">
            <CardContent>
              <div>
                <CardTitle color="#fff">Pro Soccer Swap</CardTitle>
                <CardDescription>You are a soccer star</CardDescription>
              </div>
              <Link to="/app/prosoccer">
                <ButtonVisagifyDark>Open</ButtonVisagifyDark>
              </Link>
            </CardContent>
          </CardContentBox>
        </Card>
      </CardBox>
      <FaceSwap />
      <VisualGalleryPage />
    </div>
  );
}

export default Application;
