import styled from "styled-components";

export const SuccessBox = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border: solid 1px #c4ffdf;
  border-radius: 1rem;
`;

export const SuccessIcon = styled.img`
  width: 30px;
  background-color: rgb(75 222 138 / 50%);
  box-shadow: 0px 0px 30px 7px rgb(75 222 138 / 100%);
`;

export const SuccessText = styled.p`
  color: #c4ffdf;
  font-size: 1rem;
  margin: 0;
  text-align: center;
`;
