import React from "react";
import styled from "styled-components";
import { palette } from "../../assets/style/colors";

export const FooterBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
  background: transparent;
  color: ${palette.primary.paragraph};
  flex-direction: column;
  padding: 0.5rem 0;
`;

export const FooterCopyrightBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-size: 0.875rem;
  padding-left: 12px;
  padding-right: 12px;
  opacity: 1;
  background: transparent;
  color: ${palette.primary.paragraph};
`;

export const FooterLinksBox = styled.ul`
  opacity: 1;
  background: transparent;
  font-size: 0.875rem;
  gap: 1rem;
  // color: white;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin-top: 24px;
  margin-bottom: 0px;
  padding: 0px;
  list-style-type: none !important;

  @media screen and (min-width: 992px) {
    margin-top: 0px !important;
  }
`;

export const PoweredBy = styled.img`
  width: 150px;
`;

function Footer() {
  return (
    <FooterBox>
      <FooterCopyrightBox>
        © {new Date(Date.now()).getFullYear()}&nbsp;
        <span>Visagify</span>
      </FooterCopyrightBox>
      <FooterLinksBox>
        <a href="/terms">Terms of Use</a>
        <a href="/privacy">Privacy Policy</a>
      </FooterLinksBox>
    </FooterBox>
  );
}

export default Footer;
