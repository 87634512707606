import axios from "axios";

const readSwapsAdmin = async () => {
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  // const baseUrl = process.env.REACT_APP_API_BASEURL_LOCAL;
  const url = `${baseUrl}/admin/swaps`;
  const response = await axios.get(url);
  return response.data;
};

export default readSwapsAdmin;
