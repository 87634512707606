import styled from "styled-components";
import { palette } from "../../../assets/style/colors";

// .avaxgames-title-color {
//   background-clip: text;
//   -webkit-background-clip: text;
//   -webkit-text-fill-color: transparent;
//   display: inline-block;
//   background-image: linear-gradient(
// -45deg, #09e3e8, #fff, #ff07ff, #2C0045);
//     will-change: background, opacity, filter;
//     background-size: 600%;
//     animation: _mp-title-color 16s linear infinite;
// }

export const TextBold = styled.p`
  font-weight: 700;
  color: #444;
`;

export const PageTitleBox = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const PageTitle = styled.h1`
  color: rgb(255 255 255 / 60%);
  font-family: "lato", sans-serif;
  font-size: 2rem;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(
    -45deg,
    rgb(61, 71, 217),
    rgb(233, 124, 187),
    rgb(249, 231, 187)
  );
  will-change: background, opacity, filter;
  // background-size: 100%;
  // animation: _mp-title-color 16s linear infinite;

  @keyframes _mp-title-color {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
`;

export const PageDescription = styled.p`
  color: rgb(255 255 255 / 45%);
`;

export const PageSubTitle = styled.h2`
  margin: 0.5rem 0;
  color: rgb(255 255 255 / 60%);
  font-family: "lato", sans-serif;
  font-size: 1.7rem;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(310deg, #4dc9e6c1, #210caec7);
`;

export const PageSubTitleH3 = styled.h3`
  margin: 0.5rem 0;
  color: ${palette.primary.headline};
  font-family: "lato", sans-serif;
  font-size: 1.4rem;
  /* background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(135deg, rgb(157, 89, 255), #faae7b); */
`;
