import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { UIReducer } from "./applications/UIReducer";
import { web3Reducer } from "./applications/web3Reducer";
import { AuthReducer } from "./applications/AuthReducer";

const rootReducer = combineReducers({
  UIReducer: UIReducer.reducer,
  AuthReducer: AuthReducer.reducer,
  web3Reducer: web3Reducer.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
